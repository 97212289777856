import * as d3 from 'd3';
import { observe } from 'mobx';
import { inject } from 'mobx-react';
import kebabCase from 'lodash/kebabCase';
import {BuilderContext} from '../types/BuilderContext';
import {DataSeriesBuilder, DataSeriesProps} from '../types/DataSeriesBuilder';

interface BarSeriesProps<T> extends DataSeriesProps<T> {}

@inject('builders', 'chartState')
export class BarSeries<T extends object> extends DataSeriesBuilder<BarSeriesProps<T>, T[]> {
  public static defaultProps = {
    color: 'blue'
  };

  private selection?: d3.Selection<any>;
  private bars?: d3.Selection<any>;

  public barClassName(datum: T, index: number) {
    const { className, color= '', dataKey } = this.props;
    const userDefinedClass = className ? className(datum, index) : '';
    return `bar bar-${index} bar-${kebabCase(String(dataKey))} bar-${color} ${userDefinedClass}`;
  }

  public componentWillUnmount() {
    this.selection && this.selection.remove();
  }

  public componentDidMount() {
    observe(this.chartState, ({object}) => {
      this.bars && this.bars.classed('hovered', (d, i) => (this.activeIndex === i));
    });
  }

  public build(chartContext: BuilderContext) {
    return (chartSelection: d3.Selection<SVGSVGElement>) => {
      const {dataKey, data} = this.props;
      const {x, y} = chartContext;
      // Create the base element if it doesn't exist already
      this.selection = this.selection || chartSelection.append('g').attr('class', 'bar-series');

      if (!this.selection) { return null; }
      const group = this.selection.selectAll('g.bar').data([data]);
      // Start with a flatLine
      group.enter().append('g').attr('id', () => String(dataKey)).attr('class', (datum, index) => this.barClassName(datum[index], index));
      group.exit().remove();
      // Animate line actual position

      const bars = group.selectAll('rect').data((datum) => datum);
      this.bars = bars;
      // Add interations
      bars
        .classed('hovered', (d, i) => (this.activeIndex === i))
        .on('click', (datum, index) => {
          const event = d3.event as MouseEvent;
          const target = event.target as Element;
          this.props.onClick && this.props.onClick({element: target, datum, index, ctx: chartContext});
        })
        .on('mouseenter', (datum, index) => {
          const event = d3.event as MouseEvent;
          const target = event.target as Element;
          d3.select(target).classed('hovered', true);
          this.props.onHoverEnter && this.props.onHoverEnter({element: target, datum, index, ctx: chartContext});
        })
        .on('mouseleave', (datum, index) => {
          const event = d3.event as MouseEvent;
          const target = event.target as Element;
          d3.select(target).classed('hovered', false);
          this.props.onHoverLeave && this.props.onHoverLeave({element: target, datum, index, ctx: chartContext});
        })
        // If there are no interaction handlers for the interactions, then we should disable pointer events
        .style('pointer-events', this.props.onClick || this.props.onHoverEnter || this.props.onHoverLeave ? 'initial' : 'none');

      bars.enter()
        .append('rect')
        .attr('x', (_, i) => x(`${i}`))
        .attr('y', (d) => y(0))
        .attr('height', (d) => y(0))
        .attr('width', x.rangeBand());

      bars
        .attr('x', (_, i) => x(`${i}`))
        .attr('y', (d) => y(Number(d[dataKey])))
        .attr('height', (d) => y(0) - y(Number(d[dataKey])))
        .attr('width', x.rangeBand());

      bars
        .exit()
        .transition()
        .duration(300)
        .attr('x', (_, i) => x(`${i}`))
        .attr('y', (d) => y(0))
        .attr('height', (d) => y(0))
        .attr('width', x.rangeBand())
        .remove();
    };
  }
  public getData() {
    return this.props.data;
  }
  public getRangeMax() {
    const { data, dataKey } = this.props;
    return d3.max(data.map((d) => Number(d[dataKey])));
  }
}
