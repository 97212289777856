import {AnyElement, FormField, Schema} from '@zaiusinc/app-forms-schema';
import {DataStore} from '../stores/DataStore';
import {FieldValidator} from './FieldValidator';
import {PredicateEvaluator} from './PredicateEvaluator';

export class SectionValidator {
  private _errors = {} as {[key: string]: string[]};
  constructor(private section: Schema.Section | Schema.ZedSection, private dataStore: DataStore) {}

  public valid(): boolean {
    const evaluator = new PredicateEvaluator(this.section.key, this.dataStore);
    this.section.elements?.forEach((element) => {
      if (this.isFormField(element)) {
        const fieldValidator = new FieldValidator(evaluator, element);
        if (!fieldValidator.valid()) {
          this.errors[element.key] = fieldValidator.errors;
        }
      }
    });

    return Object.keys(this.errors).length === 0;
  }

  public get errors(): {[key: string]: string[]} {
    return this._errors;
  }

  private isFormField(element: AnyElement | any): element is FormField {
    return element.required !== undefined || element.validations !== undefined;
  }
}
