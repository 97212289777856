import {Switch} from '@blueprintjs/core';
import {Schema} from '@zaiusinc/app-forms-schema';
import {AsyncDropDown, SelectItem} from '@zaiusinc/hera';
import classNames from 'classnames';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {Errors} from '../layout/Errors';
import {FieldGroup} from '../layout/FieldGroup';
import styles from './SelectField.sass';

interface Props {
  schema: Schema.SelectField;
  value?: Schema.FormValue;
  errors?: string[];
  onChange: (field: string, value: Schema.FormValue) => void;
  disabled?: boolean;
  readonly?: boolean;
  loading?: boolean;
  onChangeAction: (action: string) => void;
  onFetchRemoteData: (field: string, source: Schema.DataSource) => Promise<Schema.SelectOption[]>;
}

@observer
export class SelectField extends React.Component<Props> {
  private options: Schema.SelectOption[] = [];
  @observable private fetchResult: Promise<Schema.SelectOption[]> | null = null;
  @observable private loading = false;

  public componentDidMount() {
    if (this.props.value != null) {
      this.fetchOptions();
    } else {
      this.fetchResult = Promise.resolve([]);
    }
  }

  public componentWillReceiveProps(nextProps: Props) {
    const {value} = nextProps;
    if (value != null && this.options.findIndex((option) => option.value === value) === -1) {
      this.fetchOptions();
    }
  }

  public render() {
    const {
      schema: {help, label},
      errors,
      loading
    } = this.props;

    return (
      <FieldGroup help={help} label={label}>
        <div className={classNames({'bp5-skeleton': loading})}>{this.renderInput()}</div>
        <Errors errors={errors} />
      </FieldGroup>
    );
  }

  private renderInput() {
    const {
      schema: {options, key, dataSource},
      value,
      disabled,
      readonly
    } = this.props;
    const isDisabled = disabled || readonly || (!dataSource && (!options || options.length === 0));
    if (options && options.length === 1) {
      return (
        <Switch
          checked={value === options[0].value}
          onChange={this.onChangeToggle}
          disabled={isDisabled}
          labelElement={options[0].text}
        />
      );
    } else if (options && options.length <= 3) {
      const radios = options.map((option, index) => (
        <label className={styles.radioLabel} key={index}>
          <input
            type="radio"
            value={index}
            name={key}
            disabled={isDisabled}
            checked={option.value === value}
            onChange={this.onRadioChange}
          />
          {option.text}
        </label>
      ));
      return <div className={classNames({[styles.disabled]: disabled || readonly})}>{radios}</div>;
    } else {
      return (
        <AsyncDropDown
          initialContent={this.props.schema.hint}
          onItemSelect={this.onChange}
          disabled={isDisabled}
          value={value}
          items={dataSource ? this.fetchResult : Promise.resolve(options ?? [])}
          popoverProps={{onOpening: this.fetchOptions}}
          filterable
        />
      );
    }
  }

  private fetchOptions = () => {
    if (!this.loading && this.props.schema.dataSource) {
      this.loading = true;
      const {key, dataSource} = this.props.schema;
      this.fetchResult = this.props.onFetchRemoteData(key, dataSource).then((result) => {
        this.options = result;
        this.loading = false;
        return result;
      });
    }
  };

  private onRadioChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.onChange(this.props.schema.key, this.props.schema.options![parseInt(e.currentTarget.value, 10)].value);
  };

  private onChangeToggle = (e: React.FormEvent<HTMLInputElement>) => {
    // Toggle is only used when there is exactly one option
    this.props.onChange(this.props.schema.key, e.currentTarget.checked ? this.props.schema.options![0].value : null);
  };

  private onChange = (item: SelectItem) => {
    this.props.onChange(this.props.schema.key, item.value);
    if (this.props.schema.triggerAction) {
      this.props.onChangeAction(this.props.schema.triggerAction);
    }
  };
}
