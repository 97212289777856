import * as d3 from 'd3';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observe, toJS } from 'mobx';
import {inject} from 'mobx-react';
import { InteractionArguments, ChartArea } from './types';
import {BuilderContext} from './types/BuilderContext';
import {ChartComponentBuilder, DataSeriesBuilder} from './types/DataSeriesBuilder';
import styles from './styles/ChartTooltip.sass';

type ChartTooltipRenderer<T extends InteractionArguments<any> = InteractionArguments<any>> = (args: T) => React.ReactElement<any>;
type ChartTooltipPositioner<T extends InteractionArguments<any> = InteractionArguments<any>> =
  (x: d3.scale.Ordinal<string, number>, y: d3.scale.Linear<number, number>, args: T, chartArea: ChartArea, dataKeys: string[], scales?: { [key: string]: d3.scale.Linear<number, number> }) => { x: number, y: number };

export interface ChartTooltipController<T extends InteractionArguments<any> = InteractionArguments<any>> {
  show: (args: T) => void;
  hide: (args: T) => void;
}

export interface ChartTooltipComponentProps<T extends InteractionArguments<any> = InteractionArguments<any>> {
  positioner: ChartTooltipPositioner;
  controller?: (controller: ChartTooltipController<T>) => any;
  renderer: ChartTooltipRenderer<T>;
  auto?: boolean;
  data?: any[];
}

@inject('builders', 'chartState')
export class ChartTooltip<T extends InteractionArguments<any>> extends ChartComponentBuilder<ChartTooltipComponentProps<T>> {
  private selection?: d3.Selection<any>;
  private chartContext: BuilderContext | null = null;
  public interactive: boolean = true;

  public static defaultProps = {
    controller: (controller: ChartTooltipController<InteractionArguments<any>>) => null,
    auto: false
  };

  public static midRangePositioner<TItem, T extends InteractionArguments<TItem>>(x: d3.scale.Ordinal<string, number>, y: d3.scale.Linear<number, number>, { index, datum}: T) {
    if (!x || !y) { return { x: 0, y: 0 }; }
    const max = (datum as any).max || 0;
    const tooltipX = x(`${index}`) + x.rangeBand() / 2;
    const tooltipY = y(max) + (y(0) - y(max)) / 2;

    return {
      x: tooltipX,
      y: tooltipY
    };
  }

  public static midElementPositioner<T extends InteractionArguments<any>>(
    x: d3.scale.Ordinal<string, number>, _y: d3.scale.Linear<number, number>, {index, element}: T, chartArea: ChartArea, dataKeys: string[], scales?: any
  ) {
    const chartElement = chartArea.chartElement();
    if (!x || !chartElement) { return {x: 0, y: 0}; }
    const {top: absoluteTop} = chartElement.getBoundingClientRect();
    const tooltipX = x(`${index}`) + x.rangeBand();
    const {top: elementTop, height: elementHeight} = element.getBoundingClientRect();
    const tooltipY = elementTop + elementHeight / 2 - absoluteTop;

    return {
      x: tooltipX,
      y: tooltipY
    };
  }

  public static autoRangePositioner<TItem, T extends InteractionArguments<TItem>>(x: d3.scale.Ordinal<string, number>, y: d3.scale.Linear<number, number>, { index, datum, ctx }: T, chartArea: ChartArea, dataKeys: string[], scales: any) {
    if (!x || !y || !datum) { return { x: 0, y: 0 }; }
    const max = dataKeys.map((k) => {
      const scale = scales[k];
      // @ts-ignore: any type implicitly
      return Number(scale(datum[k]));
    }).reduce((p, d) => isNaN(d) ? p : Math.max(p, d), 0);
    const min = dataKeys.map((k) => {
      const scale = scales[k];
      // @ts-ignore: any type implicitly
      return Number(scale(datum[k]));
    }).reduce((p, d) => isNaN(d) ? p : Math.min(p, d), max);
    const tooltipX = x(`${index}`) + x.rangeBand();
    const tooltipY = (max + min) / 2;
    const chartElement = chartArea.chartElement();
    if (!chartElement) { return { x: 0, y: 0 }; }
    return {
      x: tooltipX,
      y: tooltipY
    };
  }

  public componentDidMount() {
    this.selection = this.selection || d3.select('body').append('div');
    if (this.selection) {
      this.selection.attr('class', `chart-tooltip ${styles.chartTooltip}`);
    }
    if (this.props.auto) {
      observe(this.chartState, ({ object }) => {
        // Only show tooltip for the active context
        if (JSON.stringify(toJS(object.activeContext)) !== JSON.stringify(this.chartContext)) {
          return;
        }
        if (object.active) {
          const index = object.activeIndex;
          const element = this.chartContext!.chartArea.chartElement();
          const datum = this.props.data![index];
          if (!datum) { return; }
          const args: T = { element, datum, index, ctx: this.chartContext} as unknown as T;
          this.show(args);
        } else {
          this.hide();
        }
      });
    }
  }

  public show = (args: T) => {
    if (!this.selection || !this.chartContext) { return; }
    const chartElement = this.chartContext.chartArea.chartElement();

    if (!chartElement) { return; }
    const {x: scaleX, y: scaleY, dataKeys, chartArea, chartArea: {margins}} = this.chartContext;
    let { top: absoluteTop, left: absoluteLeft } = chartElement.getBoundingClientRect();
    absoluteTop += margins.top;
    absoluteLeft += margins.left;

    const scales = this.scalesByKey();
    const { x, y } = this.props.positioner(scaleX, scaleY, args, chartArea, dataKeys, scales);
    ReactDOM.render(this.props.renderer(args), this.selection[0][0] as Element);
    this.selection.style('display', 'block').style('pointer-events', 'none');
    const { height: tooltipHeight } = (this.selection[0][0] as Element).getBoundingClientRect();

    this.selection
      .style('transform', `translate(${absoluteLeft + (x || 0) - margins.left + 15 + window.scrollX}px, ${absoluteTop + (y || 0) - tooltipHeight / 2 - margins.top + window.scrollY}px)`);
  }

  public hide = () => {
    if (this.selection) {
      ReactDOM.unmountComponentAtNode(this.selection[0][0] as Element);
      this.selection.style('display', 'none');
    }
  }

  public componentWillUnmount() {
    this.hide();
  }

  public build(context: BuilderContext) {
    this.chartContext = context;
  }

  public render() {
    this.props.controller!({show: this.show, hide: this.hide});
    this.injected.builders.push(this);
    return null;
  }

  private scalesByKey() {
    const seriesBuidlers = this.injected.builders.filter((item) => item instanceof DataSeriesBuilder);
    return seriesBuidlers.reduce((acc, sb) => {
      const scale = this.chartContext!.yScales[sb.getYAxisName()];
      if (sb.props.dataKey) {
        // @ts-ignore: any type implicitly
        acc[sb.props.dataKey] = scale;
      }
      if (sb.props.dataKeys) {
        for (const k of sb.props.dataKeys) {
          // @ts-ignore: any type implicitly
          acc[k] = scale;
        }
      }
      return acc;
    }, {});
  }
}
