import * as React from 'react';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import {debounce, isEmpty, isNumber} from 'lodash';
import {DataTableUIProps, DataTableColumn, DataTablePagingProps, DataTableSortingProps, DataTable} from './DataTable';
import {Address} from './lib/Address';

export interface DataTableRequestParams {
  rowStart: number;
  rowCount: number;
  sortColumn: string;
  sortAsc: 1 | 0;
  search: string;
}

export interface DataTableWithStateProps extends DataTableUIProps {
  /**
   * Whether the data is still displayed while loading/refreshing.
   * @default false
   */
  showWhileLoading?: boolean;
  /**
   * Column definitions
   */
  columns: DataTableColumn[];
  /**
   * Default sort column. Defaults to first column.
   */
  defaultSortColumn?: DataTableColumn;
  /**
   * Default sort direction.
   */
  defaultSortAsc?: boolean;
  /**
   * Default number of rows to display.
   * @default 100
   */
  defaultRowCount?: number;
}

interface DataTableWithStateInternalProps extends DataTableWithStateProps {
  /**
   * The data to display in the table
   */
  data: any[];
  /**
   * Total number of records (for paging)
   */
  total: number;
  /**
   * Whether the data is still loading.
   */
  loading?: boolean;
  /**
   * Callback with params for when data might need to be updated.
   */
  updateData: (params: DataTableRequestParams) => void;
}

/**
 * DataTableWithState responsibilities:
 * - Initializing via URL params & via the defaults.
 * - Calling updateData() on paging, sorting & search changes.
 * - Keeping URL params sync'd with paging, sorting & search changes.
 * - Ability to manually reset() to trigger a refetch of the data
 *   (useful when params outside of us get updated, e.g. segmentation).
 */
@observer
export class DataTableWithState extends React.Component<DataTableWithStateInternalProps, {}> {
  private debouncedOnChange;
  @observable public showCoverPage: boolean = false;
  @observable public rowStart: number = 1;
  @observable public rowCount: number = 100;
  @observable public sortColumn: string = '';
  @observable public sortAsc: boolean = true;
  @observable public search: string = '';

  constructor(props: DataTableWithStateInternalProps) {
    super(props);
    this.showCoverPage = !!props.coverPage;

    // Use a debounced version of our update to smooth out any double fetch issues.
    this.debouncedOnChange = debounce(() => {
      const DataTablerequestParams: DataTableRequestParams = {
        rowStart: this.rowStart,
        rowCount: this.rowCount,
        sortColumn: this.sortColumn,
        sortAsc: this.sortAsc ? 1 : 0,
        search: this.search,
      };
      props.updateData(DataTablerequestParams);
    }, 50);

    // Setup any default row count.
    if (props.defaultRowCount) {
      this.rowCount = props.defaultRowCount;
    }

    // Default our sorting to either default or first column (could get overwritten by URL params).
    if (props.columns.length) {
      const defaultSortColumn = props.defaultSortColumn || props.columns[0];
      this.sortAsc = defaultSortColumn.defaultAsc ?? false;
      this.sortColumn = String(defaultSortColumn.id);
      if (props.defaultSortAsc !== undefined) {
        this.sortAsc = props.defaultSortAsc;
      }
    }

    // Default our request params to the URL.
    for (const param of ['rowStart', 'rowCount', 'sortColumn', 'sortAsc', 'search']) {
      const urlValue = Address.getParam(param);
      if (isEmpty(urlValue)) {
        continue;
      }
      const value = isNumber(this[param as keyof this]) ? parseInt(urlValue as string, 10) : urlValue;
      switch (param) {
        case 'sortAsc':
          this.sortAsc = value === 1;
          break;
        case 'rowCount':
        case 'rowStart':
          this[param] = parseInt(urlValue as string, 10);
          break;
        case 'search':
        case 'sortColumn':
          this[param] = urlValue as string;
          break;
        default:
          break;
      }
    }
  }

  public componentDidMount() {
    // Fetch our data!
    if (!this.props.coverPage) {
      this.debouncedOnChange();
    }
  }

  @action
  public handlePaging = (pagingProps: DataTablePagingProps) => {
    if (this.rowStart !== pagingProps.rowStart || this.rowCount !== pagingProps.rowCount) {
      this.rowStart = pagingProps.rowStart;
      this.rowCount = pagingProps.rowCount;

      this.setParamIfChanged('rowStart', this.rowStart);
      this.setParamIfChanged('rowCount', this.rowCount);
      this.debouncedOnChange();
    }
  };

  @action
  public handleSorting = (sortingProps: DataTableSortingProps) => {
    this.sortColumn = sortingProps.sortColumn;
    this.sortAsc = sortingProps.sortAsc;

    this.setParamIfChanged('sortColumn', this.sortColumn);
    this.setParamIfChanged('sortAsc', this.sortAsc ? 1 : 0);
    this.debouncedOnChange();
  };

  @action
  public handleSearch = (value: any) => {
    if (value !== this.search) {
      this.rowStart = 1; // Reset our rowStart when search changes.
      this.search = value;
      this.setParamIfChanged('rowStart', this.rowStart);
      this.setParamIfChanged('search', this.search);
      this.debouncedOnChange();
    }
  };

  @action
  public reset = (): void => {
    this.rowStart = 1;
    this.setParamIfChanged('rowStart', this.rowStart);
    this.search = '';
    this.setParamIfChanged('search', this.search);
    this.refresh();
  };

  @action
  public refresh = (): void => {
    this.debouncedOnChange();
  };

  public setParamIfChanged(param: string, value: string | number | boolean): void {
    // console.log('TODO: Make this update params', param, value);
    // TODO: Make this a callback
    const oldValue = Address.getParam(param);
    if (!oldValue !== !value) {
      Address.setParam(param, value);
    } else if (value && value !== oldValue) {
      Address.setParam(param, value);
    }
  }

  public render() {
    return (
      <DataTable
        {...this.props}
        coverPage={this.showCoverPage ? this.props.coverPage : undefined}
        refresh={this.refresh}
        loading={!!this.props.loading && !this.props.showWhileLoading}
        rowStart={this.rowStart}
        rowCount={this.rowCount}
        sortColumn={this.sortColumn}
        sortAsc={this.sortAsc}
        search={this.search}
        onPaging={this.handlePaging}
        onSorting={this.handleSorting}
        onSearch={this.handleSearch}
      />
    );
  }
}
