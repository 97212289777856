import {Switch} from '@blueprintjs/core';
import {Schema} from '@zaiusinc/app-forms-schema';
import classNames from 'classnames';
import * as React from 'react';
import {Errors} from '../layout/Errors';
import {FieldGroup} from '../layout/FieldGroup';
import {booleanValueOf} from '../lib/booleanValueOf';

interface Props {
  schema: Schema.ToggleField;
  value?: Schema.FormValue;
  errors?: string[];
  onChange: (field: string, value: Schema.FormValue) => void;
  disabled?: boolean;
  readonly?: boolean;
  loading?: boolean;
}

export class Toggle extends React.Component<Props> {
  public render() {
    const {
      schema: {help},
      errors,
      loading
    } = this.props;

    return (
      <FieldGroup help={help} label="">
        <div className={classNames({'bp5-skeleton': loading})}>{this.renderInput()}</div>
        <Errors errors={errors} />
      </FieldGroup>
    );
  }

  private renderInput() {
    const {
      schema: {label},
      value,
      disabled,
      readonly
    } = this.props;
    const labelElement = <span>{label}</span>;
    return (
      <Switch
        checked={booleanValueOf(value) ?? false}
        onChange={this.onChange}
        disabled={disabled || readonly}
        labelElement={labelElement}
      />
    );
  }

  private onChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.onChange(this.props.schema.key, e.currentTarget.checked);
  };
}
