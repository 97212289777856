import * as React from 'react';
import {action, observable, reaction, toJS} from 'mobx';
import {observer} from 'mobx-react';
import {filterDataTableData, pageDataTableData, sortDataTableData} from './DataTableUtils';
import {
  DataTableWithState, DataTableWithStateProps, DataTableRequestParams
} from './DataTableWithState';

interface SyncDataTableProps extends DataTableWithStateProps {
  /**
   * Whether the data is still loading.
   */
  loading?: boolean;
  /**
   * The data to display
   */
  data: any[];
}

// SyncDataTable handles sort, searching and paging in memory.
// It will automatically call refresh() when props.data changes. This handles
//    the case where a record is deleted or marked as deleted (behaviors / email lists).
// Consumers of SyncDataTable will need to call reset() manually when
//    displaying new data (e.g. when switching objects in "Objects & Fields).
@observer
export class SyncDataTable extends React.Component<SyncDataTableProps, {}> {
  private datatable!: DataTableWithState;
  private disposer: any;

  @observable public data: any[] = [];
  @observable public loading: boolean = true;
  @observable public total: number = 0;

  public UNSAFE_componentWillMount(): void {
    this.disposer = reaction(
      () => toJS(this.props.data),
      () => this.datatable && this.datatable.refresh()
    );
  }

  public componentWillUnmount(): void {
    if (this.disposer) {
      this.disposer();
      this.disposer = null;
    }
  }

  @action
  public reset = (): void => {
    this.datatable.reset();
  }

  @action
  public updateData = (params: DataTableRequestParams) => {
    // Skip when still loading. This also fixes a bug where we could briefly
    // show zero results due to loading being done but updateData() hasn't been
    // re-called yet.
    if (this.props.loading) { return; }

    // Init our data, filter and sort it.
    this.data = this.props.data || [];
    this.data = filterDataTableData(this.data, this.props.columns, params.search);
    this.data = sortDataTableData(this.data, params.sortColumn, params.sortAsc);

    // Grab total count and then page our data.
    this.total = this.data.length; // Grab the total after search but before paging.
    this.data = pageDataTableData(this.data, params.rowStart, params.rowCount);

    // We're no longer loading!
    this.loading = false;
  }

  public render() {
    return (
      <DataTableWithState
        {...this.props}
        ref={(datatable) => this.datatable = datatable!}
        data={this.data}
        total={this.total}
        updateData={this.updateData}
        loading={this.loading || this.props.loading}
      />
    );
  }
}
