import {Button, Icon, Intent, PopoverProps, Popover, Spinner, Position} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import classNames from 'classnames';
import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import {action, computed, observable, toJS} from 'mobx';
import {observer} from 'mobx-react';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import {ReactNode} from 'react';
import {DatePicker, DatePickerProps} from './DatePicker';
import {DatePickerState, DateRangeOption, OnChangeParams} from './DatePickerState';

interface DateDropdownPickerProps extends DatePickerProps {
  /**
   * The target to which the popover content is attached.
   */
  children?: ReactNode;

  /**
   * Blueprint `IPopoverProps` with additional `className` prop.
   */
  popover?: PopoverProps;

  /**
   * Loading status for default dropdown button.
   */
  loading?: boolean;
}

@observer
export class DateDropdownPicker extends React.Component<DateDropdownPickerProps> {
  @observable private readonly datePickerState: DatePickerState;
  @observable private popoverOpen: boolean = false;

  public static childContextTypes = {
    datePickerState: PropTypes.object
  };

  constructor(props: DateDropdownPickerProps) {
    super(props);
    const {
      dateRangeOptions,
      initialActiveOptionKey,
      value
    } = this.props;
    this.datePickerState = new DatePickerState(dateRangeOptions, initialActiveOptionKey, DatePickerState.normalizedDateRange(value), this.onChange);
  }

  public componentDidMount(): void {
    window.addEventListener( 'popstate', this.hidePopover, {once: true} );
  }

  public getChildContext(): {datePickerState: DatePickerState} {
    return {
      datePickerState: this.datePickerState
    };
  }

  public componentDidUpdate(prevProps: DateDropdownPickerProps): void {
    const {
      value
    } = this.props;
    if (!isEqual(toJS(prevProps.value), toJS(value)) && !this.popoverOpen) {
      const start = value && DatePickerState.stringToDate(value[0]);
      const end = value && DatePickerState.stringToDate(value[1]);
      this.datePickerState.setValue(start, end);
    }
  }

  private onChange = (dateRangeOption: DateRangeOption, params?: OnChangeParams): void => {
    const close = params && params.closePopover;
    if (close || close === undefined) {
      this.maybeClosePopover(dateRangeOption);
    }
    this.props.onChange && this.props.onChange(dateRangeOption);
  }

  private maybeClosePopover = (dateRangeOption: DateRangeOption): void => {
    const start = dateRangeOption.start;
    const end = dateRangeOption.end;
    if (dateRangeOption.key === DatePickerState.DEFAULT_CUSTOM_OPTION.key) {
      if (start && end) {
        this.hidePopover();
      }
      return;
    }
    this.hidePopover();
  }

  @computed
  private get datePickerProps() {
    return {
      ...omit(this.props, ['children', 'popover', 'loading']),
      onChange: this.onChange
    };
  }

  @computed
  private get defaultButton(): JSX.Element  {
    return (
      <Button
        text={this.label}
        icon={this.indicatorIcon}
        rightIcon={<Icon icon="caret-down" className="button-icon"/>}
        onClick={this.showPopover}
        disabled={this.props.loading}
      />
    );
  }

  @computed
  private get indicatorIcon(): JSX.Element {
    return this.props.loading
      ? <Spinner intent={Intent.PRIMARY} size={15} className="spinner"/>
      : <Icon icon={IconNames.CALENDAR} iconSize={17} className="button-icon"/>;
  }

  @action
  private showPopover = (): void => {
    this.popoverOpen = true;
  }

  @action
  private hidePopover = (): void => {
    this.popoverOpen = false;
    this.revertIfInvalid();
  }

  private revertIfInvalid(): void {
    if (this.isCustomDateRangeOptionSelected && !this.datePickerState.isCustomRangeDataValid) {
      this.datePickerState.setActivePreviousDateRangeOption();
    }
  }

  @computed
  private get label(): string | undefined {
    const datePickerState = this.datePickerState;
    if (!datePickerState.dateRangeOptions.length || this.isCustomDateRangeOptionSelected) {
      const labels = compact([datePickerState.start, datePickerState.end]);
      if (labels.length && datePickerState.isCustomRangeDataValid) {
        return labels.map(DatePickerState.dateToString).join('-');
      }
      if (this.previousOptionIsCustom) {
        return [datePickerState.previousStart, datePickerState.previousEnd].map(DatePickerState.dateToString).join('-');
      }
      return this.previousActiveDateRangeOptionLabel || DatePickerState.DEFAULT_CUSTOM_OPTION.label;
    }
    return this.activeDateRangeOptionLabel;
  }

  @computed
  private get previousOptionIsCustom(): boolean {
    return this.datePickerState && this.datePickerState.previousActiveDateRangeOptionsKey === DatePickerState.DEFAULT_CUSTOM_OPTION.key;
  }

  @computed
  private get isCustomDateRangeOptionSelected(): boolean {
    return this.datePickerState && this.datePickerState.activeDateRangeOptionsKey === DatePickerState.DEFAULT_CUSTOM_OPTION.key;
  }

  @computed
  private get previousActiveDateRangeOptionLabel(): string | undefined {
    return this.datePickerState.previousActiveDateRangeOption && this.datePickerState.previousActiveDateRangeOption.label;
  }

  @computed
  private get activeDateRangeOptionLabel(): string | undefined {
    return this.datePickerState.activeDateRangeOption && this.datePickerState.activeDateRangeOption.label;
  }

  private onInteraction = (nextOpenState: boolean): void => {
    if (!nextOpenState) {
      this.hidePopover();
    }
    return this.props.popover && this.props.popover.onInteraction && this.props.popover.onInteraction(nextOpenState);
  }

  public render() {
    const {
      popover
    } = this.props;
    const className = popover && popover.className;
    return (
      <Popover
        {...this.props.popover}
        minimal
        content={<DatePicker {...this.datePickerProps}/>}
        interactionKind="click"
        isOpen={this.popoverOpen}
        onInteraction={this.onInteraction}
        position={Position.BOTTOM_LEFT}
        className={classNames('h-DateDropdownPicker', className)}
      >
        {
          this.props.children
            ? this.props.children
            : this.defaultButton
        }
      </Popover>
    );
  }
}
