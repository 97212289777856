import * as d3 from 'd3';
import {inject} from 'mobx-react';
import {StackedBarDatum, StackedBarSection, BarSectionWithMeta, InteractionArguments} from '../types';
import kebabCase from 'lodash/kebabCase';
import {DataSeriesBuilder} from '../types/DataSeriesBuilder';
import {BuilderContext} from '../types/BuilderContext';

export type SectionInteractionArguments<T extends StackedBarDatum> = InteractionArguments<T> & {
  section: BarSectionWithMeta<T>,
  sectionIndex: number
};
export type SectionInteractionHandler<T extends StackedBarDatum> = (args: SectionInteractionArguments<T>) => void;
export type CustomTransformer<T> = (sel: d3.Selection<T>, ...args: any[]) => void;

interface StackedBarSeriesProps<T extends StackedBarDatum> {
  sectionClassName?: (section: StackedBarSection, index: number) => string;
  onSectionHoverEnter?: SectionInteractionHandler<T>;
  onSectionHoverLeave?: SectionInteractionHandler<T>;
  onSectionClick?: SectionInteractionHandler<T>;
  customSectionTransformer?: CustomTransformer<BarSectionWithMeta<T>>;
  customBarTransformer?: CustomTransformer<T>;
}

@inject('builders', 'chartState')
export class ChartStackedBars<T extends StackedBarDatum = StackedBarDatum> extends DataSeriesBuilder<StackedBarSeriesProps<T>, T[]> {
  private readonly BAR_PADDING = 1;
  private selection?: d3.Selection<any>;

  public componentWillUnmount() {
    this.selection && this.selection.remove();
  }

  public useClassName(section: StackedBarSection, index: number) {
    return this.props.sectionClassName ? this.props.sectionClassName!(section, index) : `stacked-bar-${index}`;
  }

  public barSections = (chartContext: BuilderContext, bar: T, barIndex: number): Array<BarSectionWithMeta<T>> => {
    const {y} = chartContext;
    const result: Array<BarSectionWithMeta<T>> = [];
    let heightOffset: number = 0;
    for (const section of bar.sections) {
      const height = y(0) - y(section.value);
      result.push({
        ...section,
        height,
        heightOffset,
        bar,
        barIndex,
        key: `${bar.label}-${section.label}`
      });
      heightOffset += height + this.BAR_PADDING;
    }
    return result;
  }

  public buildBars = (chartContext: BuilderContext, data: T[], barTransformer?: CustomTransformer<T>) => {
    return (selection: d3.Selection<any>) => {
      const {x} = chartContext;
      const barsSelection = selection
        .selectAll('g.bar')
        .data(data, (datum) => `${datum.label}`);
      barsSelection.enter()
        .append('g')
        .attr({
          transform: (_bar, idx: number) => `translate(${x(`${idx}`)},0)`,
          class: (_bar, index) => `bar bar-${index}`,
        });

      barsSelection
        .call((g) => barTransformer && barTransformer(g))
        .transition('bar-placement')
        .attr('transform', (_bar, idx: number) => `translate(${x(`${idx}`)},0)`);

      barsSelection.exit().remove();
    };
  }

  public buildSections = (chartContext: BuilderContext) => {
    const _this = this;
    return (selection: d3.Selection<any>) => {
      const {x, y, chartArea} = chartContext;
      const barSections = selection
        .selectAll('.bar')
        .selectAll('.bar-section')
        .data((item, index) => this.barSections(chartContext, item, index), (section) => section.key);
      barSections.enter()
        .append('rect')
        .attr({
          class: (section, index) => `bar-section bar-${section.barIndex}-section bar-section-${index} bar-section-${kebabCase(section.label)} datum-${index}`,
          height: 0,
          y: ({heightOffset, height}) => chartArea.bottom - heightOffset
        })
        .style({
          stroke: `transparent`,
          'stroke-width': this.BAR_PADDING
        });

      barSections
        .on('click', function(this: Element, section, index) {
          _this.props.onSectionClick && _this.props.onSectionClick({
            element: this,
            datum: section.bar,
            section,
            index: section.barIndex,
            sectionIndex: index
          });
        })
        .on('mouseenter', function(this: Element, section, index) {
          this.classList.add('hovered');
          this.parentElement && this.parentElement.classList.add('hovered');
          _this.props.onSectionHoverEnter && _this.props.onSectionHoverEnter({
            element: this,
            datum: section.bar,
            section,
            index: section.barIndex,
            sectionIndex: index
          });
        })
        .on('mouseleave', function(this: Element, section, index) {
          this.classList.remove('hovered');
          this.parentElement && this.parentElement.classList.remove('hovered');
          _this.props.onSectionHoverLeave && _this.props.onSectionHoverLeave({
            element: this,
            datum: section.bar,
            section,
            index: section.barIndex,
            sectionIndex: index
          });
        })
        .each(function(this: Element, datum, index) {
          this.classList.add(_this.useClassName(datum, index));
        })
        .call((g) => this.props.customSectionTransformer && this.props.customSectionTransformer(g))
        .transition('bar-sections-grow')
        .attr({
          y: ({heightOffset, height}) => (chartArea.bottom || 0) - heightOffset - height,
          width: x.rangeBand(),
          height: ({height}) => Math.max(height, 0),
        });
      barSections.exit()
        .transition('bar-sections-shrink')
        .attr('y', ({heightOffset}) => (chartArea.bottom || 0) - heightOffset)
        .attr('height', () => 0)
        .remove();
    };
  }

  public build(chartContext: BuilderContext) {
    return (chartSelection: d3.Selection<SVGSVGElement>) => {
      this.selection = this.selection || chartSelection
        .append('g');
      if (!this.selection) { return null; }
      const dataSelection = this.selection.data([this.props.data]);

      this.selection
        .attr('class', `stacked-bar-series`);
      dataSelection
        .call(this.buildBars(chartContext, this.props.data, this.props.customBarTransformer))
        .call(this.buildSections(chartContext));
      dataSelection.exit().remove();
    };
  }

  public getData() {
    return this.props.data;
  }

  public getRangeMax() {
    return d3.max(this.props.data.map((item) => item.total));
  }
}
