import {PopoverProps} from '@blueprintjs/core';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {DropDown, DropDownProps, SelectItem} from './DropDown';

export type SelectItemPromiseFn<T extends SelectItem = SelectItem> = () => Promise<T[]>;

interface Props<T extends SelectItem = SelectItem> extends Pick<DropDownProps<T>, Exclude<keyof DropDownProps<T>, 'items'>> {
  /**
   * A promise that returns the list items when ready
   * Use the function form to delay the loading of the items until the drop down is opened.
   * Pass null to show the loading state
   */
  items: SelectItemPromiseFn<T> | Promise<T[]> | null;
}

/**
 * An asynchronous wrapper around a standard DropDown.
 * The drop down will be in the loading state until the items are ready
 *
 * See <DropDown/> for descriptions of additional props
 */
@observer
export class AsyncDropDown<T extends SelectItem = SelectItem> extends React.Component<Props> {
  @observable private items: T[] = [];
  @observable private loading = false;

  @action('<AsyncDropDown>#getItems')
  private getItems(props: Props) {
    if (!props.items) {
      this.loading = true;
      this.items = [];
    } else if (typeof props.items !== 'function') {
      this.loading = true;
      this.items = [];
      const promise = (props.items as Promise<T[]>).then(action((items: T[]) => {
        this.items = items;
        this.loading = false;
      }));

      if (promise.catch) {
        promise.catch(action(() => this.loading = false));
      // @ts-ignore: error is not a standard promise method
      } else if (promise['error']) {
        // @ts-ignore: error is not a standard promise method
        promise['error'](action(() => this.loading = false));
      }
    }
  }

  @action('<AsyncDropDown>#onOpening')
  private onOpening = (node: HTMLElement) => {
    const {items, popoverProps} = this.props;

    if (typeof items === 'function') {
      this.loading = true;
      this.items = [];
      const promise = (items as SelectItemPromiseFn<T>)().then(action((result: T[]) => {
        this.items = result;
        this.loading = false;
      }));

      if (promise.catch) {
        promise.catch(action(() => this.loading = false));
      // @ts-ignore: error is not a standard promise method
      } else if (promise['error']) {
        // @ts-ignore: error is not a standard promise method
        promise['error'](action(() => this.loading = false));
      }
    }

    if (popoverProps && typeof popoverProps.onOpening === 'function') {
      popoverProps.onOpening(node);
    }
  }

  public UNSAFE_componentWillMount(): void {
    this.getItems(this.props);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
    if (nextProps.items !== this.props.items) {
      this.getItems(nextProps);
    }
  }

  public render() {
    const popoverProps: Partial<PopoverProps> = {
      ...this.props.popoverProps,
      onOpening: this.onOpening
    };

    return (
      <DropDown
        {...this.props}
        popoverProps={popoverProps}
        onItemSelect={this.props.onItemSelect}
        items={this.items}
        loading={this.loading}
      />
    );
  }
}
