import * as d3 from 'd3';
import {inject} from 'mobx-react';
import {InteractionArguments} from './types';
import {DataSeriesBuilder} from './types/DataSeriesBuilder';
import {BuilderContext} from './types/BuilderContext';
import {observe} from 'mobx';

type AxisTickInteractionHandler<T> =
  (args: InteractionArguments<T>) => void;

interface MouseAxisProps<T> {
  onHoverEnterHandler?: AxisTickInteractionHandler<T>;
  onHoverLeaveHandler?: AxisTickInteractionHandler<T>;
  dataKey?: keyof T;
  active?: boolean;
  activeIndex?: number;
  onChange?: (active: boolean, activeIndex: number, args: InteractionArguments<T>) => void;
  sync?: any;
}

@inject('builders', 'chartState')
export class MouseAxis<T extends {label: string}> extends DataSeriesBuilder<MouseAxisProps<T>, T[]> {
  private chartContext?: BuilderContext;
  private selection?: d3.Selection<any>;
  private focus?: d3.Selection<any>;
  private scrubber?: d3.Selection<any>;

  public static defaultProps = {
    active: false,
    activeIndex: -1,
  };

  public getData() {
    return this.props.data;
  }

  public getRangeMax() {
    return 0;
  }

  get active() {
    return this.props.active || this.chartState.active || false;
  }

  public componentDidMount() {
    observe(this.chartState, ({object}) => {
      this.setActiveState(object.active!, object.activeIndex!);
    });
  }

  private setActiveState(active: boolean, activeIndex: number) {
    const {x} = this.chartContext!;
    this.chartState.active = active;
    this.chartState.activeIndex = activeIndex;
    const x0 = x(`${activeIndex}`) + (x.rangeBand() / 2);
    this.focus!.style('display', active ? 'unset' : 'none');
    if (active && activeIndex >= 0 && !isNaN(x0)) {
      this.scrubber!.attr('x1', x0).attr('x2', x0);
    }
  }

  private dispatchChangeEvent(element: any, active: any, index: any) {
    const {onChange, data} = this.props;
    if (this.chartState.active !== active || this.chartState.activeIndex !== index) {
      this.setActiveState(active, index);
      const datum = data[index] || data[0];
      onChange && onChange(active, index, {element, datum, index, ctx: this.chartContext});
    }
  }

  private addMouseHandlers() {
    return (selection: d3.Selection<any>) => {
      const axis = this;
      selection
        .on('mouseenter', function(this: Element, idx: number) {
          const event = d3.event as MouseEvent;
          const target = event.target as Element;
          axis.chartState.activeContext = axis.chartContext;
          axis.dispatchChangeEvent(target, true, axis.chartState.activeIndex);
          if (idx >= 0) {axis.dispatchChangeEvent(this, axis.chartState.active, idx); }
        });
    };
  }

  public build(chartContext: BuilderContext) {
    this.chartContext = chartContext;
    return (selection: d3.Selection<SVGSVGElement>) => {
      const {data} = this.props;
      const {x, chartArea: {left, right, top, bottom, margins}} = chartContext;
      const height = bottom - top;
      const width = right - left;
      // Create the base element if it doesn't exist already
      this.selection = this.selection || selection.append('g').attr('class', 'mouse-axis');
      this.selection.on('mouseleave', () => {
        const event = d3.event as MouseEvent;
        const target = event.target as Element;
        this.dispatchChangeEvent(target, false, -1);
      });
      // Focus Container
      this.focus = this.focus || this.selection.append('g').attr('class', 'focus-element');
      this.focus.style('display', this.active ? 'unset' : 'none');
      // append the x line
      const x0 = x(`${this.activeIndex}`);

      this.scrubber = this.scrubber || this.focus.append('line')
        .attr('class', 'x')
        .style('stroke', 'black')
        .style('stroke-width', 1)
        .style('stroke-dasharray', '6,4')
        .style('opacity', 1);

      this.scrubber
        .attr('x1', isNaN(x0) ? 0 : x0)
        .attr('x2', isNaN(x0) ? 0 : x0)
        .attr('y1', margins.top)
        .attr('y2', margins.top + height);

      // Mouse Capture Area
      const rects = this.selection.selectAll('rect.mouse-capture').data(data.map((_, i) => i));

      rects.enter()
        .append('rect')
        .style('pointer-events', 'all')
        .attr('fill', 'none')
        .call(this.addMouseHandlers());

      const bandPadding = (x('1') - x('0') - x.rangeBand()) / 2;
      rects.attr('class', 'mouse-capture')
        .attr('x', (index) => x(`${index}`) - bandPadding)
        .attr('y', margins.top)
        .attr('width', width / data.length)
        .attr('height', height);

      rects.exit().remove();
    };
  }
}
