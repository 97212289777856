import {
  Toaster as IToaster, OverlayToasterProps, OverlayToaster as BPToaster
} from '@blueprintjs/core';
import {ToastProps} from './IToaster';
import styles from './Toaster.sass';

export class Toaster extends BPToaster {
  public show(props: ToastProps, key?: string): string {
    // @ts-ignore: className is not a valid prop
    const className = (props.className || '') + (props.intent !== 'danger' && props.intent !== 'warning' ? ' ' + styles.bp5ToastDefault : '');
    // @ts-ignore
    return super.show({...props, className}, key);
  }

  public static create(props?: OverlayToasterProps, container?: HTMLElement): IToaster {
    let className = '';
    if (!container) {
      className = (props?.className || '') + ' ' + styles.bp5ToastContainerMain;
    }
    const toaster = super.create({...props, className}, container);
    toaster.show = this.prototype.show;
    return toaster;
  }
}
