import classNames from 'classnames';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import {Accordion} from './Accordion';
import './Accordion.sass';

interface Props {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string;
}

/**
 * Ensures only one accordion within the group is open at a time
 */
@observer
export class AccordionGroup extends React.Component<Props> {
  private accordions: Set<Accordion> = new Set();
  public static childContextTypes = {
    accordionGroup: PropTypes.object
  };

  public getChildContext() {
    return {accordionGroup: this as AccordionGroup};
  }

  public register(component: Accordion) {
    this.accordions.add(component);
  }

  public unregister(component: Accordion) {
    this.accordions.delete(component);
  }

  public onOpen(component: Accordion) {
    this.accordions.forEach((accordion) => {
      if (accordion !== component) {
        accordion.close();
      }
    });
  }

  public render() {
    return (
      <div className={classNames('h-AccordionGroup', this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}
