import * as d3 from 'd3';
import { observe } from 'mobx';
import {inject} from 'mobx-react';
import kebabCase from 'lodash/kebabCase';
import {BuilderContext} from '../types/BuilderContext';
import {DataSeriesBuilder, DataSeriesProps} from '../types/DataSeriesBuilder';

export interface CircleSeriesProps<T> extends DataSeriesProps<T> {
  vertexSize?: (item: T, index: number) => number;
}

@inject('builders', 'chartState')
export class CircleSeries<T extends object> extends DataSeriesBuilder<CircleSeriesProps<T>, T[]> {
  public static defaultProps = {
    color: 'deep-blue'
  };

  private selection?: d3.Selection<any>;
  private circles?: d3.Selection<any>;

  public circleClassName(datum: T, index: number) {
    const { className= '', color= '', dataKey } = this.props;
    return `circle circle-${index} circle-${kebabCase(String(dataKey))} circle-${color} ${className}`;
  }

  public componentWillUnmount() {
    this.selection && this.selection.remove();
  }

  public componentDidMount() {
    observe(this.chartState, ({object}) => {
      this.circles && this.circles.classed('hovered', (d, i) => (this.activeIndex === i));
    });
  }

  public build(chartContext: BuilderContext) {
    return (chartSelection: d3.Selection<SVGSVGElement>) => {
      const {dataKey, data} = this.props;
      const {x, y} = chartContext;
      // Create the base element if it doesn't exist already
      this.selection = this.selection || chartSelection.append('g').attr('class', 'circle-series');

      if (!this.selection) { return null; }
      const circles = this.selection.selectAll('circle').data(data);
      this.circles = circles;
      circles.enter()
        .append('circle')
        .attr('cy', y(0));

      // Update the vertex
      const _this = this;
      circles
        .attr({
          class: (datum, index) => this.circleClassName(datum, index),
          r: (datum, index) => this.props.vertexSize && this.props.vertexSize(datum, index) || 5,
        })
        .classed('hovered', (d, i) => (this.activeIndex === i))
        .on('click', (datum, index) => {
          const event = d3.event as MouseEvent;
          const target = event.target as Element;
          _this.props.onClick && _this.props.onClick({element: target, datum, index, ctx: chartContext});
        })
        .on('mouseenter', (datum, index) => {
          const event = d3.event as MouseEvent;
          const target = event.target as Element;
          d3.select(target).classed('hovered', true);
          _this.props.onHoverEnter && _this.props.onHoverEnter({element: target, datum, index, ctx: chartContext});
        })
        .on('mouseleave', (datum, index) => {
          const event = d3.event as MouseEvent;
          const target = event.target as Element;
          d3.select(target).classed('hovered', false);
          _this.props.onHoverLeave && _this.props.onHoverLeave({element: target, datum, index, ctx: chartContext});
        })
        // If there are no interaction handlers for the interactions, then we should disable pointer events
        .style('pointer-events', this.props.onClick || this.props.onHoverEnter || this.props.onHoverLeave ? 'initial' : 'none')
        .transition()
        .attr({
          cx: (_datum, index) => x(`${index}`) + x.rangeBand() / 2,
          cy: (datum, index) => y(Number(datum[dataKey])),
        });

      circles
        .exit()
        .transition().attr('cy', y(0)).remove();
    };
  }
  public getData() {
    return this.props.data;
  }
  public getRangeMax() {
    const { data, dataKey } = this.props;
    return d3.max(data.map((d) => Number(d[dataKey])));
  }
}
