import {Schema} from '@zaiusinc/app-forms-schema';
import {DataStore} from '../stores/DataStore';
import {SectionValidator} from './SectionValidator';

export class FormValidator {
  private _errors = {sections: {}} as Schema.FormErrors;

  constructor(private dataStore: DataStore) {}

  public valid(): boolean {
    this.dataStore.form.sections.forEach((section) => {
      const validator = new SectionValidator(section, this.dataStore);
      if (!validator.valid()) {
        this.errors.sections[section.key] = validator.errors;
      }
    });

    return Object.keys(this.errors.sections).length === 0;
  }

  public get errors(): Schema.FormErrors {
    return this._errors;
  }
}
