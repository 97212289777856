import * as React from 'react';
import {SwitchProps, Switch as BPSwitch} from '@blueprintjs/core';
import {default as styles} from './Switch.sass';
import {default as classNames} from 'classnames';

interface Props extends SwitchProps {
  alignIndicator?: 'left' | 'right';
  checked?: boolean;
  children?: React.ReactNode;
  className?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  inline?: boolean;
  innerLabel?: string;
  innerLabelChecked?: string;
  inputRef?: (ref: HTMLInputElement | null) => void;
  label?: string;
  labelElement?: React.ReactNode;
  large?: boolean;
  onChange?: React.FormEventHandler<HTMLInputElement>;
  tagName?: keyof JSX.IntrinsicElements;
}

export const Switch: React.FC<Props> = (props: Props) => {
  const [isChecked, checkedChange] = React.useState(props.checked ?? false);
  const checked = props.checked ?? isChecked;
  const classes = (props.className || '').split(' ');
  const onChange = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    checkedChange(ev.currentTarget.checked);
    props.onChange?.(ev);
  }, []);
  return <BPSwitch {...props} onChange={onChange} checked={checked} className={classNames(classes, {[styles.off]: !(props.checked ?? checked)})} />;
};
