import * as React from 'react';
import {IconName, ButtonGroup, Intent, Tooltip, AnchorButton, TooltipProps, AnchorButtonProps} from '@blueprintjs/core';

export interface ToggleOptionProps {
  /**
   * The name of the Icon for the toggle.
   */
  icon?: IconName;
  /**
   * The text to display in the toggle, can be nothing.
   */
  text?: string;
  /**
   * The id of the toggle.
   */
  id: string;
  /**
   * Whether the toggle is disabled or not.
   */
  disabled?: boolean;
  /**
   * Intent to show for the toggle.
   */
  intent?: Intent;
  /**
   * Whether the toggle option button should be in the loading state
   */
  loading?: boolean;
  /**
   * Whether the toggle button should be minimal or not.
   */
  minimal?: boolean;
  /**
   * Tooltip to display around the button.
   */
  tooltip?: string | JSX.Element;
  /**
   * Full props for the tooltip.
   */
  tooltipProps?: Partial<TooltipProps>;
  /**
   * Full props for the toggle button.
   */
  buttonProps?: Partial<AnchorButtonProps>;
}

export const ToggleOption: React.FC<ToggleOptionProps> = () => null;

interface ToggleOptionItemProps extends ToggleOptionProps {
  active: boolean;
  onClick: (id: string) => void;
}

const ToggleOptionItem: React.FC<ToggleOptionItemProps> = ({active, onClick, disabled, icon, id, intent, minimal, text, tooltip, buttonProps, tooltipProps}) => {
  const handleClick = React.useCallback(() => onClick(id), [id]);
  let content = (
    <AnchorButton
      {...(buttonProps || {})}
      disabled={disabled ?? false}
      icon={icon}
      key={id}
      intent={intent}
      minimal={minimal}
      active={active}
      onClick={handleClick}
      text={text}
    />
  );
  if (tooltip) {
    content = (
      <Tooltip {...tooltipProps} content={tooltip}>
        {content}
      </Tooltip>
    );
  }
  return content;
};

export interface ToggleGroupProps {
  /**
   * The toggle options to be presented in the group, must be `ToggleOption`.
   */
  children: Array<React.ReactElement<ToggleOptionProps>>;
  /**
   * Whether the button toggle options should display as minimal, can be overwritten at the individual
   * `ToggleOption` level.
   */
  minimal?: boolean;
  /**
   * The default intent for the toggles in the group, can be overridden on the individual `ToggleOption` level.
   */
  intent?: Intent;
  /**
   * The default loading state for the toggles in the group.
   */
  loading?: boolean;
  /**
   * The currently active toggle, must be one of the `id` props from the `ToggleOption`s.
   */
  active: string;
  /**
   * Whether the control should be disabled.
   */
  disabled?: boolean;
  /**
   * Called when a toggle is clicked, the `id` argument aligns with the `id` of the pressed toggle.
   */
  onChange: (id: string) => void;
}

export const ToggleGroup: React.FC<ToggleGroupProps> = ({children, active, disabled: controlDisabled, minimal: defaultMinimal, intent: defaultIntent, loading: defaultLoading, onChange}) => {
  return (
    <ButtonGroup>
      {children.map(({props: {icon, minimal, id, text, buttonProps, tooltipProps, intent, loading, disabled, tooltip}}) => (
        <ToggleOptionItem
          active={id === active}
          disabled={controlDisabled ?? disabled ?? false}
          key={id}
          id={id}
          loading={loading ?? defaultLoading ?? false}
          icon={icon}
          onClick={onChange}
          text={text ?? ''}
          intent={intent ?? defaultIntent ?? Intent.NONE}
          minimal={minimal ?? defaultMinimal ?? false}
          tooltip={tooltip ?? ''}
          buttonProps={buttonProps ?? {}}
          tooltipProps={tooltipProps ?? {}}
        />
      ))}
    </ButtonGroup>
  );
};
