import {find} from 'lodash';
import {DataTableColumn} from './DataTable';
import {isEmpty, isString, filter, sortBy} from 'lodash';

/**
 * This is a little awkward but we need to wrap any add/remove calls
 * in order for us to keep the sortColumn correct in the face
 * of new or deleted columns.
 */
export function preserveDataTableSortColumn(sortColumn: string, columns: any, callback: () => DataTableColumn[]) {
  // Adding or removing columns can change our sortedColumn id.
  const currentlySortedColumn = columns[sortColumn];

  // Run our callback and get the resulting new columns.
  const newColumns = callback();

  // See if our currently sorted column still exists, and use it's new id OR default to the first column.
  const column = find(newColumns, {title: currentlySortedColumn && currentlySortedColumn.title});
  const newSortColumn = column ? column.id : newColumns[0].id;
  if (sortColumn !== newSortColumn) {
    sortColumn = newSortColumn as string;
  }

  return sortColumn;
}

export function filterDataTableData(data: any[], columns: any, search: string) {
  if (data.length && !isEmpty(search)) {
    return data.filter((item) => {
      for (const column of filter(columns, 'searchable')) {
        if (column.searchFormatter) {
          const formattedValue = item[column.id] && column.searchFormatter(item[column.id]);
          if (formattedValue && formattedValue.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
        } else {
          if (item[column.id] && item[column.id].toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
        }
      }
      return false;
    });
  } else {
    return data;
  }
}

/**
 * Applay a sort on the datatable data
 */
export function sortDataTableData(data: any[], sortColumn: string, sortAsc: boolean | number) {
  if (data.length && !isEmpty(sortColumn)) {
    data = sortBy(data, (row) => {
      const value = row[sortColumn];
      return isString(value) ? value.toLowerCase() : value;
    });
    if (sortAsc === 0) {
      data = data.reverse();
    }
    // Sort nulls to the end.
    return sortBy(data, (row) => {
      return isEmpty(row[sortColumn]);
    });
  } else {
    return data;
  }
}
/**
 * Extract rows from the data array to display on the page
 */
export function pageDataTableData(data: any[], rowStart: number, rowCount: number) {
  const rowEnd = Math.min(rowStart - 1 + rowCount, data.length);
  return data.slice(rowStart - 1, rowEnd);
}
