import {Button} from '@blueprintjs/core';
import {clamp, merge, pick} from 'lodash';
import {action} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {DropDown, SelectItem} from '../DropDown';
import {DataTablePagingProps} from './DataTable';
import styles from './DataTablePaging.sass';

interface ExtendedDataTablePagingProps extends DataTablePagingProps {
  /**
   * Callback called if the datatable's page is changed
   */
  onPaging?: (pagingProps: DataTablePagingProps) => void;
  /**
   * Enable to hide page size dropdown
   */
  hideDropDown?: boolean;
}

@observer
export class DataTablePaging extends React.Component<ExtendedDataTablePagingProps, {}> {
  public static defaultProps = {
    hideDropDown: false
  };

  public updatePaging(changes: Partial<DataTablePagingProps>) {
    const pagingProps = pick(
      this.props,
      Object.keys(this.props as DataTablePagingProps)
    );
    merge(pagingProps, changes);
    this.props.onPaging &&
      this.props.onPaging(pagingProps as DataTablePagingProps);
  }

  @action
  public prevPage = () => {
    const newRowStart = this.props.rowStart - this.props.rowCount;
    this.updatePaging({rowStart: clamp(newRowStart, 0, this.props.total)});
  }

  @action
  public nextPage = () => {
    const rowStart = this.props.rowStart + this.props.rowCount;
    this.updatePaging({rowStart});
  }

  @action
  public updateRowStart = (rowStart: React.ChangeEvent<HTMLInputElement>) => {
    this.updatePaging({rowStart: clamp(Number(rowStart.target.value), 0, this.props.total)});
  }

  public render() {
    const {rowStart, rowCount, total, hideDropDown} = this.props;
    const rowEnd = Math.min(rowStart - 1 + rowCount, total);

    return (
      <div className={styles.paging}>
        <label>
          {total ? rowStart : 0}-{rowEnd} of {total}
        </label>
        <Button
          icon="chevron-left"
          disabled={rowStart === 1}
          onClick={this.prevPage}
        />
        <input
          className="bp5-input"
          type="number"
          value={`${rowStart === 0 ? '' : rowStart}`}
          onChange={this.updateRowStart}
          min={1}
          max={total}
        />
        <Button
          icon="chevron-right"
          disabled={rowEnd >= total}
          onClick={this.nextPage}
        />

        {!hideDropDown && <label className="">Rows</label>}
        {!hideDropDown && <DropDown
          value={rowCount}
          items={[
            {value: 10, text: '10'},
            {value: 25, text: '25'},
            {value: 100, text: '100'},
            {value: 1000, text: '1000'},
          ]}
          // tslint:disable-next-line jsx-no-lambda
          onItemSelect={(selected: SelectItem) => this.updatePaging({rowCount: selected.value})}
        />
        }
      </div>
    );
  }
}
