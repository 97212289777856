import {Icon} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import * as React from 'react';
import { NumberFormatArgs, formatPercent, formatCurrency, formatNumber, CurrencyFormatArgs } from '../../lib/NumberFormatters';
import {CopyTooltip} from '../CopyTooltip';
import styles from './DataTableFormatters.sass';

// Used by our formatLink and formatActions helpers.
export type Linker = (row: any) => string;
export interface DataTableActions {
  become?: Linker;
  edit?: Linker;
  trash?: (row: any) => void;
  duplicate?: Linker;
  custom?: (row: any) => JSX.Element[] | JSX.Element;
}

export class DataTableFormatters {
  public static formatLink = (linker: Linker, target?: string) => {
    return (value: any, row: any) => {
      return (
        // eslint-disable-next-line
        <a href={linker(row)} target={target}>
          {value}
        </a>
      );
    };
  }

  public static formatPercent = (args: NumberFormatArgs = {
    precision: 3,
    abbreviate: false,
  }) => {
    return (value: string, _: any) => {
      return formatPercent(Number(value), args);
    };
  }

  public static formatCurrency = (args?: CurrencyFormatArgs) => {
    return (value: string | number, _: any) => {
      return formatCurrency(Number(value), args);
    };
  }

  public static formatNumber = (args?: NumberFormatArgs) => {
    return (value: string | number, _: any) => {
      return formatNumber(Number(value), args);
    };
  }

  public static formatImage = (_value: any, row: any) => {
    return (
      <div className={styles.image}>
        {row.img_src ?
          <img src={row.img_src}/> :
          <div className={styles['placeholder-img']}><Icon icon={IconNames.MEDIA}/></div>
        }
      </div>
    );
  }

  public static formatImageColumn = (value: any, row: any) => {
    return (
      <div className={styles.image}>
        {value ?
          <img src={value}/> :
          <div className={styles['placeholder-img']}><Icon icon={IconNames.MEDIA}/></div>
        }
      </div>
    );
  }

  public static formatActions = (actions: DataTableActions) => {
    return (_value: any, row: any) => {
      return (
        <div className={styles.actions}>
          {actions.become && (
            <a href={actions.become(row)} title="become">
              <Icon icon="follower" />
            </a>
          )}

          {actions.edit && (
            <a href={actions.edit(row)} title="edit">
              <Icon icon="edit" />
            </a>
          )}

          {actions.trash && (
            // tslint:disable-next-line jsx-no-lambda
            <a onClick={() => actions.trash!(row)} title="delete">
              <Icon icon="trash" />
            </a>
          )}

          {actions.duplicate && (
            <a href={actions.duplicate(row)} title="copy">
              <Icon icon="duplicate" />
            </a>
          )}

          {actions.custom?.(row)}
        </div>
      );
    };
  }

  /**
   * inserts a placeholder dash if value is null or undefined
   */
  public static formatNullable = (value: any) => {
    return value == null || value === '' ? '—' : value;
  }

  public static formatCopyToClipboard = (value: any) => {
    return (
      <div>
        <CopyTooltip valueToCopy={value} messageTimeout={1000}>
          <span>{value}</span>
        </CopyTooltip>
      </div>
    );
  }
}
