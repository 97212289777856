import * as React from 'react';
import classNames from 'classnames';
import {observable, toJS, computed} from 'mobx';
import {observer} from 'mobx-react';
import omit from 'lodash/omit';
import {ItemRendererProps, MultiSelectProps, MultiSelect} from '@blueprintjs/select';
import {Button, PopoverProps, TagInputProps, MenuItem, Classes} from '@blueprintjs/core';
import styles from './DropDownMulti.sass';
import {SelectItem, SelectItemTypes} from '../DropDown';
import {
  itemPredicate, itemRenderer, NoResultsState, itemListRenderer
} from '../../helpers/selectRenderers';
import {ItemListRendererProps} from '@blueprintjs/select/src/common/itemListRenderer';

export interface DropDownMultiProps<T extends SelectItem> extends Partial<MultiSelectProps<T>> {
  items: T[];
  onItemSelect?: never;

  /**
   * Called with the updated list of values whenever items
   * are added, removed or cleared.
   */
  onChange: (values: any[]) => void;

  /**
   * Called when a new item is added.
   */
  onAddNewItem?: (item: T) => void;

  /**
   * Called when an item that was created is removed from the list
   */
  onRemoveTransientItem?: (item: T) => void;

  /**
   * Whether or not the user should be able to add items to the list
   */
  creatable?: boolean;

  /**
   * Whether or not first item in the list should 'Add <query value>'
   */
  showAddNewValue?: boolean;

  /**
   * List of currently selected items.
   * NOTE: These should be of SelectItem.value type and not SelectItems themselves.
   */
  selectedItems: Array<T['value']>;

  /**
   * Disables the input when true.
   */
  disabled?: boolean;

  /**
   * Renders the input with Intent.DANGER.
   */
  invalid?: boolean;

  /**
   * @default true
   */
  resetOnSelect?: boolean;

  /**
   * true to disable and show loading spinner
   */
  loading?: boolean;

  /**
   * List of items (usually one) that should be fixed to the bottom of the drop down list.
   * These items do not scroll and are not filtered by search input.
   * The type needs to be set to SelectItemTypes.Sticky to trigger the onStickySelect prop.
   */
  stickyItems?: T[];

  /**
   * Called when a sticky item is clicked
   */
  onStickySelect?: (item: T, event?: React.SyntheticEvent<HTMLElement>) => void;

  /**
   * JSX.Element that will be placed at the bottom of the drop down
   */
  bottomSection?: JSX.Element;

  /**
   * Whether or not to use virtual rendering for the list items.
   * If true, we will only render visible items and lazily render as the user scrolls.
   * If false, or not provided, we will eagerly render all items (existing behavior).
   * @default false
   */
  virtualize?: boolean;

  /**
   * When using Lazy item rendering (virtualize == true), we need to know the row height.
   * Defaults to our standard list item height of 30. When not using virtual rendering, this value is ignored
   * @default 30
   */
  itemHeight?: number;
}

@observer
export class DropDownMulti<T extends SelectItem> extends React.Component<DropDownMultiProps<T>> {
  @observable private isOpen = false;
  @observable private activeItem: T | null = null;
  @observable private query?: string;
  @observable private transientItems: T[] = [];
  private multiSelectRef?: MultiSelect<T> | null;

  constructor(props: DropDownMultiProps<T>) {
    super(props);
  }

  public render() {
    const {className, disabled, invalid} = this.props;
    const containerClassNames = classNames(
      'h-DropDownMulti',
      {'h-DropDownMulti__disabled': disabled},
      {'h-DropDownMulti__invalid': invalid},
      className
    );
    return (
      <div className={classNames(styles.container, containerClassNames)}>
        <MultiSelect
          className={classNames({[styles.disabled]: disabled})}
          itemPredicate={itemPredicate}
          itemRenderer={this.itemRenderer}
          itemListRenderer={this.itemListRenderer}
          onActiveItemChange={this.onActiveItemChange}
          onItemSelect={this.onItemSelect}
          onQueryChange={this.onQueryChange}
          query={this.query}
          resetOnSelect
          itemsEqual={this.itemsEqual}
          itemDisabled={this.itemDisabled}
          tagRenderer={this.tagRenderer}
          noResults={NoResultsState}
          {...omit(this.props, 'className', 'onQueryChange')}
          popoverProps={this.popoverProps}
          tagInputProps={this.tagInputProps}
          items={this.items}
          ref={(ref) => this.multiSelectRef = ref}
        />
      </div>
    );
  }

  public componentDidMount() {
    const items = new Set(this.props.items.map((item) => item.value));
    if (this.props.createNewItemFromQuery) {
      this.transientItems = this.props.selectedItems.filter((item) => !items.has(item)).map(this.props.createNewItemFromQuery) as T[];
    }
  }

  public componentWillUnmount() {
    this.transientItems = [];
  }

  private itemRenderer = (item: SelectItem, itemRenderProps: ItemRendererProps): JSX.Element => {
    // This is retrofitting something that will be implemented in further versions of blueprintjs.select
    if (item.type === SelectItemTypes.Creatable) {
      const text = `Add ${item.text === '' ? 'value' : item.text}`;
      const plusIcon = 'plus';
      return itemRenderer({...item, icon: plusIcon, text}, itemRenderProps);
    }
    const icon = this.isItemSelected(item) ? 'tick' : 'blank';
    return itemRenderer({...item, icon}, itemRenderProps);
  }

  private itemDisabled = (item: SelectItem) => {
    return (item.type === SelectItemTypes.Creatable && (this.query === '' || this.query === undefined));
  }

  private itemsEqual(item1: T, item2: T) {
    return String(item1.value).toLowerCase() === String(item2.value).toLowerCase() || String(item1.text).toLowerCase() === String(item2.text).toLowerCase();
  }

  private onQueryChange = (query: string, event?: React.ChangeEvent<HTMLInputElement>): void => {
    this.query = query || '';
    if (this.props.onQueryChange) {
      this.props.onQueryChange(query, event);
    }
  }

  private selectedItemIndex(selectedItem: T): number {
    return this.props.selectedItems.findIndex((item) => item  === selectedItem.value );
  }

  private isItemNew(maybeNewItem: T) {
    return !this.props.items.some((item) => this.itemsEqual(item, maybeNewItem));
  }

  private transientItemIndex(maybeTransientItem: T) {
    return this.transientItems.findIndex((item) => item.value === maybeTransientItem.value);
  }

  @computed
  private get itemMap() {
    const {createNewItemFromQuery, items} = this.props;
    const uniqueItems = new Map<T['value'], T>();

    // This is retrofitting something that will be implemented in further versions of blueprintjs.select
    if (this.shouldAddNewItemLine() && createNewItemFromQuery) {
        const newItem = createNewItemFromQuery(this.query || '') as unknown as T;
        uniqueItems.set(newItem.value, {...newItem, type: SelectItemTypes.Creatable});
    }

    this.props.items.forEach((item) => uniqueItems.set(item.value, item));
    this.transientItems.forEach((item) => this.hasValue(uniqueItems, item) ? undefined : uniqueItems.set(item.value, item));
    return uniqueItems;
  }

  private shouldAddNewItemLine() {
    const {showAddNewValue, creatable, createNewItemFromQuery, items} = this.props;
    return showAddNewValue
        && creatable
        && createNewItemFromQuery
        && items.findIndex((item) => item.text.toLowerCase() === this.query?.toLowerCase()) === -1
        && this.transientItems.findIndex((item) => item.text.toLowerCase() === this.query?.toLowerCase()) === -1;
  }

  private hasValue(uniqueItems: Map<T['value'], T>, itemToCompare: T) {
    for (const itemInMap of uniqueItems.values()) {
      return this.itemsEqual(itemInMap, itemToCompare);
    }
  }

  private get items() {
    return Array.from(this.itemMap.values());
  }

  private get selectedItems() {
    return this.props.selectedItems.map((value) => this.itemMap.get(value));
  }

  private maybeAddNewItem(item: T) {
    if (this.isItemNew(item)) {
      const typelessItem = {...item};
      delete typelessItem.type;
      this.transientItems.push(typelessItem);
      this.props.onAddNewItem && this.props.onAddNewItem(typelessItem);
    }
  }

  private maybeRemoveTransientItem(item: T) {
    const index = this.transientItemIndex(item);
    if (index >= 0) {
      this.transientItems.splice(index, 1);
      this.props.onRemoveTransientItem && this.props.onRemoveTransientItem(item);
    }
  }

  // Oddly this can also get called when clicking on an already selected item
  // in the menu, thus we should remove it.
  private onItemSelect = (item: T, event?: React.SyntheticEvent<HTMLElement>) => {
    if (item.type === SelectItemTypes.Sticky) {
      this.props.onStickySelect?.(item, event);
      return;
    }
    const selectedItems = toJS(this.props.selectedItems);
    const index = this.selectedItemIndex(item);
    if (index >= 0) {
      selectedItems.splice(index, 1);
      this.maybeRemoveTransientItem(item);
    } else {
      selectedItems.push(item.value);
      this.maybeAddNewItem(item);
    }
    this.props.onChange(selectedItems);
  }

  private isItemSelected = (item: SelectItem) => {
    const {selectedItems} = this.props;
    return selectedItems.includes(item.value);
  }

  private get popoverProps(): Partial<PopoverProps> & any {
    const {disabled} = this.props;
    return {...this.props.popoverProps, minimal: true, disabled};
  }

  private onActiveItemChange = (item: T | null, isCreateNew: boolean) => {
    const {onActiveItemChange} = this.props;

    this.activeItem = item;
    if (onActiveItemChange) {
      onActiveItemChange(item, isCreateNew);
    }
  }

  private get clearButton(): JSX.Element | undefined {
    const {disabled, selectedItems} = this.props;
    const onClear = () => {
      this.props.onChange([]);
    };

    if (selectedItems.length && !disabled) {
      return <Button icon="cross" minimal onClick={onClear}/>;
    }
  }

  private get tagInputProps(): Partial<TagInputProps> {
    const {disabled, invalid, tagInputProps = {}} = this.props;

    return {
      ...tagInputProps,
      onRemove: (_, index: number) => {
        const item = this.selectedItems[index];
        item && this.onItemSelect(item);
      },
      rightElement: this.clearButton,
      tagProps: {...tagInputProps.tagProps, minimal: true},
      className: invalid ? Classes.INTENT_DANGER : '',
      disabled
    };
  }

  private tagRenderer = (value: any): string => {
    const item = this.items.find((_item: SelectItem) => value === _item.value);
    return item ? item.text : value;
  }

  private itemListRenderer = (props: ItemListRendererProps<T>) => {
    const {loading, bottomSection, virtualize, itemHeight} = this.props;
    return itemListRenderer<T>({
      itemListRendererProps: props,
      loading,
      bottomSection,
      virtualize,
      itemHeight,
      stickyItems: this.props.stickyItems,
      selectRef: this.multiSelectRef,
      onItemSelect: this.onItemSelect
    });
  }
}
