import {H4, Icon, IconName, Spinner, Tooltip} from '@blueprintjs/core';
import {clone, has, isEmpty, some} from 'lodash';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import classNames from 'classnames';
import {ScrollableContainer, ScrollableContainerProps} from '../ScrollableContainer';
import {SearchInput} from '../SearchInput';
import styles from './DataTable.sass';
import {DataTablePaging} from './DataTablePaging';

// Used by topControls & bottomControls as a way to inject in the paging component.
export const USE_DATATABLE_PAGING = 'DataTablePaging';

export enum DataTableColumnAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface DataTableColumn {
  /**
   * Column title to be displayed in header row.
   */
  title?: string;
  /**
   * An icon to display next to the title.
   */
  icon?: string;
  /**
   * The field within the data row.
   */
  id?: string | number;
  /**
   * When clicking sort on this column, should we default to ASC.
   */
  defaultAsc?: boolean;
  /**
   * Defaults to 'true', makes header clickable and shows sort icons.
   */
  sortable?: boolean;
  /**
   * Formatting function receives value and entire row.
   */
  formatter?: (value: any, row: any) => any;
  /**
   * Tells DataTable to use this column for search (not needed when using loadData).
   */
  searchable?: boolean;
  /**
   * Tells DataTable to use this column for search (not needed when using loadData).
   */
  deletable?: boolean;
  /**
   * Fixes the width of this column AND sets table-layout: fixed.
   */
  width?: string;
  /**
   * Sets text-alignment on column
   */
  align?: DataTableColumnAlignment;
  /**
   * Class Name
   */
  className?: string;
  /**
   * Useful in combination with searchable to add a searchable field without showing it.
   */
  hidden?: boolean;
  /**
   * Formatting function to be used when passing a
   * data structure as the value for a searchable array
   */
  searchFormatter?: (value: object | any[]) => string;
}

export interface NoResultsTexts {
  mainText?: string;
  subCopy?: string;
}

export interface DataTableDataProps {
  /**
   * Column definitions
   */
  columns: DataTableColumn[];
  /**
   * The data to display
   */
  data: any[];
  /**
   * Whether the data is still loading.
   */
  loading: boolean;
}

export interface DataTableUIProps {
  className?: string;
  /**
   * The title (or an element to represent the title) of the table.
   */
  name?: string | JSX.Element;
  // Props related to searching.
  /**
   * Placeholder text for search input, Defaults to "Search {name}".
   */
  searchPlaceholder?: string;
  /**
   * Show or Hide search input. Defaults to false.
   */
  hideSearch?: boolean;
  /**
   * Defaults to 'No results were found'
   */
  noResultsTexts?: NoResultsTexts;
  /**
   * If set, will load this screen instead of fetching data
   */
  coverPage?: (dismissCallback: (event: React.SyntheticEvent<HTMLElement>) => void) => JSX.Element;
  /**
   * Dismissing cover page requires us to trigger refresh
   */
  refresh?: () => void;
  /**
   * Hides the top and bottom divs that contain title/search/paging/etc.
   * @default false
   */
  simple?: boolean;
  /**
   * If specified and met, sets simple to true
   */
  simpleThreshold?: number;

  /**
   * Define what JSX elements/components to render in the top right.
   * Use the special USE_DATATABLE_PAGING string to have the paging component injected in.
   * E.g. topControls={[USE_DATATABLE_PAGING, (<Button>Export</Button>)]}
   * @default USE_DATATABLE_PAGING
   */
  topControls?: Array<JSX.Element | string>;
  /**
   * Define what JSX elements/components to render in the bottom right.
   * Use the special USE_DATATABLE_PAGING string to have the paging component injected in.
   * E.g. topControls={[USE_DATATABLE_PAGING, (<Button>Export</Button>)]}
   * @default USE_DATATABLE_PAGING
   */
  bottomControls?: Array<JSX.Element | string>;
  /**
   * Inclusion of this prop makes headers sticky automatically
   */
  scrollableContainerProps?: ScrollableContainerProps;
  /**
   * Removes the border around the table.
   */
  hideBorder?: boolean;
  /**
   * Hides the paging dropdown and label.
   */
  hidePagingDropDown?: boolean;
  /**
   * Hides the top section, search, title, topControls
   */
  hideTop?: boolean;
  /**
   * Hides the bottom section, bottomControls
   */
  hideBottom?: boolean;
}

export interface DataTablePagingProps {
  /**
   * First row to display.
   */
  rowStart: number;
  /**
   * Number of rows to display per page.
   */
  rowCount: number;
  /**
   * Total number of rows.
   */
  total: number;
}

export interface DataTableSortingProps {
  /**
   * Key of the DataTableColumn that is sorted.
   */
  sortColumn: string;
  /**
   * If enabled the will sort the column ascending.
   */
  sortAsc: boolean;
}

interface DataTableProps extends DataTableDataProps, DataTableUIProps, DataTablePagingProps, DataTableSortingProps {
  /**
   * Search input value
   */
  search?: string;
  /**
   * Callback for deleting columns. Required when using DataTableColumn.deletable.
   */
  onDeleteColumn?: (columnIdx: number) => void;
  /**
   * Callbacks for paging changes.
   */
  onPaging: (pagingProps: DataTablePagingProps) => void;
  /**
   * Callbacks for sorting changes.
   */
  onSorting: (sortingProps: DataTableSortingProps) => void;
  /**
   * Callbacks for search changes.
   */
  onSearch?: (search: string) => void;
}

const ICON_SIZE_XLARGE = 40;

/**
 * DataTable is our base DataTable component. It is stateless and is responsible
 * for rendering the UI and handling the UX (calling the prop callbacks).
 * Consumers of this component will need to manage keeping track of the props
 * and refreshing the data.
 * NOTE: You'll likely want to use AsyncDataTable or SyncDataTable.
 */
@observer
export class DataTable extends React.Component<DataTableProps, {}> {
  @observable private showCoverPage: boolean = false;

  constructor(props: DataTableProps) {
    super(props);
    if (props.search !== undefined && props.search.length > 0) {
      this.dismissCoverPage();
    } else {
      this.showCoverPage = !!props.coverPage;
    }
  }

  @action('<DataTable>#updateSort')
  public updateSort = (column: DataTableColumn) => {
    let sortColumn: string = this.props.sortColumn;
    let sortAsc: boolean = this.props.sortAsc;
    if (column.id === sortColumn) {
      sortAsc = !sortAsc;
    } else {
      sortColumn = column.id as string;
      sortAsc = !!column.defaultAsc;
    }

    this.props.onSorting({sortColumn, sortAsc});
  };

  private static format(row: any, columnDef: DataTableColumn) {
    let value = row[columnDef.id as string];
    if (!value && row instanceof Map) {
      value = value.get(columnDef.id as string);
    }

    if (!columnDef.formatter) {
      return value;
    } else {
      return columnDef.formatter(value, row);
    }
  }

  private dismissCoverPage = () => {
    this.showCoverPage = false;
    this.props.refresh && this.props.refresh();
  };

  @action
  public handleDeleteColumn = (event: any, idx: number): void => {
    event.stopPropagation(); // Prevents triggering a sort order change.
    this.props.onDeleteColumn && this.props.onDeleteColumn(idx);
  };

  private setupControls = (controlsProp: any) => {
    // Cloning here fixes a bug where if controls are defined, the paging would
    // not get updated in async mode. Not sure why, perhaps having the array created
    // here helps the observables work?
    let controls = clone(controlsProp);
    const pagingJsx = (
      <DataTablePaging
        rowStart={this.props.rowStart}
        rowCount={this.props.rowCount}
        total={this.props.total}
        onPaging={this.props.onPaging}
        hideDropDown={this.props.hidePagingDropDown}
      />
    );

    if (!controls) {
      controls = [pagingJsx];
    } else {
      const idx = controls.indexOf(USE_DATATABLE_PAGING);
      if (idx >= 0) {
        controls[idx] = pagingJsx;
      }
    }

    return controls.map((control: any, index: string) => {
      return {...control, key: index};
    });
  };

  private get searchPlaceholderText() {
    const {name, searchPlaceholder} = this.props;

    let placeholderText = '';

    if (searchPlaceholder) {
      placeholderText = searchPlaceholder;
    } else if (typeof name === 'string') {
      placeholderText = name;
    }

    return 'Search ' + placeholderText.toLowerCase();
  }

  public renderColumnHeader = (columnDef: DataTableColumn, idx: number) => {
    const {sortColumn, sortAsc, scrollableContainerProps} = this.props;
    if (columnDef.hidden) {
      return null;
    }

    let headerClassNames = [`${scrollableContainerProps ? styles.stickyHeader : ''}`];
    const sortable = !has(columnDef, 'sortable') || columnDef.sortable;
    const cssStyles = {
      width: columnDef.width,
      textAlign: columnDef.align,
    };

    if (isEmpty(columnDef.title)) {
      return <th key={idx} style={cssStyles} className={headerClassNames.join(' ')} />;
    } else {
      if (columnDef.className) {
        headerClassNames = headerClassNames.concat(columnDef.className);
      }
      const sortAttributes = {
        onClick: () => this.updateSort(columnDef),
      };
      let sortIcon = 'double-caret-vertical';
      if (sortColumn === columnDef.id) {
        sortIcon = sortAsc ? 'caret-up' : 'caret-down';
      }
      if (sortable) {
        headerClassNames.push(styles.sortable);
      }

      headerClassNames.push(columnDef.align === 'right' ? styles.alignRight : styles.alignLeft);

      return (
        <th
          key={idx}
          title={columnDef.title}
          style={cssStyles}
          className={classNames(...headerClassNames)}
          {...(sortable ? sortAttributes : {})}
        >
          {columnDef.icon && <span className={`${styles['column-icon']} bp5-icon-standard ${columnDef.icon}`} />}
          {columnDef.title}
          {sortable && <Icon className={styles['sort-icon']} icon={sortIcon as IconName} iconSize={12} />}
          {columnDef.deletable && (
            <Tooltip content="Remove Column" className={styles['delete-icon']}>
              <Icon
                icon="small-cross"
                // tslint:disable-next-line jsx-no-lambda
                onClick={(event) => this.handleDeleteColumn(event, idx)}
              />
            </Tooltip>
          )}
        </th>
      );
    }
  };

  public renderTable = () => {
    const {columns, data, scrollableContainerProps} = this.props;
    const fixedLayout = some(columns, 'width');
    const table = (
      <table className={fixedLayout ? styles.fixed : ''}>
        <thead>
          <tr>{columns.map(this.renderColumnHeader)}</tr>
        </thead>
        <tbody>
          {data.map((row, rowIdx) => (
            <tr key={rowIdx}>
              {columns.map((columnDef, idx) => {
                if (columnDef.hidden) {
                  return null;
                }
                const classes = [columnDef.className, columnDef.align === 'right' ? styles.alignRight : styles.alignLeft];

                return (
                  <td key={idx} className={classNames(...classes)}>
                    {DataTable.format(row, columnDef)}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
    if (scrollableContainerProps) {
      return (
        <ScrollableContainer className={styles.scrollContainer} {...(this.props.scrollableContainerProps || {})}>
          {table}
        </ScrollableContainer>
      );
    } else {
      return table;
    }
  };

  public render() {
    const {
      className,
      data,
      loading,
      name,
      hideBorder,
      hideSearch,
      noResultsTexts,
      topControls,
      bottomControls,
      search,
      hideTop,
      hideBottom,
      simpleThreshold,
      coverPage,
    } = this.props;
    let {simple} = this.props;
    const {mainText: noResultsText = 'No results were found', subCopy: noResultsSubCopy = null} = noResultsTexts || {};
    const hasColumns = this.props.columns.length !== 0;

    // If a simpleThreshold is specified, update the simple parameter as needed
    if (simpleThreshold) {
      simple = simpleThreshold >= this.props.total;
    }

    return (
      <div
        className={classNames(className, styles.container, {
          [styles['has-border']]: !hideBorder,
        })}
      >
        {!(simple || hideTop) && (
          <div className={styles.top}>
            <div className={styles.searchrow}>
              {name && <H4 className={styles.title}>{name}</H4>}
              {!hideSearch && (
                <SearchInput
                  placeholder={this.searchPlaceholderText}
                  searchTerm={search}
                  changeDebounce={500}
                  // tslint:disable-next-line jsx-no-lambda
                  onChange={(s: string) => {
                    this.showCoverPage = false;
                    this.props.onSearch && this.props.onSearch(s);
                  }}
                />
              )}
            </div>
            <div className={styles.rightControls}>{this.setupControls(topControls)}</div>
          </div>
        )}

        {this.showCoverPage && coverPage ? (
          coverPage(this.dismissCoverPage)
        ) : (
          <div>
            {!hasColumns && (
              <div className={styles.placeholder}>
                <div className={styles.noResults}>
                  <Icon icon="search" iconSize={ICON_SIZE_XLARGE} />
                  <span>Looks like you have not added any columns yet.</span>
                  <span>Add columns to build a report</span>
                </div>
              </div>
            )}

            {hasColumns && loading && (
              <div className={styles.placeholder}>
                <Spinner />
              </div>
            )}

            {hasColumns && !loading && !data.length && (
              <div className={styles.placeholder}>
                <div className={styles.noResults}>
                  <Icon icon="search" iconSize={ICON_SIZE_XLARGE} />
                  <span>{noResultsText}</span>
                  {noResultsSubCopy ? <span className={styles.subCopy}>{noResultsSubCopy}</span> : null}
                </div>
              </div>
            )}

            {!loading && !!data.length && this.renderTable()}
          </div>
        )}

        {!(simple || hideBottom) && (
          <div className={styles.bottom}>
            <div className={styles.rightControls}>{this.setupControls(bottomControls)}</div>
          </div>
        )}
      </div>
    );
  }
}
