import * as React from 'react';
import {DraggableList, DraggableListProps} from '../DraggableList/DraggableList';
import {Card} from '@blueprintjs/core';
import {ListItemWithKey} from '../DraggableList/DraggableListItem';
import styles from './DraggableCards.sass';

type DraggableCardDetailRenderer<TItem extends ListItemWithKey> = (item: TItem, index?: number) => JSX.Element;

// @ts-ignore: OmitByKeys is not found
interface Props<TItem extends ListItemWithKey = ListItemWithKey> extends Partial<OmitByKeys<DraggableListProps<TItem>, 'dragHandleClassName'>> {
  items: TItem[];
  itemRenderer: DraggableCardDetailRenderer<TItem>;
  onChange: DraggableListProps<TItem>['onChange'];
}

export class DraggableCards<TItem extends ListItemWithKey> extends React.PureComponent<Props<TItem>> {
  public static ItemMovementType = DraggableList.ItemMovement;
  public itemRenderer = (
    item: TItem,
    index: number,
    dragHandle: JSX.Element
  ) => {
    return (
      <Card className={styles.listCard}>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-between',
          }}
        >
          {dragHandle}
        </div>
        {this.props.itemRenderer(item, index)}
      </Card>
    );
  }

  public render() {
    return (
      <div>
        <DraggableList
          {...this.props}
          itemRenderer={this.itemRenderer}
          dragHandleClassName={styles.dragHandle}
        />
      </div>
    );
  }
}
