import {Button, Collapse} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import classNames from 'classnames';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {ReactNode} from 'react';
import styles from './ShowHideToggle.sass';

interface Props {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string;

  /**
   * The text for the toggle button.
   */
  button: string;

  /**
   * The elements to show and hide.
   */
  children: ReactNode;
}

@observer
export class ShowHideToggle extends React.Component<Props> {
  @observable private isOpen = false;

  private handleClick = () => {
    this.isOpen = !this.isOpen;
  };

  public render() {
    const {children, button} = this.props;
    const containerClasses = classNames(
      'h-ShowHideToggle',
      this.props.className,
      {[styles.open]: this.isOpen}
    );

    return (
      <div className={containerClasses}>
        <Button
          className={styles.toggle}
          minimal
          icon={IconNames.CHEVRON_RIGHT}
          onClick={this.handleClick}
        >
          {button}
        </Button>
        {/* @ts-ignore: Collapse not JSX component */}
        <Collapse isOpen={this.isOpen} className={styles.main} keepChildrenMounted>
          {children}
        </Collapse>
      </div>
    );
  }
}
