import * as React from 'react';
import {DropTarget, DropTargetCollector, DropTargetConnector, ConnectDropTarget, DropTargetSpec, DropTargetMonitor} from 'react-dnd';
import {LIST_ITEM} from './DraggableListItem';

interface DraggableListItemUnderlayProps {
  connectDropTarget?: ConnectDropTarget;
  hoveredIndex: number | null;
  draggedIndex: number | null;
  onDrop: (indexFrom: number | null, indexTo: number | null) => void;
  onDragLeave?: () => void;
  isOver?: boolean;
  className?: string;
}

const spec: DropTargetSpec<DraggableListItemUnderlayProps> = {
  drop(props, monitor) {
    const {hoveredIndex, draggedIndex} = props;
    props.onDrop(draggedIndex, hoveredIndex);
  }
};

const collect: DropTargetCollector = (
  connect: DropTargetConnector,
  monitor: DropTargetMonitor
) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: !!monitor.isOver()
  };
};

class DraggableListContainerComponent extends React.Component<DraggableListItemUnderlayProps> {
  public UNSAFE_componentWillReceiveProps(nextProps: DraggableListItemUnderlayProps) {
    if (this.props.isOver && !nextProps.isOver && this.props.onDragLeave) {
      // This is drag leave
      this.props.onDragLeave();
    }
  }
  public render() {
    return this.props.connectDropTarget!(
      <div className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

export const DraggableListContainer = DropTarget(LIST_ITEM, spec, collect)(DraggableListContainerComponent);
