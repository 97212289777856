import {Button as BPButton, Tooltip} from '@blueprintjs/core';
import {ElementType, Schema} from '@zaiusinc/app-forms-schema';
import classNames from 'classnames';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import mdStyles from '../markdown.sass';
import styles from './Button.sass';

interface Props {
  schema: Schema.Button | Schema.LinkButton;
  onClick: (action: string) => Promise<any>;
  disabled?: boolean;
  loading?: boolean;
  assetsBaseUrl?: string;
}

@observer
export class Button extends React.Component<Props> {
  @observable private loading = false;

  public render() {
    const {
      disabled,
      loading,
      schema: {label, style}
    } = this.props;
    return this.renderWithTooltip(
      <BPButton
        className={classNames(styles.button, {'bp5-skeleton': loading})}
        disabled={disabled}
        intent={style}
        onClick={this.onClick}
        loading={this.loading}
      >
        {label}
      </BPButton>
    );
  }

  private renderWithTooltip(jsx: JSX.Element) {
    const {help} = this.props.schema;
    if (help) {
      return (
        <Tooltip
          content={<ReactMarkdown className={mdStyles.markdown} children={help} linkTarget="_blank" />}
          hoverOpenDelay={1000}
        >
          {jsx}
        </Tooltip>
      );
    }
    return jsx;
  }

  private onClick = async () => {
    const schema = this.props.schema;
    if (schema.type === ElementType.LinkButton) {
      window.open(schema.href, '_blank')?.focus();
    } else {
      this.loading = true;
      try {
        await this.props.onClick(schema.action);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    }
  };
}
