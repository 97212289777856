import * as React from 'react';
import {findDOMNode} from 'react-dom';
import {action, observable, toJS} from 'mobx';
import {observer} from 'mobx-react';
import {DragDropContextProvider, DndComponent} from 'react-dnd';
import HTML5Backed from 'react-dnd-html5-backend';
import {DraggableListItem, DraggableListTranslation, ListItemWithKey, DragHandleRenderer, DraggableListItemProps} from './DraggableListItem';
import {DraggableListContainer} from './DraggableListContainer';
import styles from './DraggableList.sass';

export type DraggableListItemRenderer<TItem> = (item: TItem, index: number, dragHandle: JSX.Element) => JSX.Element;

export type DraggableListChangeHandler<TItem> = (items: TItem[], oldItems: TItem[]) => void;

export interface DraggableListProps<T extends ListItemWithKey> extends Props<T> {}

interface ItemPosition {
  top: number;
  left: number;
  bottom: number;
  right: number;
  height: number;
  width: number;
}

export enum ItemMovementType {
  SWITCH,
  DISPLACE
}

interface Props<TItem extends ListItemWithKey> {

  /**
   * Items to be represented in the list
   */
  items: TItem[];

  /**
   * Function used to render rows on the list
   * @param item The list item being rendered
   * @param index The index of the item on the list
   * @param dragHandle This is passed in to allow you to place the drag handle
   *  inside your mark up
   */
  itemRenderer: DraggableListItemRenderer<TItem>;

  /**
   * Used to render the drag handle, if you need something other than the default vertical-drag-handle
   */
  dragHandleRenderer?: DragHandleRenderer<TItem>;

  /**
   * Handler for when the order of items in the list changes
   * @param items The array of items after the change
   * @param oldItems The array of items before the change
   */
  onChange: DraggableListChangeHandler<TItem>;

  /**
   * Class name passed down to to be used by the body wrapper for the list items
   */
  itemClassName?: string;

  /**
   * Class name applied to the list
   */
  className?: string;

  /**
   * Class to be applied to the drag handler
   */
  dragHandleClassName?: string;

  /**
   * Pass this if you need to provide your own context
   * for example if this going to be loaded at the same time as another
   * drag-n-drop component
   */
  contextProvided?: boolean;

  /**
   *
   */
  itemMovement?: ItemMovementType;
}

@observer
export class DraggableList<TItem extends ListItemWithKey> extends React.Component<Props<TItem>> {
  public static ItemMovement = ItemMovementType;
  public static defaultProps = {
    ItemMovement: ItemMovementType.DISPLACE
  };
  private static readonly TIME_FOR_ITEM_TO_MOVE = 250;
  @observable private hoveredIndex: number | null = null;
  @observable private draggedIndex: number | null = null;
  @observable private effectiveList: number[] = [];
  private itemRefs: Array<DndComponent<DraggableListItemProps<TItem>, {}>> = [];
  private itemPositions: ItemPosition[] = [];
  private lastMovedTsPerItem: {[key: number]: number} = {};

  constructor(props: any) {
    super(props);
  }

  public componentDidMount() {
    // This is a hack to fix a bug in react-dnd from redisplaying multiple backends
    // @ts-ignore: window on any type
    if (window['__isReactDndBackendSetUp']) {
      // @ts-ignore: window on any type
      window['__isReactDndBackendSetUp'] = false;
    }
  }

  @action
  private handleDrop = (indexFrom: number | null, indexTo: number | null) => {
    if (indexFrom != null && indexTo != null) {
      const newList = this.moveItems(this.props.items, indexFrom, indexTo);
      this.props.onChange(newList, this.props.items);
    }
    this.draggedIndex = null;
    this.hoveredIndex = null;
  }

  @action
  private handleDragLeave = () => {
    this.hoverIndexChanged(null);
  }

  private getEffectiveIndex(index: number) {
    const effectiveIndex = this.effectiveList.indexOf(index);
    return effectiveIndex !== -1 ? effectiveIndex : index;
  }

  private translation(index: number): DraggableListTranslation | undefined {
    if (this.draggedIndex != null && this.draggedIndex !== index) {
      const draggedDimensions = this.itemPositions[this.draggedIndex];
      const originalPosition = this.itemPositions[index];
      const effectiveIndex = this.effectiveList.indexOf(index);
      const effectivePosition = this.itemPositions[effectiveIndex];
      if (effectivePosition && originalPosition) {
        if (effectiveIndex > index) {
          return {
            top: (effectivePosition.top + draggedDimensions.height) - (originalPosition.top + originalPosition.height),
            left: (effectivePosition.left + draggedDimensions.width) - (originalPosition.left + originalPosition.width)
          };
        } else if (effectiveIndex < index) {
          return {
            top: (effectivePosition.top + effectivePosition.height) - (originalPosition.top + draggedDimensions.height),
            left: (effectivePosition.left + effectivePosition.width) - (originalPosition.left + draggedDimensions.width)
          };
        }
      }
    }
  }

  @action
  private moveItems(items: TItem[], indexFrom: number, indexTo: number) {
    const newArray = toJS(items);
    return this.effectiveList.map((index) => newArray[index]);
  }

  @action
  private dragStarted = (index: number) => {
    this.draggedIndex = index;
    this.itemPositions = [];
    for (const ref of this.itemRefs) {
      const element = findDOMNode(ref);
      if (element && element instanceof Element) {
        const {top, left, bottom, right, width, height} = element.getBoundingClientRect();
        this.itemPositions.push({top, left, bottom, right, width, height});
      }
    }
    this.effectiveList = Object.keys(toJS(this.props.items)).map((item) => parseInt(item, 10));
  }

  @action
  private dragEnded = () => {
    this.draggedIndex = null;
    this.effectiveList = [];
  }

  @action
  private hoverIndexChanged = (index: number | null) => {
    this.hoveredIndex = index != null ? this.effectiveList.indexOf(index) : this.draggedIndex;
    if (this.props.itemMovement === ItemMovementType.SWITCH) {
      if (index != null && this.hoveredIndex != null && this.draggedIndex != null) {
        const effectiveDraggedIndex = this.effectiveList.indexOf(this.draggedIndex);
        if (effectiveDraggedIndex !== -1) {
          this.effectiveList.splice(effectiveDraggedIndex, 1, index);
          this.effectiveList.splice(this.hoveredIndex, 1, this.draggedIndex);
        }
      }
    } else {
      if (index != null && this.hoveredIndex != null && this.draggedIndex != null) {
        const effectiveDraggedIndex = this.effectiveList.indexOf(this.draggedIndex);
        if (effectiveDraggedIndex !== -1) {
          this.effectiveList.splice(effectiveDraggedIndex, 1);
        }
        this.effectiveList.splice(this.hoveredIndex, 0, this.draggedIndex);
      }
    }
  }

  private debouncedHoverChanged = (index: number | null) => {
    if (this.shouldInvokeHoverChanged(index)) {
      const effectiveHoveredIndex = this.hoveredIndex || this.draggedIndex;
      if (index != null && effectiveHoveredIndex != null) {
        this.lastMovedTsPerItem = {};
        for (let i = Math.min(effectiveHoveredIndex, index); i <= Math.max(effectiveHoveredIndex, index); i++) {
          this.lastMovedTsPerItem[i] = Date.now();
        }
      }
      this.hoverIndexChanged(index);
    }
  }

  private shouldInvokeHoverChanged(index: number | null) {
    if (index != null) {
      const lastMovedTs = this.lastMovedTsPerItem[index] || 0;
      return lastMovedTs + DraggableList.TIME_FOR_ITEM_TO_MOVE < Date.now();
    }
    return true;
  }

  private getContainerClasses() {
    const classes = [(this.props.className || ''), styles.listContainer, 'h-DraggableList'];
    if (this.draggedIndex != null) {
      classes.push(styles.dragging, 'h-DraggableList-Dragging');
    }
    return classes.join(' ');
  }

  private renderList() {
    this.itemRefs = [];
    return (
      // @ts-ignore: DraggableListContainer not JSX component
      <DraggableListContainer
        onDrop={this.handleDrop}
        onDragLeave={this.handleDragLeave}
        draggedIndex={this.draggedIndex}
        hoveredIndex={this.hoveredIndex}
        className={this.getContainerClasses()}
      >
      {
      (this.props.items || []).map((item, index) => (
        // @ts-ignore: DraggableListItem not JSX component
        <DraggableListItem
          index={index}
          item={item}
          ref={(ref) => ref && this.itemRefs.push(ref)}
          key={item.key}
          className={this.props.itemClassName}
          dragHandleClassName={this.props.dragHandleClassName}
          translation={this.translation(index)}
          effectiveIndex={this.getEffectiveIndex(index)}
          onDrop={this.handleDrop}
          onDragStart={this.dragStarted}
          onDragEnd={this.dragEnded}
          itemRenderer={this.props.itemRenderer}
          draggedIndex={this.draggedIndex}
          hoveredIndex={this.hoveredIndex}
          onHoverChanged={this.debouncedHoverChanged}
          dragHandleRenderer={this.props.dragHandleRenderer}
        />
      ))
      }
      </DraggableListContainer>
    );
  }
  public render() {
    const content = this.renderList();
    if (this.props.contextProvided) {
      return content;
    }
    return (
      // @ts-ignore: DragDropContextProvider not JSX component
      <DragDropContextProvider backend={HTML5Backed}>
        {this.renderList()}
      </DragDropContextProvider>
    );
  }
}
