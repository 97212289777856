import {Label} from '@zaiusinc/hera';
import classNames from 'classnames';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import mdStyles from '../markdown.sass';
import styles from './FieldGroup.sass';

interface Props {
  label?: string;
  help: string;
}

export class FieldGroup extends React.Component<Props> {
  public render() {
    // if the label ends with something in parentheses, pull that out and use it as the label info (muted text)
    const [, label, info] = (this.props.label ?? '').match(/^(.+?)(\([^)(]*?\))?$/) || [null, this.props.label, ''];
    const className = classNames(styles.container, {[styles.noLabel]: !label});
    return (
      <div className={className}>
        <div className={styles.left}>
          <Label label={label} labelInfo={info}>
            {this.props.children}
          </Label>
        </div>
        <div className={styles.right}>
          <div className={styles.help}>
            <ReactMarkdown className={mdStyles.markdown} children={this.props.help} linkTarget="_blank" />
          </div>
        </div>
      </div>
    );
  }
}
