import {Schema} from '@zaiusinc/app-forms-schema';

/**
 * Assigns default values to the given data from the given schema iff the data for that field is undefined
 * @param schema Complete form schema which may include default values
 * @param data form data to add default values too
 */
export function assignDefaultFormValues(schema: Schema.Form, data: Schema.FormData) {
  schema.sections.forEach((section) => {
    section.elements?.forEach((element) => {
      if (element.key && 'defaultValue' in element && element.defaultValue !== undefined) {
        assignIfNotExists(data, section.key, element.key, element.defaultValue);
      }
    });
  });
  return data;
}

function assignIfNotExists(data: Schema.FormData, section: string, field: string, value: Schema.FormValue) {
  if (!data[section]) {
    data[section] = {};
  }
  if (data[section][field] === undefined) {
    data[section][field] = value;
  }
}
