// tslint:disable: max-classes-per-file
import {Checkbox} from '@blueprintjs/core';
import * as React from 'react';
import {observer} from 'mobx-react';
import { Label } from '../Label';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

import styles from './styles/Legend.sass';

// Controls whether the legend value is enabled/disabled
export enum LegendEntryState {
  CHECKED = 'checked',
  UNCHECKED = 'unchecked',
  INDETERMINATE = 'indeterminate'
}

// Controls whether the legend entry can be toggled
export enum LegendEntryInteractionModes {
  NON_INTERACTIVE,
  TWO_STATE,
  THREE_STATE
}

// Controls the display icon for the entry
export enum LegendEntryDisplayType {
  LINE = 'line',
  DASHED = 'dashed-line',
  BAR = 'bar',
  DOT = 'dot'
}
export interface LegendEntryProps {
  className?: string;
  color?: string;
  onToggle?: (value: LegendEntryState) => void;
  value?: LegendEntryState;
  index?: number;
  label: string;
  tooltip?: string;  // TODO: Implement me
  interactionMode?: LegendEntryInteractionModes;
  displayType?: LegendEntryDisplayType;
}

@observer
export class LegendEntry extends React.Component<LegendEntryProps, { value: LegendEntryState }> {
  public static defaultProps = {
    interactionMode: LegendEntryInteractionModes.NON_INTERACTIVE,
    displayType: LegendEntryDisplayType.DOT
  };
  public state: any = {
    value: LegendEntryState.CHECKED
  };

  public componentDidMount() {
    if (this.props.value !== undefined) {
      this.setState({ value: this.props.value });
    }
  }

  private toggle = () => {
    const interactionMode = this.props.interactionMode == null ? LegendEntryInteractionModes.THREE_STATE : this.props.interactionMode;
    let newState = LegendEntryState.INDETERMINATE;
    const currentValue: LegendEntryState = (this.props.value !== undefined) ? this.props.value : this.state.value;
    switch (interactionMode) {
      case LegendEntryInteractionModes.THREE_STATE:
        switch (currentValue) {
          case LegendEntryState.CHECKED:
            newState = LegendEntryState.UNCHECKED;
            break;
          case LegendEntryState.UNCHECKED:
            newState = LegendEntryState.INDETERMINATE;
            break;
          case LegendEntryState.INDETERMINATE:
            newState = LegendEntryState.CHECKED;
            break;
        }
        break;
      case LegendEntryInteractionModes.TWO_STATE:
        switch (currentValue) {
          case LegendEntryState.CHECKED:
            newState = LegendEntryState.UNCHECKED;
            break;
          case LegendEntryState.UNCHECKED:
            newState = LegendEntryState.CHECKED;
            break;
        }
    }
    this.setState({ value: newState });
    this.props.onToggle && this.props.onToggle(newState);
  }

  private get displayTypeClass(): string {
    const { displayType } = this.props;
    return `${styles.legendType} ${styles[`legendType${upperFirst(camelCase(displayType))}`]} legend-type-${displayType} ${displayType}`;
  }

  public legendClassName(label: string, value: LegendEntryState) {
    const { className =  '', color= 'grey-200', index = 0 } = this.props;
    return `legend-entry legend-entry-${value} legend-entry-${index} legend-entry-${color} ${className} `;
  }

  public render(): JSX.Element {
    const { label } = this.props;
    const value = (this.props.value !== undefined) ? this.props.value : this.state.value;
    let checkbox: JSX.Element;
    if (this.props.interactionMode === LegendEntryInteractionModes.NON_INTERACTIVE) {
      checkbox = (
        <div className={`bp5-control bp5-checkbox bp5-inline ${styles.legendEntryNonInteractive} ${this.legendClassName(label, value)}`}>
          <span className={this.displayTypeClass} />
          {label}
        </div>
      );
    } else {
      checkbox = (
        <Checkbox
          checked={value === LegendEntryState.CHECKED}
          indeterminate={value === LegendEntryState.INDETERMINATE}
          label={label}
          className={this.legendClassName(label, value) + ' ' + (value === LegendEntryState.CHECKED ? 'checked' : 'unchecked')}
          onChange={this.toggle}
          inline
        />
      );
    }

    // TODO: Add back tooltip
    return checkbox;
  }
}

@observer
export class Legend extends React.Component<Legend.Props> {
  public render(): JSX.Element {
    return (
      <div className={`${styles.legendContainer} legend`}>
        <Label
          label={this.props.label}
          className={styles.legendOutline}
          muted
        >
          {this.props.children}
        </Label>
      </div>
    );
  }
}

export namespace Legend {
  export interface Props {
    displayMode: 'horizontal' | 'vertical';
    label?: string;
  }
}
