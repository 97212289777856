import * as d3 from 'd3';
import { observe } from 'mobx';
import { inject } from 'mobx-react';
import kebabCase from 'lodash/kebabCase';
import { BuilderContext } from '../types/BuilderContext';
import { DataSeriesBuilder, DataSeriesProps } from '../types/DataSeriesBuilder';
import { ChartTooltip } from '../ChartTooltip';

export interface LineSeriesProps<T> extends DataSeriesProps<T> {
  dash?: string;
  vertexSize?: number;
  ignoreZeros?: boolean;
  extraClassName?: (datum: T, index?: number) => string;
}

@inject('builders', 'chartState')
export class LineSeries<T extends any> extends DataSeriesBuilder<LineSeriesProps<T>, T[]> {
  public static defaultProps = {
    color: 'purple',
    vertexSize: 5,
    ignoreZeros: false,
  };

  private selection?: d3.Selection<any>;

  public lineClassName(datum: T, index: number) {
    const { className, color = '', dataKey } = this.props;
    return `line line-${index} line-${kebabCase(String(dataKey))} line-${color} ${className?.(datum, index) || ''}`;
  }

  public componentWillUnmount(): void {
    this.selection && this.selection.remove();
  }

  public componentDidMount(): void {
    observe(this.chartState, ({object}) => {
      this.selection!.selectAll('circle').data(this.props.data).style('opacity', (d, i) => (this.activeIndex === i) ? 1 : 0);
    });
  }

  public build(chartContext: BuilderContext) {
    return (chartSelection: d3.Selection<SVGSVGElement>) => {
      const { data, dataKey, ignoreZeros, dash = '0', color = '' } = this.props;
      const { x, y } = chartContext;
      // Create the base element if it doesn't exist already
      this.selection = this.selection || chartSelection.append('g').attr('class', 'line-series');

      const line = d3.svg.line<T>()
        .defined((d) => ignoreZeros ? !!d[dataKey] : !isNaN(Number(d[dataKey])))
        .x((_val, index) => x(`${index}`) + x.rangeBand() / 2)
        .y((datum) => y(Number(datum[dataKey])));

      const flatLine = d3.svg.line<T>()
        .defined((d) => ignoreZeros ? !!d[dataKey] : !isNaN(Number(d[dataKey])))
        .x((_val, index) => x(`${index}`) + x.rangeBand() / 2)
        .y(() => y(0));

      const lineSelection = this.selection.selectAll('path').data([data]);
      // Start with a flatLine
      lineSelection.enter().append('path').attr('d', (datum) => flatLine(datum));
      // Animate line actual position
      lineSelection
        .attr('class', (datum, index) => this.lineClassName(datum[index], index))
        .attr('id', () => String(dataKey))
        .attr({ fill: 'none', 'stroke-width': '2', 'stroke-dasharray': dash })
        .transition()
        .attr('d', line)
        // If there are no interaction handlers for the interactions, then we should disable pointer events
        .style('pointer-events', this.props.onClick || this.props.onHoverEnter || this.props.onHoverLeave ? 'initial' : 'none');

      // Animate lines out
      lineSelection.exit().transition().attr('d', (datum) => flatLine(datum)).remove();

      // If there are no interaction handlers for the interactions, then we should not add vertices
      if (this.props.vertexSize! > 0) {
        const interactive = !!(this.props.onClick || this.props.onHoverEnter || this.props.onHoverLeave);
        // instanceof does not work, assuming because of mobx injection
        const hasTooltip = this.injected.builders.filter((item) => item.interactive).length > 0;
        const vertices = this.selection.selectAll('circle').data(data);
        vertices.enter().append('circle').attr('cy', y(0)).attr({
          class: (datum, index) => `circle circle-${index} circle-${color}`,
          r: (datum, index) => this.props.vertexSize || 5,
        }).style('opacity', (d, i) => (hasTooltip || interactive) ? 0 : 1);
        vertices.exit().transition().attr('cy', y(0)).remove();
        // Update the vertex
        const _this = this;
        vertices
          .transition()
          .attr({
            cx: (_datum, index) => x(`${index}`) + x.rangeBand() / 2,
            cy: (datum, index) => y(Number(datum[dataKey])),
          });

        if (interactive) {
          vertices
            .on('click', (datum, index) => {
              const event = d3.event as MouseEvent;
              const target = event.target as Element;
              _this.props.onClick && _this.props.onClick({ element: target, datum, index, ctx: chartContext });
            })
            .on('mouseenter', (datum, index) => {
              const event = d3.event as MouseEvent;
              const target = event.target as Element;
              d3.select(target).classed('hovered', true).style('opacity', 1);
              _this.props.onHoverEnter && _this.props.onHoverEnter({ element: target, datum, index, ctx: chartContext });
            })
            .on('mouseleave', (datum, index) => {
              const event = d3.event as MouseEvent;
              const target = event.target as Element;
              d3.select(target).classed('hovered', false).style('opacity', 0);
              _this.props.onHoverLeave && _this.props.onHoverLeave({ element: target, datum, index, ctx: chartContext });
            });

        }
      }
    };
  }

  public getData() {
    return this.props.data;
  }
  public getRangeMax() {
    const { data, dataKey } = this.props;
    return d3.max(data.map((d) => Number(d[dataKey])));
  }
}
