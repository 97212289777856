export class Address {
  public static hashPath() {
    return window.location.hash.split('?')[0].replace('#', '');
  }

  public static hashParams() {
    return new URLSearchParams(window.location.hash.split('?')[1]);
  }

  public static set(params: { [key: string]: string | number | boolean }) {
    const existing = Address.hashParams();
    for (const [key, value] of Object.entries(params)) {
      if (value == null) {
        existing.delete(key);
      } else {
        existing.set(key, value as string);
      }
    }
    const path = `#${Address.hashPath()}?${existing.toString()}`;
    if (window.history.replaceState) {
      window.history.replaceState(null, '', path);
      // replaceState doesn't trigger a hashchange event, which means that other
      // places which might be relying on a hashchange event to understand what hash
      // parameters are populated won't be informed. So to fix this we can simply manually
      // trigger a hash change event.
      window.dispatchEvent(new HashChangeEvent('hashchange'));
    } else {
      window.location.hash = path;
    }
  }

  public static getParam(key: string): string | null {
    return Address.hashParams().get(key);
  }
  public static setParam(key: string, value: string | number | boolean) {
    Address.set({ [key]: value });
  }
}
