import {InputGroup} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';
import {useDebounce, useInputHandler} from '../../lib/hookHelpers';
import styles from './SearchInput.sass';

const DEFAULT_PLACEHOLDER = 'Search...';

interface Props {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string;

  /**
   * The current search term to display and reset to on blur if applicable
   */
  searchTerm?: string;

  /**
   * Whether the input should be disabled or not
   */
  disabled?: boolean;

  /**
   * Input placeholder text
   */
  placeholder?: string;

  /**
   * Search handler (fired on enter)
   */
  onSearch?: (searchTerm: string) => void;

  /**
   * Input value change handler (no enter required)
   */
  onChange?: (searchTerm: string) => void;

  /**
   * Debounce in miliseconds for the change event
   */
  changeDebounce?: number;

  /**
   * true if the displayed search term should reset to props.searchTerm on blur. Mutually exclusive with `searchOnBlur`.
   */
  resetOnBlur?: boolean;

  /**
   * set to true to fire a search event when the user blurs the search input. Mutually exclusive with `resetOnBlur`.
   */
  searchOnBlur?: boolean;

  /**
   * true if the input should take focus on component mount
   *
   * @default true
   */
  autoFocus?: boolean;
}

export const SearchInput: React.FC<Props> = ({autoFocus, className, disabled, onChange, changeDebounce, onSearch, placeholder, resetOnBlur, searchOnBlur, searchTerm}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [term, setTerm] = React.useState(searchTerm || '');
  const [lastSearchTerm, setLastSearchTerm] = React.useState(term);
  const [lastChangeTerm, setLastChangeTerm] = React.useState(term);

  const handleChange = useInputHandler(React.useCallback((value) => {
    setTerm(value);
  }, [setTerm]));

  const triggerChange = React.useCallback((val: string) => {
    if (val !== lastChangeTerm) {
      setLastChangeTerm(val);
      onChange?.(val);
    }
  }, [lastChangeTerm, setLastChangeTerm, onChange]);

  const debouncedTerm = useDebounce(term, changeDebounce || 0);
  React.useEffect(() => {
    if (debouncedTerm != null && debouncedTerm === term) {
      triggerChange(debouncedTerm);
    }
  }, [lastChangeTerm, debouncedTerm, triggerChange, term]);

  const searchChange = React.useCallback((val: string) => {
    setTerm(val);
    triggerChange(val);
    if (val !== lastSearchTerm) {
      onSearch?.(val);
      setLastSearchTerm(val);
    }
  }, [lastSearchTerm, onSearch, setLastSearchTerm, triggerChange, setTerm]);

  const handleBlur = React.useCallback(() => {
    if (resetOnBlur) {
      setTerm(searchTerm || '');
      triggerChange(searchTerm || '');
    } else if (searchOnBlur) {
      searchChange(term);
    }
  }, [resetOnBlur, searchOnBlur, searchTerm, triggerChange, searchChange, term]);

  // I need to do this because React doesn't support the "search" event natively, but when you press the "clear" button on a search input
  // on Safari/Chrome they only notification you get is a change event, followed by a search event, followed by a blur event.
  // As such this search input never fired a search event for the "clear" button click.
  React.useEffect(() => {
    const handler = (evt: Event) => {
      const val = (evt.currentTarget as HTMLInputElement).value;
      searchChange(val);
    };
    const node = inputRef.current;
    if (node && 'onsearch' in node) {
      (node as HTMLInputElement)?.addEventListener('search', handler);
    } else {
      // Firefox doesn't support the search event :(
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/search_event
      node?.addEventListener('keypress', (evt) => {
        if (evt.key === 'Enter') {
          handler(evt);
        }
      });
    }
    return () => node?.removeEventListener('search', handler);
  }, [inputRef, searchChange]);

  React.useEffect(() => {
    if (autoFocus || autoFocus === undefined) {
      inputRef.current?.focus();
    }
  }, [inputRef, autoFocus]);

  return (
    <div className={classNames('h-SearchInput', styles.container, className)}>
      {/* @ts-ignore: InputGroup not JSX component */}
      <InputGroup
        leftIcon={IconNames.SEARCH}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        type="search"
        placeholder={placeholder || DEFAULT_PLACEHOLDER}
        value={term}
        inputRef={inputRef}
        data-test="SearchForm__input"
      />
    </div>
  );
};
