import {Classes, Collapse, H4, H6, Icon, IconName, Intent} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import classNames from 'classnames';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import {ReactNode} from 'react';
import styles from './Accordion.sass';

const GROUP_STUB = {
  register: () => null,
  unregister: () => null,
  onOpen: () => null
};

interface Props {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string;

  /**
   * Whether the accordion can be opened or not.
   */
  disabled?: boolean;

  /**
   * The header text (left side)
   */
  header: ReactNode;

  /**
   * The icon to display on the right
   */
  rightIcon?: JSX.Element | IconName;

  /**
   * The size of the icon on the right
   */
  iconSize?: number;

  /**
   * The intent (color) of the icon on the right
   */
  iconIntent?: Intent;

  /**
   * Extra text on the right side of the header
   */
  helperText?: ReactNode;

  /**
   * Open or close the accordion. If unspecified it will control itself.
   * @default false
   */
  isOpen?: boolean;

  /**
   * handle click events on the header (if controlling the accordion)
   */
  onClick?: () => void;

  /**
   * true to ensure the accordion is open when first rendered without controlling the accordion otherwise
   * @default false
   */
  startOpen?: boolean;

  /**
   * BlueprintJS elevation level. 1 is normal, 2 for accordions that are within another card
   * @default 1
   */
  elevation?: number;

  /**
   * Renders Accordion without a box-shadowed BlueprintJS Card. Elevation will be ignored.
   */
  minimal?: boolean;

  /**
   * Whether the child components will remain mounted when the `Collapse` is closed.
   * Setting to true may improve performance by avoiding re-mounting children.
   * @default false
   */
  keepChildrenMounted?: boolean;

  children: ReactNode;
}

@observer
export class Accordion extends React.Component<Props> {
  @observable private isOpen: boolean = false;
  @observable private isHovering: boolean = false;
  public static contextTypes = {
    accordionGroup: PropTypes.object
  };
  public static defaultProps = {
    elevation: 1
  };

  constructor(props: Props) {
    super(props);
    if (props.startOpen || props.isOpen) {
      this.isOpen = true;
    }
  }

  public close() {
    // only close if we're not being controlled
    if (this.props.isOpen === undefined) {
      this.isOpen = false;
    }
  }

  private get group() {
    return this.context.accordionGroup || GROUP_STUB;
  }

  private onHeaderClick = () => {
    if (this.props.disabled) { return; }

    if (this.props.onClick) {
      this.props.onClick();
    }

    // only toggle if we're not being controlled
    if (this.props.isOpen === undefined) {
      if (!this.isOpen) {
        this.isOpen = true;
        this.group.onOpen(this);
      } else {
        this.isOpen = false;
      }
    }
  }

  private onMouseEnterHeader = () => {
    this.isHovering = true;
  }

  private onMouseLeaveHeader = () => {
    this.isHovering = false;
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
    if (nextProps.isOpen !== undefined) {
      this.isOpen = nextProps.isOpen;
    }
  }

  public UNSAFE_componentWillMount(): void {
    this.group.register(this);
  }

  public componentDidMount(): void {
    if (this.isOpen) {
      this.group.onOpen(this);
    }
  }

  public componentWillUnmount(): void {
    if (this.context.accordionGroup) {
      this.context.accordionGroup.unregister(this);
    }
  }

  private renderHeader() {
    const {header, minimal} = this.props;

    if (typeof header !== 'string') {
      return header;
    } else if (minimal) {
      return <H6>{header}</H6>;
    } else {
      return <H4>{header}</H4>;
    }
  }

  private renderRightIcon() {
    const {rightIcon, iconSize, iconIntent} = this.props;

    if (typeof rightIcon === 'string') {
      const extraClassName = rightIcon === IconNames.TICK_CIRCLE ? styles.iconTickCircle : '';

      return <span className={styles.icon}><Icon className={extraClassName} icon={rightIcon} size={iconSize} intent={iconIntent} /></span>;
    }
    return <span className={styles.icon}>{rightIcon}</span>;
  }

  private preventDefault(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  public render() {
    const {children, disabled, helperText, minimal, keepChildrenMounted} = this.props;
    const elevation = this.isHovering && !disabled ? Classes.ELEVATION_3 : (this.props.elevation === 2 ? Classes.ELEVATION_2 : Classes.ELEVATION_1);
    const cardClasses = [elevation, Classes.CARD];
    const isOpen = this.isOpen && !disabled;

    return (
      <div
        className={classNames('h-Accordion', styles.container, {[styles.open]: isOpen, [styles.minimal]: minimal, [cardClasses.join(' ')]: !minimal}, this.props.className)}
      >
        <div
          className={classNames(styles.header, {[styles.minimal]: minimal, [styles.disabled]: disabled})}
          onClick={this.onHeaderClick}
          onMouseDown={this.preventDefault}
          onMouseEnter={this.onMouseEnterHeader}
          onMouseLeave={this.onMouseLeaveHeader}
        >
          <span className={classNames(styles.left, {[styles.minimal]: minimal})}>
            {!disabled &&
              <Icon
                icon={IconNames.CHEVRON_RIGHT}
                className={classNames(styles.icon, {[styles.open]: this.isOpen})}
              />
            }
            {this.renderHeader()}
          </span>

          <span className={styles.right}>
            <span className={styles.helperText}>{helperText}</span>
            {this.renderRightIcon()}
          </span>
        </div>
        {/* @ts-ignore: Collapse not JSX component */}
        <Collapse isOpen={isOpen} keepChildrenMounted={keepChildrenMounted}>
          <div className={classNames(styles.body, {[styles.minimal]: minimal})}>
            {children}
          </div>
        </Collapse>
      </div>
    );
  }}
