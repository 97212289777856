import {Menu, MenuItem} from '@blueprintjs/core';
import {DateRange, DateRangePicker, DateRangePickerProps} from '@blueprintjs/datetime';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import {action, computed, observable, toJS} from 'mobx';
import {observer} from 'mobx-react';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import styles from './DatePicker.sass';
import {DatePickerState, DateRangeOption, DefaultKeyType} from './DatePickerState';

// Re-export these since they're useful for interacting with the date picker
export {DateRangeOption} from './DatePickerState';

// @ts-ignore: OmitByKeys not found
export interface DatePickerProps extends OmitByKeys<DateRangePickerProps, 'onChange' | 'value'> {
  /**
   * Called when the user selects a day or DateRangeOption.
   * If dateRangeOption are selected, it will pass `DateRangeOption`.
   * If `Custom Range` dateRangeOption selected, it will pass `DateRangeOption` with value `start: null, end: null]`.
   * If a start date is selected but not an end date, it will pass `start: selectedDate, end: null]`.
   * If both a start and end date are selected, it will pass `start: startDate, end: endDate`.
   */
  onChange?: (option: DateRangeOption) => void;

  /**
   * The currently selected `DateRange` 'MM/DD/YYYY'[].
   * If this prop is provided, the component acts in a controlled manner.
   */
  value?: string[] | undefined;

  /**
   * Whether dateRangeOptions to quickly select a range of Utils are displayed or not.
   * If `true`, default dateRangeOptions will be displayed.
   * If `false`, no dateRangeOptions will be displayed.
   * If an array is provided, the custom DateRangeOptions will be displayed.
   * dateRangeOptions:
   * {
   *    key: string | DefaultKeyType;
   *    label: string;
   *    start?: 'MM/DD/YYYY';
   *    end?: 'MM/DD/YYYY';
   *    quantity?: number;
   *    unit?: 'year' | 'month '| 'week '| 'day '| 'hour '| 'minute '| 'second '| 'millisecond';
   * }[]
   * @default true
   */
  dateRangeOptions?: DateRangeOption[] | boolean;

  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string;

  /**
   * Active dateRangeOption for displaying.
   */
  initialActiveOptionKey?: string | DefaultKeyType;

  /**
   * Position of dateRangeOptions menu `left | right`.
   * @default 'left'
   */
  position?: 'left' | 'right';

  /**
   * Helper text for date drop down picker (optional)
   */
  helperText?: string;
}

@observer
export class DatePicker extends React.Component<DatePickerProps> {
  @observable public _datePickerState: DatePickerState | undefined;

  public componentDidUpdate(prevProps: DatePickerProps): void {
    if (!this.context.datePickerState) {
      return;
    }
    const start = this.context.datePickerState?.start;
    const end = this.context.datePickerState?.end;
    const startAsString = DatePickerState.dateToString(start);
    const endAsString = DatePickerState.dateToString(end);
    const newVals = [startAsString, endAsString];
    if (!isEqual(prevProps.value, newVals)) {
      this.datePickerState.setValue(start, end);
    }
  }

  public static contextTypes = {
    datePickerState: PropTypes.object
  };

  @computed
  public get datePickerState(): DatePickerState {
    return this.context.datePickerState || this.datePickerStateInstance();
  }

  @action
  private datePickerStateInstance(): DatePickerState {
    const {
      dateRangeOptions,
      initialActiveOptionKey,
      value,
      onChange
    } = this.props;
    this._datePickerState = this._datePickerState || new DatePickerState(dateRangeOptions, initialActiveOptionKey, DatePickerState.normalizedDateRange(value), onChange);
    return this._datePickerState;
  }

  @computed
  private get rangePickerProps() {
    return {
      ...omit(this.props, ['className', 'initialActiveOptionKey', 'position', 'dateRangeOptions']),
      allowSingleDayRange: true,
      onChange: this.datePickerState.onDatePickerChangeHandler,
      value: [toJS(this.datePickerState.start), toJS(this.datePickerState.end)] as DateRange | undefined,
      shortcuts: false
    };
  }

  @computed
  private get renderDateRangeOptions() {
    const { dateRangeOptions } = this.datePickerState;
    return (
      <Menu>
        {
          dateRangeOptions
            .map(({start, end, label, key, unit, quantity}, index) => {
              return (
                <MenuItem
                  key={index}
                  text={label}
                  onClick={this.datePickerState.handleDateRangeOptionClick({start, end, label, key, unit, quantity})}
                  active={this.isDateRangeOptionsActive(key)}
                  shouldDismissPopover={key !== DatePickerState.DEFAULT_CUSTOM_OPTION.key}
                />
              );
            })
        }
      </Menu>
    );
  }

  private isDateRangeOptionsActive(dateRangeOptionsKey: string): boolean {
    return this.datePickerState.activeDateRangeOptionsKey === dateRangeOptionsKey;
  }

  public render() {
    const {
      dateRangeOptions,
      showRangePicker
    } = this.datePickerState;

    const {
      className,
      position,
      helperText
    } = this.props;

    return (
      <div className={classNames('h-DatePicker', styles.container, {[styles.rangePickerShow]: showRangePicker}, className)}>
        {
          dateRangeOptions.length > 0 && (!position || position === 'left') &&
            <div className={styles.dateRangeOptions}>
              {this.renderDateRangeOptions}
            </div>
        }
        <div className={styles.dateRangePicker}>
          {showRangePicker &&
            <div>
              <div className={styles.column}>
                <DateRangePicker {...this.rangePickerProps}/>
              </div>
              {helperText &&
                <div className={classNames(styles.column, styles.helperText)}>
                  {helperText}
                </div>
              }
            </div>
          }
        </div>
        {
          dateRangeOptions.length > 0 && position === 'right' &&
            <div className={styles.dateRangeOptions}>
              {this.renderDateRangeOptions}
            </div>
        }
      </div>
    );
  }
}
