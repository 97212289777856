import {DateRange} from '@blueprintjs/datetime';
import isArray from 'lodash/isArray';
import {action, computed, observable} from 'mobx';
import {DurationInputArg1, DurationInputArg2} from 'moment';
import * as moment from 'moment-timezone';

export type DefaultKeyType = 'all' | 'days_7' | 'weeks_4' | 'weeks_13';

export interface DateRangeOption {
  key: string | DefaultKeyType;
  label: string;
  start?: string | undefined;
  end?: string | undefined;
  quantity?: DurationInputArg1;
  unit?: DurationInputArg2;
}

export interface OnChangeParams {
  closePopover?: boolean;
}

export class DatePickerState {
  @observable public dateRangeOptions: DateRangeOption[] = [];
  @observable public showRangePicker: boolean = false;

  @observable public start: Date | undefined;
  @observable public end: Date | undefined;

  @observable public previousActiveDateRangeOptionsKey: DefaultKeyType | string | undefined;
  @observable public previousStart: Date | undefined;
  @observable public previousEnd: Date | undefined;
  @observable public activeDateRangeOptionsKey: DefaultKeyType | string | undefined;

  private readonly onChangeCb: (option: DateRangeOption, params?: OnChangeParams) => void;

  public static DEFAULT_CUSTOM_OPTION: DateRangeOption = Object.freeze({
    key     : 'custom_range',
    start   : undefined,
    end     : undefined,
    label   : 'Custom Range'
  });

  public static DEFAULT_OPTIONS: DateRangeOption[] = [
    {
      key     : 'all',
      label   : 'All Time'
    },
    {
      key      : 'days_7',
      unit     : 'days',
      quantity : 7,
      label    : 'Past 7 days'
    },
    {
      key      : 'weeks_4',
      unit     : 'weeks',
      quantity : 4,
      label    : 'Past 4 weeks'
    },
    {
      key      : 'weeks_13',
      unit     : 'weeks',
      quantity : 13,
      label    : 'Past 13 weeks'
    },
    DatePickerState.DEFAULT_CUSTOM_OPTION
  ];

  constructor(dateRangeOptions: any, initialActiveOptionKey: any, value: any, onChange: any) {
    this.onChangeCb = onChange;
    this.dateRangeOptions = DatePickerState.setDateRangeOptions(dateRangeOptions);
    this.initializeFirstActiveDate(initialActiveOptionKey, value);
  }

  public onDatePickerChangeHandler = (value: DateRange | undefined): void => {
    const [start, end] = value || [undefined, undefined];

    this.setValue(start, end);
    this.setPreviousValue(start, end);
    this.previousActiveDateRangeOptionsKey = DatePickerState.DEFAULT_CUSTOM_OPTION.key;

    if (start && end) {
      this.onChange({
        ...DatePickerState.DEFAULT_CUSTOM_OPTION,
        start: DatePickerState.dateToString(start),
        end: DatePickerState.dateToString(end)
      });
    }
  }

  public handleDateRangeOptionClick(dateRangeOption: DateRangeOption): () => void {
    return () => {
      if (this.activeDateRangeOptionsKey !== DatePickerState.DEFAULT_CUSTOM_OPTION.key) {
        this.clearValue();
      }

      if (dateRangeOption.key !== DatePickerState.DEFAULT_CUSTOM_OPTION.key) {
        const selectedOption = DatePickerState.buildFullCalculatedDateRangeOption(dateRangeOption);
        this.onChange(selectedOption);
      }

      this.setActiveDateRangeOptionKey(dateRangeOption.key);
    };
  }

  public findDateRangeOptionByKey(key: string | undefined): DateRangeOption | undefined {
    return key ? this.dateRangeOptions.find((dateRangeOption) => dateRangeOption.key === key) : undefined;
  }

  @computed
  public get previousActiveDateRangeOption(): DateRangeOption | undefined {
    return this.findDateRangeOptionByKey(this.previousActiveDateRangeOptionsKey);
  }

  public setActivePreviousDateRangeOption = (): void => {
    if (this.previousActiveDateRangeOptionsKey === DatePickerState.DEFAULT_CUSTOM_OPTION.key) {
      this.setValue(this.previousStart, this.previousEnd);
      return;
    }
    if (this.previousActiveDateRangeOption) {
      this.setActiveDateRangeOptionKey(this.previousActiveDateRangeOption.key);
    }
  }

  @action
  public setValue(start: any, end: any): void {
    this.start = start;
    this.end = end;
  }

  @computed
  public get activeDateRangeOption(): DateRangeOption | undefined {
    return this.dateRangeOptions.find((dateRangeOption) => dateRangeOption.key === this.activeDateRangeOptionsKey);
  }

  @computed
  public get isCustomRangeDataValid(): boolean {
    return !!(this.start && this.end);
  }

  @action
  private initializeFirstActiveDate(initialActiveOptionKey: any, value: any): void {
    if (this.dateRangeOptions.length) {
      this.setInitialActiveDateRangeOption(initialActiveOptionKey, value);
    } else {
      this.setInitialDateForEmptyDateRangeOptions(value);
    }
  }

  @action
  private setInitialDateForEmptyDateRangeOptions(value: DateRange | undefined) {
    const [start, end] = value || [undefined, undefined];
    this.showRangePicker = true;
    this.setValue(start, end);
    this.activeDateRangeOptionsKey = DatePickerState.DEFAULT_CUSTOM_OPTION.key;
    if (this.isCustomRangeDataValid) {
      this.setPreviousValue(start, end);
      this.previousActiveDateRangeOptionsKey = DatePickerState.DEFAULT_CUSTOM_OPTION.key;
    }
  }

  @action
  private clearValue(): void {
    this.setValue(undefined, undefined);
  }

  @action
  private setPreviousValue(start: any, end: any): void {
    this.previousStart = start;
    this.previousEnd = end;
  }

  @action
  private setActiveDateRangeOptionKey(key: string | undefined): void {
    this.previousActiveDateRangeOptionsKey = this.activeDateRangeOptionsKey;
    this.activeDateRangeOptionsKey = key;
    this.showRangePicker = key === DatePickerState.DEFAULT_CUSTOM_OPTION.key;
  }

  private onChange(dateRangeOption: DateRangeOption, params?: OnChangeParams): void {
    this.onChangeCb && this.onChangeCb(dateRangeOption, params);
  }

  private setInitialActiveDateRangeOption(initialActiveOptionKey: string, value: DateRange | undefined): void {
    const key = initialActiveOptionKey || (value ? DatePickerState.DEFAULT_CUSTOM_OPTION.key : this.dateRangeOptions[0].key);
    this.setActiveDateRangeOptionKey(key);
    // Wait one tick.
    setTimeout(() => {
      this.handleInitialChange(value);
    });
  }

  private handleInitialChange(value: any): void {
    if (this.activeDateRangeOption) {
      if (this.activeDateRangeOption.key === DatePickerState.DEFAULT_CUSTOM_OPTION.key) {
        this.onDatePickerChangeHandler(value);
      } else {
        const selectedOption = DatePickerState.buildFullCalculatedDateRangeOption(this.activeDateRangeOption);
        this.onChange(selectedOption);
      }
    }
  }

  private static buildFullCalculatedDateRangeOption(dateRangeOption: DateRangeOption): DateRangeOption {
    const [start, end] = DatePickerState.calcStartEndFromOption(dateRangeOption);
    return {
      ...dateRangeOption,
      start: DatePickerState.dateToString(start),
      end: DatePickerState.dateToString(end),
    };
  }

  private static setDateRangeOptions(dateRangeOptions: DateRangeOption[] | boolean): DateRangeOption[] {
    if (dateRangeOptions || dateRangeOptions === undefined) {
      if (isArray(dateRangeOptions)) {
        return [...dateRangeOptions, DatePickerState.DEFAULT_CUSTOM_OPTION];
      }
      return DatePickerState.DEFAULT_OPTIONS;
    }
    return [];
  }

  public static calcStartEndFromOption(dateRangeOption: DateRangeOption): Array<Date | undefined> {
    const quantity = dateRangeOption.quantity || 1;
    const unit = dateRangeOption.unit;
    return [
      (dateRangeOption.start ? DatePickerState.stringToDate(dateRangeOption.start) : undefined) || DatePickerState.calcDate(quantity, unit),
      dateRangeOption.end ? DatePickerState.stringToDate(dateRangeOption.end) : undefined
    ];
  }

  public static normalizedDateRange(value: string[] | undefined): DateRange | undefined {
    return value && value.map((date: string | undefined) => {
      return DatePickerState.stringToDate(date);
    }) as DateRange | undefined;
  }

  public static dateToString(date: Date | undefined): string | undefined {
    return date
      ? moment.tz(date, DatePickerState.browserTimeZone).format('MM/DD/YYYY')
      : undefined;
  }

  public static stringToDate(stringDate: string | undefined): Date | undefined {
    return stringDate
      ? moment.tz(stringDate, 'MM/DD/YYYY', DatePickerState.browserTimeZone).toDate()
      : undefined;
  }

  private static calcDate(quantity: any, unit: any): Date | undefined {
    return unit
      // @ts-ignore: moment declaration
      ? moment().subtract(quantity, unit).startOf('day').toDate()
      : undefined;
  }

  @computed
  private static get browserTimeZone() {
    return moment.tz.guess();
  }
}
