import { observable } from 'mobx';
import { BuilderContext } from './types/BuilderContext';

export interface ChartState {
  active: boolean;
  activeIndex: number;
  activeContext?: BuilderContext;
}

export const createChartState = () => {
  return observable({
    active: false,
    activeIndex: -1
  });
};
