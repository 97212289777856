import {Schema} from '@zaiusinc/app-forms-schema';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import joinUri from 'url-join';
import {transformImageUri} from '../lib/transformUri';
import mdStyles from '../markdown.sass';
import styles from './Instructions.sass';
import remarkGfm from 'remark-gfm';
interface Props {
  schema: Schema.ParagraphElement;
  disabled?: boolean;
  assetsBaseUrl: string;
}

export class Instructions extends React.Component<Props> {
  public render() {
    const {
      schema: {text},
      assetsBaseUrl
    } = this.props;

    return (
      <div className={styles.instructions}>
        <ReactMarkdown
          className={mdStyles.markdown}
          children={text}
          transformImageUri={(uri) => transformImageUri(uri, joinUri(assetsBaseUrl, 'directory/'))}
          linkTarget="_blank"
          remarkPlugins={[remarkGfm]}
        />
      </div>
    );
  }
}
