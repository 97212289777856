import * as React from 'react';
import {OrderedLineDatum, OrderedLinesDatum, InteractionHandler} from '../types';
import {LineSeries, LineSeriesProps} from './LineSeries';
import { CircleSeries, CircleSeriesProps } from './CircleSeries';

export interface LineDataSeries {
  label: string;
  series: OrderedLineDatum[];
}

export interface MultiLineProps<T, TItem> {
  data: T[];
  lineClassName?: (datum: LineDataSeries, index?: number) => string;
  vertexClassName?: (datum: LineDataSeries, index?: number) => string;
  colors?: string[];
  axis?: string;
  showVertices?: boolean;
  onHoverEnter?: InteractionHandler<TItem>;
  onHoverLeave?: InteractionHandler<TItem>;
  onClick?: InteractionHandler<TItem>;
  vertexSize?: (item: TItem, index: number) => number;
}

export class MultiLineSeries extends React.Component<MultiLineProps<OrderedLinesDatum, OrderedLineDatum>> {
  public static defaultProps = {
    colors: ['blue', 'purple', 'aqua', 'green', 'red', 'orange', 'pink', 'deep-blue', 'navy', 'mist'],
    showVertices: true,
  };

  private separateLines(data: OrderedLinesDatum[]) {
    const lines: {[key: string]: {series: OrderedLineDatum[], label: string}} = {};
    data.forEach((datum, index) =>
      datum.lines.forEach((vertex) => {
        if (!lines[vertex.label]) {
          lines[vertex.label] = {
            series: [],
            label: vertex.label
          };
        }
        lines[vertex.label].series.push(vertex);
      })
    );
    return Object.values(lines);
  }

  public render(): JSX.Element {
    const { lineClassName, vertexClassName, colors, showVertices, onHoverEnter, onHoverLeave } = this.props;
    const lineData: LineDataSeries[] = this.separateLines(this.props.data);

    const lines: Array<LineSeriesProps<OrderedLineDatum>> = lineData.map((ls: LineDataSeries, i: number) => {
      return {
        data: ls.series,
        dataKey: 'value',
        color: colors![i],
        className: lineClassName ? lineClassName(ls, i) : undefined,
      };
    }) as Array<LineSeriesProps<OrderedLineDatum>>;

    const verticies: Array<CircleSeriesProps<OrderedLineDatum>> = lineData.map((ls: LineDataSeries, i: number) => {
      return {
        data: ls.series,
        dataKey: 'value',
        color: colors![i],
        className: vertexClassName ? vertexClassName(ls, i) : undefined,
        onHoverEnter,
        onHoverLeave
      };
    }) as Array<CircleSeriesProps<OrderedLineDatum>>;

    return (
      <g className="multiline-ref">
        {lines.map((props, i) => <LineSeries key={i} {...props} />)}
        {showVertices && verticies.map((props, i) => <CircleSeries key={i} {...props} />)}
      </g>
    );

  }

}
