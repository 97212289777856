import {useEffect, useCallback, useState, ChangeEvent} from 'react';

/**
 * Used to convert a standard ChangeEvent onChange from an input, e.g. `<input onChange={...}>` to a string callback, just avoids having to use `ev.currentTarget.value`.
 * For example
 * ```
 * useCallback((ev: React.ChangeEvent<HTMLInputElement>) => onChange(ev.currentTarget.value), [onChange])
 * ```
 * Can be slightly reduced to
 * ```
 * useCallback(useInputHandler((val: string) => onChange(val)), [onChange])
 * ```
 * @param onChange
 */
export function useInputHandler(
  onChange: (val: string, ev: ChangeEvent<HTMLInputElement>) => void
): (ev: ChangeEvent<HTMLInputElement>) => void {
  return useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    onChange(ev.currentTarget.value, ev);
  }, [onChange]);
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      if (delay <= 0) {
        setDebouncedValue(value);
      } else {
        const timer = setTimeout(() => setDebouncedValue(value), delay);
        // Cleanup if useEffect is called again before our timer expires
        return () => clearTimeout(timer);
      }
    },
    [value, delay]
  );

  return debouncedValue;
}
