import * as d3 from 'd3';
import { observe } from 'mobx';
import { inject } from 'mobx-react';
import kebabCase from 'lodash/kebabCase';
import {InteractionHandler} from '../types';
import {BuilderContext} from '../types/BuilderContext';
import {DataSeriesBuilder} from '../types/DataSeriesBuilder';

interface D3Stack {
  x: string;
  y: number;
  y0?: number;
}

export interface StackedBarSeriesProps<T> {
  data: T[];
  dataKeys: Array<keyof T>;
  axis?: string;
  colors?: string[];
  className?: (datum: D3Stack[], index?: number) => string;
  onHoverEnter?: InteractionHandler<T>;
  onHoverLeave?: InteractionHandler<T>;
  onClick?: InteractionHandler<T>;
  activeIndex?: number;
}

@inject('builders', 'chartState')
export class StackedBarSeries<T extends object> extends DataSeriesBuilder<StackedBarSeriesProps<T>, T[]> {
  public static defaultProps = {
    colors: ['blue', 'deep-blue', 'aqua']
  };

  private selection?: d3.Selection<any>;
  private bars?: d3.Selection<any>;

  public barClassName(datum: D3Stack[], index: number) {
    const { className, colors, dataKeys } = this.props;
    const userDefinedClass = className ? className(datum, index) : '';
    return `layer bar bar-${index} bar-${kebabCase(String(dataKeys[index]))} bar-${colors![index]} ${userDefinedClass}`;
  }

  public componentWillUnmount() {
    this.selection && this.selection.remove();
  }

  public componentDidMount() {
    observe(this.chartState, ({object}) => {
      this.bars && this.bars.classed('hovered', (d, i) => (this.activeIndex === i));
    });
  }

  public build(chartContext: BuilderContext) {
    return (chartSelection: d3.Selection<SVGSVGElement>) => {
      const {dataKeys, data} = this.props;
      const {x, y} = chartContext;
      // Create the base element if it doesn't exist already
      this.selection = this.selection || chartSelection.append('g').attr('class', 'stacked-bar-area');

      if (!this.selection) { return null; }

      const z = d3.scale.category10();
      // const stack = d3.layout.stack<{x: string, y: number}>();
      const layers = d3.layout.stack<D3Stack>()(dataKeys.map((key: keyof T) => {
        return data.map((d: T, i: number) => ({ x: `${i}`, y: Number(d[key])}));
      }));
      // x.domain(layers[0].map((d) =>  d.x ));
      // y.domain([0, d3.max(layers[layers.length - 1], (d) =>  d.y0 + d.y)]).nice();

      const group = this.selection.selectAll('g.layer').data(layers);
      group.exit().remove();
      group
        .enter()
        .append('g')
        .attr('class', (d, i) => this.barClassName(d, i));

      const bars = group.selectAll('rect').data((d) => d);
      this.bars = bars;
      bars
        .classed('hovered', (d, i) => (this.activeIndex === i))
        .on('click', (d, index) => {
          const event = d3.event as MouseEvent;
          const target = event.target as Element;
          const datum = data[index];
          this.props.onClick && this.props.onClick({element: target, datum, index, ctx: chartContext});
        })
        .on('mouseenter', (d, index) => {
          const event = d3.event as MouseEvent;
          const target = event.target as Element;
          const datum = data[index];
          d3.select(target).classed('hovered', true);
          this.props.onHoverEnter && this.props.onHoverEnter({element: target, datum, index, ctx: chartContext});
        })
        .on('mouseleave', (d, index) => {
          const event = d3.event as MouseEvent;
          const target = event.target as Element;
          const datum = data[index];
          d3.select(target).classed('hovered', false);
          this.props.onHoverLeave && this.props.onHoverLeave({element: target, datum, index, ctx: chartContext});
        })
        // If there are no interaction handlers for the interactions, then we should disable pointer events
        .style('pointer-events', this.props.onClick || this.props.onHoverEnter || this.props.onHoverLeave ? 'initial' : 'none');
      bars.exit().remove();
      bars.enter().append('rect')
        .attr('width', x.rangeBand());
      bars
        .transition().duration(300)
        .attr('x', (d) => x(d.x))
        .attr('y', (d) => y(d.y + d.y0!))
        .attr('height', (d) => y(d.y0!) - y(d.y + d.y0!))
        .attr('width', x.rangeBand() - 1);

    };
  }
  public getData() {
    return this.props.data;
  }
  public getRangeMax() {
    const { data, dataKeys } = this.props;
    const totals = data.map((d) => dataKeys.reduce((p, c) => p + Number(d[c]), 0));
    return d3.max(totals);
  }
}
