import {
  Button, Menu, MenuItem, Popover, Position, Tooltip, ButtonGroup
} from '@blueprintjs/core';
import {IconNames, IconName} from '@blueprintjs/icons';
import * as React from 'react';
import { observer} from 'mobx-react';
import classNames from 'classnames';
import styles from './SortDropdown.sass';

export interface SortOption {
  text: string;
  sortBy: string;
  type: SortTypes;
  defaultDirection: SortDirections;
  disabled?: boolean;
}

export enum SortDirections {
  ASC = 'asc',
  DESC = 'desc'
}

export enum SortTypes {
  NUMERIC = 'numeric',
  ALPHABETICAL = 'alphabetical'
}

interface SortDropdownProps {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string;
  /**
   * A title to display left to the component.
   */
  title?: string | JSX.Element;
  /**
   * A list of SortOptions with types and
   */
  options: SortOption[];
  /**
   * Selected sort option
   */
  selectedOption: SortOption;
  /**
   * Selected sort direction
   */
  sortDirection: SortDirections;
  onChange: (option: SortOption, direction: SortDirections) => void;
  /**
   * A Blueprint Popover position
   * @default Position.BOTTOM_RIGHT
   */
  position?: Position;
}

@observer
export class SortDropdown extends React.Component<SortDropdownProps, {}> {
  private TOOLTIP_DELAY = 500;

  private sortDirectionIconNames: {[T in SortTypes]: {[D in SortDirections]: IconName}} = {
    [SortTypes.NUMERIC]: {
      [SortDirections.ASC]: IconNames.SORT_ASC,
      [SortDirections.DESC]: IconNames.SORT_DESC
    },
    [SortTypes.ALPHABETICAL]: {
      [SortDirections.ASC]: IconNames.SORT_ALPHABETICAL,
      [SortDirections.DESC]: IconNames.SORT_ALPHABETICAL_DESC
    },
  };

  public selectOption = (option: SortOption) => () => {
    this.props.onChange(option, option.defaultDirection);
  }

  public onDirectionChange = () => {
    this.props.onChange(
      this.props.selectedOption,
      this.props.sortDirection === SortDirections.ASC ? SortDirections.DESC : SortDirections.ASC
    );
  }

  get directionIconClass(): IconName {
    const {sortDirection, selectedOption} = this.props;
    return this.sortDirectionIconNames[selectedOption.type][sortDirection];
  }

  get optionsMenu() {
    const {options} = this.props;
    return (
      <Menu>
        {options.map((option) =>
          <MenuItem
            className={styles.option}
            key={option.text}
            text={option.text}
            disabled={option.disabled}
            onClick={this.selectOption(option)}
          />
        )}
      </Menu>
    );
  }

  public render() {
    const {
      title,
      selectedOption,
      sortDirection,
      position
    } = this.props;
    const {optionsMenu, directionIconClass} = this;
    return (
      <div className={classNames('h-SortDropdown', styles.container)}>
        {!!title && <span className={styles.title}>{title}</span>}
        <ButtonGroup>
          <Tooltip
            className={styles.tooltip}
            content={sortDirection === SortDirections.ASC ? 'Ascending' : 'Descending'}
            hoverOpenDelay={this.TOOLTIP_DELAY}
            position={Position.TOP}
            usePortal={false}
          >
            <Button
              className={styles.sortDirection}
              icon={directionIconClass}
              onClick={this.onDirectionChange}
            />
          </Tooltip>
          <Popover
            minimal
            content={optionsMenu}
            position={position || Position.BOTTOM_RIGHT}
          >
            <Button
              className={styles.sortByButton}
              text={selectedOption.text}
              rightIcon={IconNames.CARET_DOWN}
            />
          </Popover>
        </ButtonGroup>
      </div>
    );
  }
}
