import classNames from 'classnames';
import * as React from 'react';
import './Link.sass';

interface Props {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string;

  /**
   * Click handler
   */
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;

  /**
   * Visually disable, click is disabled
   */
  disabled?: boolean;

  /**
   * Visually inactive, does not stop the click event
   */
  inactive?: boolean;
}

export const Link: React.FunctionComponent<Props> = (props) => {
  const className = classNames('h-Link', props.className, {
    'h-Link__disabled': props.disabled,
    'h-Link__inactive': props.inactive && !props.disabled
  });

  return (
    <span
      className={className}
      onClick={props.disabled ? undefined : props.onClick}
    >
      {props.children}
    </span>
  );
};
