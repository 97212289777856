// tslint:disable: max-classes-per-file
import * as React from 'react';
import * as d3 from 'd3';
import {BuilderContext} from './BuilderContext';
import {InteractionHandler} from '../types/InteractionHandler';
import { ChartState } from '../ChartState';

export interface WithBuilders {
  builders: Array<ChartComponentBuilder<any>>;
  chartState: ChartState;
}

export abstract class ChartComponentBuilder<TProps> extends React.Component<TProps> {
  public interactive = false;
  public abstract build(chartContext: BuilderContext): ((selection: d3.Selection<SVGSVGElement>) => void) | void;

  protected get injected(): WithBuilders {
    // We know that props should really include all the injected values, so we can just return props.
    return this.props as any as WithBuilders;
  }
  protected get chartState(): ChartState {
    return this.injected.chartState;
  }

  public render() {
    this.injected.builders.push(this);
    return null;
  }

  public getYAxisName(): string {
    // @ts-ignore: does not exist on type
    return this.props['axis'] || 'default';
  }

  public getRequiredMargin() {
    return { left: 0, top: 0, right: 0, bottom: 0 };
  }
}

export abstract class DataSeriesBuilder<TProps, TData extends any[]> extends ChartComponentBuilder<{data: TData} & TProps> {

  public abstract getData(): TData;

  public abstract getRangeMax(): number;

  public get activeIndex(): number | undefined {
    // @ts-ignore: does not exist on type
    return this.props['activeIndex'] || this.chartState.activeIndex;
  }
}

export interface DataSeriesProps<T> {
  data: T[];
  dataKey: keyof T;
  axis?: string;
  color?: string;
  className?: (datum: T, index?: number) => string;
  onHoverEnter?: InteractionHandler<T>;
  onHoverLeave?: InteractionHandler<T>;
  onClick?: InteractionHandler<T>;
  activeIndex?: number;
}
