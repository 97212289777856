import {Intent} from '@blueprintjs/core';
import {Toaster} from '@zaiusinc/hera';

export type ToastIntent = 'info' | 'success' | 'warning' | 'danger';

export const toaster = Toaster.create();

export function showToasts(toasts: Array<{intent: ToastIntent, message: string}>) {
  for (const toast of toasts || []) {
    toaster.show({intent: intentToBPIntent(toast.intent), message: toast.message});
  }
}

function intentToBPIntent(intent: ToastIntent) {
  switch (intent) {
    case 'success':
      return Intent.SUCCESS;
    case 'warning':
      return Intent.WARNING;
    case 'danger':
      return Intent.DANGER;
    default:
      return Intent.NONE;
  }
}
