import {Classes, H1, H2, H3, H4} from '@blueprintjs/core';
import React from 'react';
import {DropDown, DropDownProps, SelectItem} from '../DropDown';
import styles from './InteractiveHeader.sass';
import classNames from 'classnames';

interface InteractiveHeaderProps<T extends SelectItem> {
  /**
   * Props to be passed to the dropdown.
   */
  dropDownProps?: Partial<DropDownProps<T>>;
  /**
   * If the dropdown shoud be loading or not
   */
  loading?: boolean;
  /**
   * If the dropdown should be disabled or not
   */
  disabled?: boolean;
  /**
   * class to be added to the classes of the main container
   */
  className?: string;
  /**
   * Handler called when the dropdown value changes.
   */
  onChange: (value: string) => void;
  /**
   * Text to be placed before the dropdown.
   */
  textBefore?: string | React.ReactElement;
  /**
   * Text to be placed after the dropdown.
   */
  textAfter?: string | React.ReactElement;
  /**
   * The items to go in the dropdown.
   */
  items: T[];
  /**
   * The current value of the dropdown.
   */
  value?: T['value'];
  /**
   * Placeholder to be supplied if a value is not presupplied.
   */
  placeholder?: string;
  /**
   * This is intentionally limited to 1-4, we do not expect this element to be used with H5/H6.
   */
  headerLevel: 1 | 2 | 3 | 4;
}

const Heading: React.FC<{level: 1 | 2 | 3 | 4, className: string}> = ({level, children, className}) => {
  switch (level) {
    case 1:
      return <H1 className={className}>{children}</H1>;
    case 2:
      return <H2 className={className}>{children}</H2>;
    case 3:
      return <H3 className={className}>{children}</H3>;
    case 4:
      return <H4 className={className}>{children}</H4>;
  }
};

export const InteractiveHeader = <T extends SelectItem>({
  onChange,
  className,
  headerLevel,
  dropDownProps,
  loading,
  disabled,
  value,
  items,
  textBefore,
  placeholder,
  textAfter
}: InteractiveHeaderProps<T>) => {
  const handleChange = React.useCallback((item: SelectItem) => onChange(item.value), [onChange]);
  const selectedOption = items.find((item) => item.value === value);
  const heading = (
    <Heading level={headerLevel} className={styles.headerText}>
      {selectedOption?.text ?? placeholder ?? 'Select...'}
    </Heading>
  );
  return (
    <div className={classNames(styles.headerContainer, className)}>
      {textBefore && <Heading className={styles.headerPrefix} level={headerLevel}>{textBefore}</Heading>}
      {items.length === 1 || disabled ? heading : (
        <DropDown
          {...dropDownProps}
          className={classNames(dropDownProps?.className, { [Classes.SKELETON]: loading})}
          buttonProps={{
            minimal: true,
            className: styles.headerDropdownButton,
            text: heading
          }}
          loading={loading}
          items={items}
          onItemSelect={handleChange}
          value={value}
        />
      )}
      {textAfter && <Heading className={styles.headerSuffix} level={headerLevel}>{textAfter}</Heading>}
    </div >
  );
};
