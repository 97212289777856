import {Schema} from '@zaiusinc/app-forms-schema';
import * as React from 'react';
import './Divider.sass';

interface Props {
  schema: Schema.DividerElement;
}

export class Divider extends React.Component<Props> {
  public render() {
    return <hr />;
  }
}
