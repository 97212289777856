import {Button, Tooltip} from '@blueprintjs/core';
import {Schema} from '@zaiusinc/app-forms-schema';
import classNames from 'classnames';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {Errors} from '../layout/Errors';
import styles from './OAuthButton.sass';

interface Props {
  schema: Schema.OAuthButton;
  onClick: (action: string) => Promise<any>;
  disabled?: boolean;
  loading?: boolean;
  assetsBaseUrl?: string;
  errors?: string[];
}

const DEFAULT_ICON_PROPS = {
  iconPosition: 'left',
  icon: 'icon.svg'
};

@observer
export class OAuthButton extends React.Component<Props> {
  @observable private loading = false;

  public render() {
    const {
      errors,
      schema: {key}
    } = this.props;
    return (
      <div key={key} className="bp5-form-group">
        <div>{this.renderButton()}</div>
        <Errors errors={errors} />
      </div>
    );
  }

  public renderButton() {
    const {
      disabled,
      loading,
      schema: {label, style}
    } = this.props;
    return this.renderWithTooltip(
      <Button
        className={classNames(styles.button, {'bp5-skeleton': loading})}
        disabled={disabled}
        intent={style}
        onClick={this.onClick}
        loading={this.loading}
        large
        {...this.iconProps()}
      >
        {label}
      </Button>
    );
  }

  private renderWithTooltip(jsx: JSX.Element) {
    const {help} = this.props.schema;
    if (help) {
      return (
        <Tooltip content={help} hoverOpenDelay={1000}>
          {jsx}
        </Tooltip>
      );
    }
    return jsx;
  }

  private iconProps() {
    const {icon, iconPosition} = {...DEFAULT_ICON_PROPS, ...this.props.schema};
    const {assetsBaseUrl} = this.props;
    const img = <img className={'bp5-icon'} src={`${assetsBaseUrl}/${icon}`} alt={''} />;
    return iconPosition === 'left' ? {icon: img} : {rightIcon: img};
  }

  private onClick = async () => {
    this.loading = true;
    try {
      await this.props.onClick('oauth');
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  };
}
