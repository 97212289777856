import * as React from 'react';
import {Classes, Icon} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import {DragSourceSpec, DragSourceCollector, ConnectDragSource, ConnectDragPreview, DragSource, ConnectDropTarget, DropTarget, DropTargetSpec, DropTargetCollector, DropTargetConnector, DropTargetMonitor} from 'react-dnd';
import {DraggableListItemRenderer} from './DraggableList';
import styles from './DraggableListItem.sass';

export type DragHandleRenderer<TItem> = (item: TItem, index: number) => JSX.Element;

export const LIST_ITEM = 'H_LIST_ITEM';

export interface ListItemWithKey {
  key: string | number;
}

export interface DraggableListTranslation {
  top: number;
  left: number;
}

export interface DraggableListItemProps<TItem extends ListItemWithKey> {
  index: number;
  item: TItem;
  className?: string;
  dragHandleClassName?: string;
  itemRenderer: DraggableListItemRenderer<TItem>;
  onDragStart: (index: number) => void;
  onDragEnd: () => void;
  isOver?: boolean;
  hoveredIndex: number | null;
  onHoverChanged: (index: number | null) => void;
  onDrop: (indexFrom: number, indexTo: number) => void;
  draggedIndex?: number | null;
  dragHandleRenderer?: DragHandleRenderer<TItem>;
  translation?: DraggableListTranslation;
  effectiveIndex: number;
  connectDragSource?: ConnectDragSource;
  connectDragPreview?: ConnectDragPreview;
  connectDropTarget?: ConnectDropTarget;
}

const sourceSpec: DragSourceSpec<DraggableListItemProps<ListItemWithKey>> = {
  beginDrag({index, item, onDragStart}, _monitor) {
    // If we do this without the timeout then react-dnd will freak out when the hidden
    // element receives `pointer-events: none` and will stop the drag immediately
    setTimeout(() => {
      onDragStart(index);
    }, 0);
    return item;
  },
  endDrag({index, onDragEnd, onHoverChanged}, monitor) {
    if (monitor && !monitor.getDropResult()) {
      onDragEnd();
      onHoverChanged(null);
    }
  }
};

const sourceCollect: DragSourceCollector = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
};

const targetSpec: DropTargetSpec<DraggableListItemProps<any>> = {
  hover(props, monitor, component) {
    props.onHoverChanged(props.index);
  }
};

const targetCollect: DropTargetCollector = (
  connect: DropTargetConnector,
  monitor: DropTargetMonitor,
) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: !!monitor.isOver(),
    canDrop: !!monitor.canDrop(),
  };
};

class DraggableListItemComponent<TItem extends ListItemWithKey> extends React.Component<DraggableListItemProps<TItem>> {
  private renderDragHandle(index: number, item: TItem) {
    if (this.props.dragHandleRenderer) {
      return this.props.dragHandleRenderer(item, index);
    }

    const classes: string[] = [Classes.ICON, styles.dragHandle, (this.props.dragHandleClassName || '')];

    // react-dnd doesn't allow non-base elements to be used as drag handles, so I have to wrap it in a span,
    // if the outer element's class is not the BP Icon class then layout gets funky, so here we are
    return (
      <span className={classes.join(' ')}>
        <Icon icon={IconNames.DRAG_HANDLE_VERTICAL} />
      </span>
    );
  }

  private isBeingDragged() {
    return this.props.draggedIndex === this.props.index;
  }

  private getTargetClasses() {
    const classes: string[] = [styles.listItem, 'h-DraggableListItem', (this.props.className || '')];
    if (this.props.draggedIndex != null) {
      classes.push(styles.dragging, 'h-DraggableListItem-Dragging');
    }
    if (this.isBeingDragged()) {
      classes.push(styles.beingDragged, 'h-DraggableListItem-BeingDragged');
    }
    return classes.join(' ');
  }

  private getTargetStyle() {
    if (this.props.draggedIndex != null && this.props.translation) {
      return {
        transform: `translate(${this.props.translation.left}px, ${this.props.translation.top}px)`
      };
    }
    return {};
  }

  public render() {
    const {index, item, itemRenderer} = this.props;
    const dragHandle = this.renderDragHandle(index, item);
    const content = itemRenderer(
      item,
      index,
      this.props.connectDragSource!(dragHandle)
    );
    return (
      <div
        style={this.getTargetStyle()}
        className={this.getTargetClasses()}
      >
        {this.props.connectDropTarget!(this.props.connectDragPreview!(<div>{content}</div>))}
      </div>
    );
  }
}

const dragSource = DragSource(
  LIST_ITEM,
  sourceSpec,
  sourceCollect
)(DraggableListItemComponent);

export const DraggableListItem = DropTarget(
  LIST_ITEM,
  targetSpec,
  targetCollect
)(dragSource);
