import {Schema} from '@zaiusinc/app-forms-schema';
import {DataStore, FeatureFlagChecker} from '../stores/DataStore';
import {FormValidator} from './FormValidator';

/**
 * The result of a validateForm.  If the validation failed errors will be populated
 * for all sections in the form.
 */
export interface FormValidationResult {
  valid: boolean;
  errors?: Schema.FormErrors;
}

/**
 * Validates a form schema.
 *
 * @param schema Complete form schema
 * @param data form data for validating
 * @param hasFeatureFlag a callback to check if the current user has a feature flag
 */
export function validateForm(
  schema: Schema.Form,
  data: Schema.FormData,
  hasFeatureFlag: FeatureFlagChecker
): FormValidationResult {
  const validator = new FormValidator(new DataStore(data, schema, hasFeatureFlag));
  return validator.valid() ? {valid: true} : {valid: false, errors: validator.errors};
}
