import * as React from 'react';
import {Tooltip, TooltipProps} from '@blueprintjs/core';
import styles from './OverflowTooltip.sass';

interface Props extends TooltipProps {
  compact?: boolean;
  content: string | JSX.Element;
  hoverCloseDelay?: number;
  hoverOpenDelay?: number;
  intent?: 'primary' | 'success' | 'warning' | 'danger';
  interactionKind?: 'hover' | 'hover-target';
  transitionDuration?: number;
}

export const OverflowTooltip: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const open = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    const element = event.currentTarget.firstElementChild ? event.currentTarget.firstElementChild : event.currentTarget;

    if (element.clientWidth < element.scrollWidth) {
      setIsOpen(true);
    }
  }, []);

  const close = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    setIsOpen(false);
  }, []);

  return (
    <Tooltip className={styles.container} isOpen={isOpen} {...props}>
      <span className={styles.childrenWrapper} onMouseEnter={open} onMouseLeave={close}>
        {props.children}
      </span>
    </Tooltip>
  );
};
