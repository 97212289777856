import * as d3 from 'd3';
import {inject} from 'mobx-react';
import {ChartArea, InteractionArguments} from './types';
import {DataSeriesBuilder} from './types/DataSeriesBuilder';
import {BuilderContext} from './types/BuilderContext';

type AxisTickInteractionHandler<T> =
  (args: InteractionArguments<T>) => void;
type TickFormatter<T> = (datum: T, index: number) => string;

interface XAxisProps<T> {
  onHoverEnterHandler?: AxisTickInteractionHandler<T>;
  onHoverLeaveHandler?: AxisTickInteractionHandler<T>;
  onClick?: AxisTickInteractionHandler<T>;
  label?: JSX.Element;
  hideLabels?: boolean;
  height?: number;
  dataKey?: keyof T;
}

@inject('builders', 'chartState')
export class XAxis<T extends { label: string }> extends DataSeriesBuilder<XAxisProps<T>, T[]> {

  public static defaultProps = {
    height: 50
  };

  public getData() {
    return this.props.data;
  }

  public getRangeMax() {
    return 0;
  }

  public getRequiredMargin() {
    return { left: 40, top: 0, right: 40, bottom: this.props.height! };
  }

  private formatLabel(chartArea: ChartArea, data: T[], index: number) {
    const {left, right} = chartArea;
    const width = (right - left);
    const maxLabels = width / 70;
    const factor = Math.ceil(data.length / maxLabels);
    const key = this.props.dataKey || 'label';
    return index % factor === 0 ? String(data[index][key]) : '';
  }

  private hoverEnter(element: Element, datum: T, index: number) {
    d3.selectAll(`.datum-${index}`).each(function(this: Element) {
      this.classList.add('x-axis-hovered');
    });
    this.props.onHoverEnterHandler && this.props.onHoverEnterHandler({element, datum, index});
  }

  private hoverLeave(element: Element, datum: T, index: number) {
    d3.selectAll(`.datum-${index}`).each(function(this: Element) {
      this.classList.remove('x-axis-hovered');
    });
    this.props.onHoverLeaveHandler && this.props.onHoverLeaveHandler({element, datum, index});
  }

  private addTickInteractionHandling = (selection: d3.Selection<any>) => {
    const axisThis = this;
    selection
      .style('cursor', () => this.props.onClick ? 'pointer' : 'initial')
      .on('click', function(this: Element, bar, index) {
        axisThis.props.onClick && axisThis.props.onClick({element: this, datum: bar, index});
      })
      .on('mouseenter', function(this: Element, datum, index) {
        axisThis.hoverEnter.call(axisThis, this, datum, index);
      })
      .on('mouseleave', function(this: Element, datum, index) {
        axisThis.hoverLeave.call(axisThis, this, datum, index);
      });
  }

  public build(context: BuilderContext) {
    return (selection: d3.Selection<SVGSVGElement>) => {
      const {x, chartArea} = context;
      const axisThis = this;
      const xAxis = d3.svg.axis().scale(x).orient('bottom')
        .tickSize(0)
        .innerTickSize(10)
        .outerTickSize(0)
        .tickFormat((t, index): string => {
          return this.formatLabel(chartArea, this.props.data, index);
        });

      const axisElement = selection
        .selectAll('g.x-axis')
        .data([this.props.data]);

      axisElement.enter()
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0, ${chartArea.bottom})`);

      const axis = axisElement
        .data(this.props.data)
        .call(xAxis);
      axis.selectAll('.tick line')
        .style('stroke', '#d8d8d8');
      if (this.props.hideLabels) {
        axisElement.selectAll('.tick text').remove();
      } else {
        axisElement.selectAll('.tick text')
          .data(this.props.data)
          .attr('style', 'text-anchor: end')
          .style('font-size', '13px')
          .style('transform', 'rotate(-45deg)')
          .call(this.addTickInteractionHandling);
      }
      let maxTickHeight = 0;
      axisElement.selectAll('.tick').each(function(this: Element) {
        const {height} = this.getBoundingClientRect();
        maxTickHeight = height > maxTickHeight ? height : maxTickHeight;
      });

      const outlineSelection = axisElement.selectAll('.x-axis-line').data([0]);
      outlineSelection.enter()
        .append('line')
        .attr('class', 'x-axis-line');
      outlineSelection.attr('y1', 0)
        .attr('y2', 0)
        .attr('x1', chartArea.left)
        .attr('x2', chartArea.right)
        .attr('stroke', 'rgb(216, 216, 216)');

      axisElement.selectAll('.tick')
        .data(this.props.data)
        .append('rect')
        .attr('width', () => x.rangeBand() || 0 )
        .attr('x', `-${x.rangeBand() / 2}`)
        .attr('height', () => Math.max(maxTickHeight, 20))
        .attr('fill-opacity', 0)
        .call(this.addTickInteractionHandling);
    };
  }
}
