import {AppForm} from '@zaiusinc/app-forms';
import {Schema} from '@zaiusinc/app-forms-schema';
import {toJS} from 'mobx';
import {useObserver} from 'mobx-react-lite';
import * as queryString from 'querystring';
import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {fetchRemoteData} from '../../lib/fetchRemoteData';
import {filterZaiusOnlySections} from '../../lib/filterZaiusOnlySections';
import {addToQuery} from '../../lib/history';
import {AppDetailStore} from '../../stores/AppDetailStore';
import {directoryStore} from '../../stores/DirectoryStore';
import {inIframe} from '../../lib/inIframe';
import {HostPageMessenger} from '../../lib/HostPageMessenger';

const ASSETS_BASE_URI: string = process.env.REACT_APP_ASSETS_BASE_URI || (() => {
  const url = new URL(window.location.href);
  const stem = url.host.split('.').slice(1);
  if (stem[0] === 'staging') {
    // Legacy quirks.
    return 'https://zaius-directory-staging.s3.amazonaws.com';
  }
  return 'https://apps.global.ocp.optimizely.com';
})();

interface Props {
  detailStore: AppDetailStore;
}

export const AppSettings = ({detailStore}: Props) => {
  const history = useHistory();
  const {search} = useLocation();
  const params = queryString.parse(search.startsWith('?') ? search.substring(1) : search);
  const openSection = (params['section'] as string) || undefined;

  return useObserver(() => {
    if (!detailStore.loading && params.errors) {
      detailStore.processErrors(JSON.parse(atob(params.errors as string)));
      delete params.errors;
      history.replace({...history.location, search: queryString.encode(params)});
    }

    if ((detailStore.settingsFormSchema?.sections?.length || 0) === 0) {
      return <div />;
    }

    const settingsFormSchema = filterZaiusOnlySections(toJS(detailStore.settingsFormSchema!));
    const {settingsFormData, settingsFormErrors, loading, appId, version, installId} = detailStore;

    return (
      <AppForm
        schema={settingsFormSchema}
        loading={loading}
        data={toJS(settingsFormData) || {}}
        options={{assetsBaseUrl: `${ASSETS_BASE_URI}/${appId}/${version}/assets`}}
        openSection={openSection || settingsFormData?.$formState?.defaultSection as string}
        hasFeatureFlag={directoryStore.hasFeatureFlag}
        errors={toJS<Schema.FormErrors>(settingsFormErrors as any)}
        onFetchData={(section, field, source, data) => fetchRemoteData(section, field, source, data, installId!)}
        onAction={(section, action, data) => detailStore.handleAction(section, action, data).then((resp) => {
          if (resp.redirectMode === 'url') {
            if (inIframe()) {
              HostPageMessenger.send('setWindowLocation', {url: resp.redirectTarget});
            } else {
              window.location.href = resp.redirectTarget!;
            }
          } else if (resp.redirectMode === 'settings') {
            addToQuery(history, {section: resp.redirectTarget ?? null});
          }
          return resp.success;
        })}
        onSectionClick={(section) => addToQuery(history, {section: section.key})}
      />
    );
  });
};
