import {Schema, SectionType} from '@zaiusinc/app-forms-schema';
import {cloneDeep} from 'lodash';
import {action, computed, isObservableArray, observable} from 'mobx';
import {observer} from 'mobx-react';
import Mustache from 'mustache';
import * as React from 'react';
import {Section} from './elements/Section';
import {PredicateEvaluator} from './lib/PredicateEvaluator';
import {SectionValidator} from './lib/SectionValidator';
import {DataStore, FeatureFlagChecker, FormChangeHandler} from './stores/DataStore';

export interface FormOptions {
  /**
   * When true, the form sections are controlled, meaning: all sections can be opened/collapsed independently
   * (controlled from outside)
   * @default false
   */
  controlled: boolean;

  /**
   * False to not allow buttons in the form sections, including the dynamically added Save button.
   * Used for campaign forms where the data is managed externally from ZIP.
   * @default true
   */
  allowButtons: boolean;

  /**
   * True (default) to use minimal accordions for sections, false if minimal is not desired
   * @default true
   */
  minimalAccordions: boolean;

  /**
   * The base url for assets for components that need access to icons.
   */
  assetsBaseUrl?: string;

  /**
   * True (default) to show error badges on sections.
   * @default true
   */
  showErrorBadge: boolean;
}

interface Props {
  /**
   * The schema of the form. See https://docs.developers.zaius.com/build-apps/forms
   */
  schema: Schema.Form;

  /**
   * The data to populate the form with
   */
  data: Schema.FormData;

  /**
   * A callback to determine if a feature flag is enabled or not
   */
  hasFeatureFlag: FeatureFlagChecker;

  /**
   * If the form should show a loading state
   */
  loading?: boolean;

  /**
   * Errors to display inline
   */
  errors?: Schema.FormErrors;

  /**
   * The key(s) of the sections to open if you are controlling the form
   */
  openSection?: string | string[];

  /**
   * Callback when the user clicks to open another section.
   * If section is controlled, you are responsible for updating the activeSection prop.
   */
  onSectionClick?: (section: Schema.Section) => void;

  /**
   * A handler responsible for performing the action (e.g., save).
   * Resolve the promise AFTER any updates to the form data have been made.
   * Must be implemented for Settings forms. Not used for Channel forms.
   * Default values from the form will be added to the data for any fields not edited.
   */
  onAction?: (section: string, action: string, data: Schema.FormSectionData) => Promise<boolean>;

  /**
   * A handler that can fetch remote data, e.g., to populate a select dropdown
   */
  onFetchData: (
    section: string,
    field: string,
    source: Schema.DataSource,
    data: Schema.FormSectionData
  ) => Promise<Schema.SelectOption[]>;

  /**
   * An optional callback to watch for changes in the form data.
   * Called for each individual field changed by the user in real time.
   */
  onFormDataChange?: FormChangeHandler;

  /**
   * Options to control what mode the form rendering is in, e.g., campain settings/content vs app settings.
   */
  options?: Partial<FormOptions>;
}

/**
 * Render an app form given a form schema and the stored data
 */
@observer
export class AppForm extends React.Component<Props> {
  @observable private preferredSection?: string;
  @observable private errors?: Schema.FormErrors;
  private dataStore: DataStore;

  constructor(props: Props) {
    super(props);
    this.errors = props.errors;
    this.dataStore = new DataStore(
      props.data,
      props.schema,
      props.hasFeatureFlag,
      props.onFormDataChange,
      props.options
    );
    this.dataStore.loading = !!props.loading;
  }

  @action('AppForm#componentWillReceiveProps')
  public componentWillReceiveProps(nextProps: Props) {
    this.errors = nextProps.errors;
    this.dataStore.updateSchema(nextProps.schema);
    this.dataStore.setSourceData(nextProps.data);
    this.dataStore.setOptions(nextProps.options);
    this.dataStore.loading = !!nextProps.loading;
  }

  public componentWillUnmount() {
    this.dataStore.destroy();
  }

  public render() {
    return (
      <div>
        {this.renderedSchema.sections.filter(this.sectionIsVisible).map((section) => (
          section.type === SectionType.Zed ? null : (
            <Section
              key={section.key}
              data={this.dataStore}
              errors={(this.errors && this.errors.sections[section.key]) || {}}
              section={section}
              isOpen={this.isSectionOpen(section.key)}
              onClick={this.onSectionClick}
              onFetchRemoteData={this.props.onFetchData}
              onAction={this.onAction}
            />
          )
        ))}
      </div>
    );
  }

  private sectionIsVisible = (section: Schema.Section | Schema.ZedSection) => {
    return new PredicateEvaluator(section.key, this.dataStore).evaluate(section.visible, true);
  };

  private sectionIsEnabled = (section: Schema.Section | Schema.ZedSection) => {
    return !new PredicateEvaluator(section.key, this.dataStore).evaluate(section.disabled, false);
  };

  private isSectionOpen(key: string) {
    const {controlled} = this.dataStore.options;
    const {openSection} = this.props;
    const openSections =
      Array.isArray(openSection) || isObservableArray(openSection) ? openSection : openSection ? [openSection] : [];
    if (openSections.length > 0 || controlled) {
      return openSections.includes(key);
    } else {
      if (this.preferredSection) {
        // the form is not controlled and the user opened a section
        return this.preferredSection === key;
      } else {
        // only the first visible & enabled section should be open
        for (const section of this.props.schema.sections) {
          if (this.sectionIsEnabled(section) && this.sectionIsVisible(section)) {
            return section.key === key;
          }
        }
      }
    }
    return false;
  }

  private onSectionClick = (s: Schema.Section) => {
    this.preferredSection = s.key;
    if (this.props.onSectionClick) {
      this.props.onSectionClick(s);
    }
  };

  private onAction = async (section: Schema.Section, actionName: string) => {
    const validator = new SectionValidator(section, this.dataStore);
    const violations = {sections: this.errors?.sections || {}};
    violations.sections[section.key] = {};
    if (!validator.valid()) {
      violations.sections[section.key] = validator.errors;
      this.errors = violations;
      return false;
    }

    this.errors = violations;
    if (this.props.onAction) {
      return await this.props.onAction(section.key, actionName ?? 'save', this.dataStore.getSection(section));
    } else {
      return false;
    }
  };

  @computed
  private get renderedSchema(): Schema.Form {
    const data = this.dataStore.getAllData();
    const schema = cloneDeep(this.props.schema);
    schema.sections.forEach((section) => {
      section.label = Mustache.render(section.label || '', data);
      section.elements?.forEach((element: any) => {
        for (const prop of ['label', 'help', 'hint', 'text', 'href']) {
          if (element[prop]) {
            element[prop] = Mustache.render(element[prop], data);
          }
        }
      });
    });
    return schema;
  }
}
