import {Button, Classes, ButtonProps, PopoverProps, Position, ResizeSensor, Spinner, Tooltip} from '@blueprintjs/core';
import {IconName, IconNames} from '@blueprintjs/icons';
import {SelectProps, Select} from '@blueprintjs/select';
import {ItemListRendererProps} from '@blueprintjs/select/src/common/itemListRenderer';
import classNames from 'classnames';
import omit from 'lodash/omit';
import {action, computed, observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {CSSProperties} from 'react';
import {itemPredicate, itemListRenderer, selectItemRenderer} from '../../helpers/selectRenderers';
import styles from './DropDown.sass';

export enum SelectItemTypes {
  Sticky = 'Sticky',
  Creatable = 'Creatable'
}

export interface SelectItem {
  text: string;
  value: any;
  icon?: IconName | JSX.Element;
  disabled?: boolean;
  disabledTooltip?: string;
  type?: string;
}

export type DropDownProps<T extends SelectItem = SelectItem> = Props<T>;

interface Props<T extends SelectItem = SelectItem> extends Partial<SelectProps<T>> {
  items: T[];
  onItemSelect: (item: T, event?: React.SyntheticEvent<HTMLElement>) => void;

  /**
   * Properties to be passed to the button used by the select (e.g., minimal)
   */
  buttonProps?: ButtonProps;

  /**
   * Currently selected value, should match the value of one of the items provided
   */
  value?: T | T['value'];

  /**
   * true to attempt to fill the container (UX requirements also limit max size)
   * false to size to selected content
   * @default true
   */
  fill?: boolean;

  /**
   * true to disable and show loading spinner
   */
  loading?: boolean;

  /**
   * Specific width for special cases
   */
  width?: number;

  /**
   * List of items (usually one) that should be fixed to the bottom of the drop down list.
   * These items do not scroll and are not filtered by search input
   */
  stickyItems?: T[];

  /**
   * JSX.Element that will be placed at the bottom of the drop down
   */
  bottomSection?: JSX.Element;

  /**
   * Whether or not to use virtual rendering for the list items.
   * If true, we will only render visible items and lazily render as the user scrolls.
   * If false, or not provided, we will eagerly render all items (existing behavior).
   * @default false
   */
  virtualize?: boolean;

  /**
   * When using Lazy item rendering (virtualize == true), we need to know the row height.
   * Defaults to our standard list item height of 30. When not using virtual rendering, this value is ignored
   * @default 30
   */
  itemHeight?: number;
}

@observer
export class DropDown<T extends SelectItem = SelectItem> extends React.Component<Props<T>> {
  @observable private isOpen: boolean = false;
  @observable private valueIsOverflown: boolean = false;
  @observable private selectWidth?: number;
  @observable private activeItem?: SelectItem | null;
  private buttonRef?: HTMLButtonElement;
  private popoverRef?: HTMLDivElement;
  private selectRef?: Select<T> | null;
  public static defaultProps = {
    fill: true,
    itemHeight: 30
  };

  constructor(props: Props<T>) {
    super(props);
  }

  private itemFromValue(value?: string | number | boolean | null | object | T) {
    if (isSelectItem(value)) {
      return value;
    }
    const result = this.props.items.find((v) => v.value === value);
    if (!result && this.props.stickyItems) {
      return this.props.stickyItems.find((v) => v.value === value);
    }
    return result;
  }

  @computed
  private get selectedItem() {
    return this.itemFromValue(this.props.value);
  }

  @action('<DropDown>#onItemSelect')
  private onItemSelect = (item: T, event?: React.SyntheticEvent<HTMLElement>) => {
    this.isOpen = false;
    this.props.onItemSelect(item, event);
  }

  @action('<DropDown>#onActiveItemChange')
  private onActiveItemChange = (item: T | null, isCreateNewItem: boolean) => {
    this.activeItem = item;
    if (this.props.onActiveItemChange) {
      this.props.onActiveItemChange(item, isCreateNewItem);
    }
  }

  @action('<DropDown>#popoverOpening')
  private popoverOpening = (node: HTMLElement) => {
    this.updateMenuMinWidth();
    const {popoverProps} = this.props;
    if (popoverProps && popoverProps.onOpening) {
      popoverProps.onOpening(node);
    }

    const item = this.selectedItem;
    if (item != null) {
      this.activeItem = item;
    }
  }

  @action('<DropDown>#popoverClosing')
  private popoverClosing = (node: HTMLElement) => {
    const {popoverProps} = this.props;
    if (popoverProps && popoverProps.onClosing) {
      popoverProps.onClosing(node);
    }
  }

  @action('<DropDown>#checkForOverflow')
  private checkForOverflow = () => {
    if (this.buttonRef) {
      this.selectWidth = this.buttonRef.clientWidth;
      const textElement = this.buttonRef.querySelector('.bp5-button-text');
      if (textElement) {
        this.valueIsOverflown = textElement.scrollWidth > textElement.clientWidth;
      }
      this.updateMenuMinWidth();
    }
  }

  private updateMenuMinWidth = () => {
    if (this.popoverRef && this.selectWidth) {
      const menus: NodeListOf<HTMLDivElement> = this.popoverRef.querySelectorAll('.bp5-menu');
      menus.forEach((menu) => menu.style.minWidth = `${this.selectWidth}px`);
    }
  }

  private onPopoverRef = (ref: HTMLElement | null) => {
    this.popoverRef = ref as HTMLDivElement;
  }

  private itemListRenderer = (props: ItemListRendererProps<T>) => {
    const {loading, bottomSection, virtualize, itemHeight} = this.props;
    return itemListRenderer<T>({
      itemListRendererProps: props,
      loading,
      bottomSection,
      virtualize,
      itemHeight,
      stickyItems: this.props.stickyItems,
      selectRef: this.selectRef,
      onItemSelect: this.onItemSelect
    });
  }

  @action('<DropDown>#componentDidUpdate')
  public componentDidUpdate(prevProps: Readonly<Props<T>>, prevState: Readonly<{}>, prevContext: any): void {
    setTimeout(this.checkForOverflow, 0);
  }

  public render() {
    let {children} = this.props;
    const {initialContent, value, disabled, className, loading, fill, buttonProps, width} = this.props;

    let selectedItem: SelectItem | undefined;
    if (value !== undefined) {
      selectedItem = this.selectedItem;
    }

    let widthOverride: CSSProperties | undefined;
    if (width != null) {
      widthOverride = {
        width,
        minWidth: width,
        maxWidth: width
      };
    }

    if (!children) {
      const text = selectedItem && selectedItem.text;
      const textContent = text || <span className={Classes.TEXT_DISABLED}>{initialContent || 'Select...'}</span>;
      const icon = loading ? <Spinner size={16}/> : (this.isOpen ? IconNames.CARET_UP : IconNames.CARET_DOWN);
      children = (
        // @ts-ignore: ResizeSensor not JSX component
        <ResizeSensor observeParents onResize={this.checkForOverflow}>
          <Tooltip
            content={textContent}
            hoverOpenDelay={1000}
            position={Position.TOP}
            isOpen={!this.valueIsOverflown ? false : undefined}
            disabled={!text || !this.valueIsOverflown}
          >
            <Button
              className={classNames(styles.selectButton, {[Classes.FILL]: fill})}
              style={widthOverride}
              disabled={disabled}
              text={textContent}
              rightIcon={icon}
              icon={selectedItem ? selectedItem.icon : undefined}
              {...buttonProps}
            />
          </Tooltip>
        </ResizeSensor>
      );
    }

    const popoverProps: PopoverProps = {
      minimal: true,
      modifiers: {
        preventOverflow: {enabled: true}
      },
      canEscapeKeyClose: true,
      onOpening: this.popoverOpening,
      onClosing: this.popoverClosing,
      popoverClassName: classNames('h-DropDown-popover', className && `${className}-popover`),
      popoverRef: this.onPopoverRef,
      ...omit(this.props.popoverProps, ['onOpening', 'onClosing'])
    };

    return (
      <div
        className={classNames('h-DropDown', styles.container, this.props.className, {[styles.inline]: !fill})}
        style={widthOverride}
      >
        <Select
          filterable={false}
          itemPredicate={itemPredicate}
          itemRenderer={selectItemRenderer}
          itemListRenderer={this.itemListRenderer}
          resetOnClose
          activeItem={this.activeItem ? this.props.items.find((i) => this.activeItem!.value === i.value) : undefined}
          {...omit(this.props, ['initialContent', 'value'])}
          popoverProps={popoverProps}
          onItemSelect={this.onItemSelect}
          onActiveItemChange={this.onActiveItemChange}
          ref={(ref) => this.selectRef = ref}
        >
          {children}
        </Select>
      </div>
    );
  }
}

export const isSelectItem = (item: unknown): item is SelectItem => (
  (item !== undefined && item !== null) && (typeof item === 'object') && (typeof (item as any).text) === 'string'
);
