import * as d3 from 'd3';
import {inject} from 'mobx-react';
import {ChartComponentBuilder} from './types/DataSeriesBuilder';
import {BuilderContext} from './types/BuilderContext';

export interface YAxisProps {
  name?: string;
  color?: string;
  className?: string;
  /**
   * Use to override the range for the y-axis
   */
  manualRange?: [number, number];
  /**
   * Use to set a fallback range for the y-axis when the domain has no range.
   */
  fallbackRange?: [number, number];
  /**
   * Use to add a label above the y-axis
   */
  label?: string;
  /**
   * Use to display the y-xis on right side of the chart
   */
  reversed?: boolean;
  width?: number;
  formatTick?: (t: number, format: d3.FormatPrefix) => string;
}

@inject('builders', 'chartState')
export class YAxis extends ChartComponentBuilder<YAxisProps> {
  private selection?: d3.Selection<any>;

  public static defaultProps = {
    width: 50
  };

  public getRequiredMargin() {
    const left = this.props.reversed ? 0 : this.props.width!;
    const right = this.props.reversed ? this.props.width! : 0;
    const top = this.props.label ? 50 : 10;
    const bottom = 10;
    return { left, top, right, bottom};
  }

  private axisClassNames() {
    const { className, color= 'grey-900' } = this.props;
    return `y-axis y-axis-${color} ${className}`;
  }

  public getYAxisName(): string {
    return this.props['name'] || 'default';
  }

  public build(context: BuilderContext) {
    return (selection: d3.Selection<SVGSVGElement>) => {
      const { y, yScales, numberOfTicks, chartArea: { left, right, top, bottom } } = context;
      const { reversed, manualRange, fallbackRange, name, label, formatTick } = this.props;
      let axisScale: d3.scale.Linear<number, number> = y;
      let mainScale = yScales.default;

      if (manualRange) {
        mainScale = d3.scale.linear().domain(manualRange).range(y.range()).nice(numberOfTicks);
        axisScale = mainScale;
      } else if (mainScale.domain()[1] <= 0 && fallbackRange) {
        mainScale = d3.scale.linear().domain(fallbackRange).range(y.range()).nice(numberOfTicks);
        axisScale = mainScale;
      }

      // We always use the default scale to calculate tick positions, so they always line up
      // as a result we need a scale which can convert from the main scale to the alternate scale for the tick text.
      const conversionScale = name !== 'default'
        ? d3.scale.linear().domain(mainScale.domain()).range(axisScale.domain())
        : mainScale;

      const baseYAxis = d3.svg.axis()
        // Need to use the main scale no matter what for correct tick positioning.
        .scale(mainScale)
        .orient(reversed ? 'right' : 'left' )
        .ticks(numberOfTicks)
        .tickSize(0)
        .tickFormat((t): string => {
          // Since we used the main scale, we need to convert back from the main scale to the alternate scale.
          if (name !== 'default') {
            t = Number(conversionScale(t).toPrecision(2));
          }
          const format = d3.formatPrefix(t, 1);
          if (formatTick) {
            return formatTick(t, format);
          }
          return `${format.scale(t)} ${format.symbol}`;
      });

      this.selection = this.selection || selection
        .append('g')
        .attr('class', this.axisClassNames());

      this.selection
        .call(baseYAxis)
        .attr('transform', `translate(${reversed ? right + 5 : left - 5}, 0)`)
        .selectAll('line')
        .attr('class', 'tick-line')
        .style('stroke', '#eee')
        .attr('x1', 0)
        .attr('x2', reversed ? - (right - left) : (right - left));

      const outlineSelection = selection.selectAll(reversed ? '.y-axis-line-right' : '.y-axis-line').data([true]);
      outlineSelection.enter()
        .append('line')
        .attr('class', reversed ? 'y-axis-line-right' : 'y-axis-line');

      outlineSelection
        .attr('y1', top)
        .attr('y2', bottom)
        .attr('x1', reversed ? right : left)
        .attr('x2', reversed ? right : left)
        .attr('stroke', 'rgb(216, 216, 216)');

      // text label for the y axis
      if (label) {
        this.selection
          .selectAll('.axis-label')
          .data([label])
          .enter()
          .append('text')
          .attr('class', 'axis-label y-axis-label')
          .attr('transform', `translate(0, ${top / 2})`)
          .attr('dy', '.32em')
          .attr('y', 0)
          .attr('x', reversed ? 28 : -28)
          .style('text-anchor', reversed ? 'end' : 'start')
          .text(label.toUpperCase());
      }
    };
  }
}
