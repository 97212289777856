import {Icon, MaybeElement, NonIdealState} from '@blueprintjs/core';
import {IconName, IconNames} from '@blueprintjs/icons';
import * as React from 'react';

interface NoSearchResultProps {
  icon?: IconName | MaybeElement;
  title?: React.ReactNode;
  description?: React.ReactChild;
  action?: JSX.Element;
  className?: string;
}

export const NoSearchResult: React.FC<NoSearchResultProps> = ({className, icon, title, description, action}) => {
  const iconElement = typeof icon === 'string' ? <Icon iconSize={40} icon={icon} /> : icon;
  return (
    <NonIdealState
      className={className}
      icon={iconElement ?? <Icon iconSize={40} icon={IconNames.SEARCH} />}
      title={title ?? 'No results found.'}
      description={description ?? 'Check for spelling errors, or try broadening your search.'}
      action={action}
    />);
};
