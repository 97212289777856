import * as React from 'react';
import {Errors} from './Errors';

export class ButtonRenderer {
  private buttons: JSX.Element[] = [];
  private errors: string[] = [];

  public get hasButtons() {
    return this.buttons.length > 0;
  }

  public addButton(button: JSX.Element, errors?: string[]) {
    this.buttons.push(button);
    if (errors) {
      this.errors.push(...errors);
    }
  }

  public flush(key: string) {
    const jsx = (
      <div key={key} className="bp5-form-group">
        <div style={{display: 'flex'}}>{this.buttons}</div>
        <Errors errors={this.errors} />
      </div>
    );

    this.buttons = [];
    this.errors = [];
    return jsx;
  }
}
