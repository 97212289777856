import {FormGroup, FormGroupProps} from '@blueprintjs/core';
import classNames from 'classnames';
import omit from 'lodash/omit';
import * as React from 'react';
import {ReactNode} from 'react';
import styles from './Label.sass';

const canFocus = (element: HTMLInputElement) => {
  return !element.disabled && element.style.display !== 'none';
};

interface Props extends FormGroupProps {
  label: React.ReactNode;

  /**
   * Use the zaius muted color for the label (if not disabled)
   * @default false
   */
  muted?: boolean;

  /**
   * Disable onClick interactions.
   * @default false
   */
  nonInteractive?: boolean;

  /**
   * Required. To get the spacing and interactions right, the target of the label should be a child of the label.
   */
  children: ReactNode;
}

export class Label extends React.Component<Props> {
  private formGroupRef: HTMLElement | null = null;
  private onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const {nonInteractive, labelFor} = this.props;
    if (!(nonInteractive || labelFor) && this.formGroupRef) {
      !this.alreadyFocused(this.formGroupRef) &&
      !this.focusInput(this.formGroupRef) &&
      this.openDropDown(this.formGroupRef);
    }
  }

  private alreadyFocused = (ref: HTMLElement): boolean => {
    return ref.contains(document.activeElement);
  }

  private focusInput = (ref: HTMLElement): boolean => {
    const elements: HTMLInputElement[] = ref.querySelectorAll('input,textarea') as any;
    for (const inputEl of elements) {
      if (canFocus(inputEl)) {
        inputEl.focus();
        return true;
      }
    }
    return false;
  }

  private openDropDown = (ref: HTMLElement) => {
    const buttonElement: HTMLButtonElement = ref.querySelector('.h-DropDown button') as HTMLButtonElement;
    if (buttonElement) {
      buttonElement.click();
    }
  }

  public render() {
    const {className, muted} = this.props;
    return (
      <div
        className={classNames('h-Label', className, styles.label, {[styles.muted]: muted})}
        ref={(e) => this.formGroupRef = e}
        onClick={this.onClick}
      >
        <FormGroup
          {...omit(this.props, 'muted', 'className')}
        />
      </div>
    );
  }
}
