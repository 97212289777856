import {PopoverProps, Menu} from '@blueprintjs/core';
import {SuggestProps, Suggest} from '@blueprintjs/select';
import {ItemListRendererProps} from '@blueprintjs/select/src/common/itemListRenderer';
import classNames from 'classnames';
import omit from 'lodash/omit';
import {computed, observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {findDOMNode} from 'react-dom';
import {
  inputValueRenderer, itemPredicate, itemRenderer, LoadingState, NoResultsState,
  renderBottomSection
} from '../../helpers/selectRenderers';
import {SelectItem} from '../DropDown';
import styles from './AutoSuggest.sass';

export interface AutoSuggestProps<T extends SelectItem = SelectItem> extends Omit<SuggestProps<T>, 'inputValueRenderer' | 'itemRenderer'> {
  /**
   * True to disable and show loading spinner
   */
  loading?: boolean;

  /**
   * Property for controlled use of suggestion popover.
   * Use with onInteraction to get a better control over different interactions with popover
   * Also alias for popoverProps.isOpen
   */
  isSuggestionOpen?: boolean;

  /**
   * Custom renderer to transform an item into a string for the input value.
   */
  inputValueRenderer?: SuggestProps<T>['inputValueRenderer'];

  /**
   * Custom renderer for an item in the dropdown list. Receives a boolean indicating whether
   * this item is active (selected by keyboard arrows) and an `onClick` event handler that
   * should be attached to the returned element.
   */
  itemRenderer?: SuggestProps<T>['itemRenderer'];

  /**
   * Property for controlled use of suggestion popover.
   * Listens to different popover close / open events and allow to change your state appropriately
   * Also alias for popoverProps.onInteraction
   */
  onInteraction?: NonNullable<SuggestProps<T>['popoverProps']>['onInteraction'];

  /**
   * JSX.Element that will be placed at the bottom of the drop down
   */
  bottomSection?: JSX.Element | undefined | false | null;

  /**
   * Property that controls suggestion menu popover width
   * If not set, popover menu width will default to input width with padding included
   */
  menuWidth?: number;
}

@observer
export class AutoSuggest<T extends SelectItem = SelectItem> extends React.Component<AutoSuggestProps<T>> {
  private readonly PORTAL_PADDING = 10;

  @observable
  private isOpenOverwrite: false | undefined;

  // Blueprint default value is 400px
  @observable
  private popoverMenuWidth = 400;

  public componentDidUpdate(): void {
    this.updateMenuWidth();
  }

  public componentDidMount(): void {
    this.updateMenuWidth();
  }

  constructor(props: AutoSuggestProps<T>) {
    super(props);
    this.isOpenOverwrite = false;
    setTimeout(() => this.isOpenOverwrite = undefined);
  }

  private updateMenuWidth = () => {
    this.popoverMenuWidth = this.props.menuWidth || this.getInputWidth() || this.popoverMenuWidth;
  }

  private getInputWidth() {
    const componentNode = findDOMNode(this) as Element;
    const inputNode = componentNode.querySelector('input');

    if (inputNode) {
      return inputNode.clientWidth - this.PORTAL_PADDING;
    }
  }

  private handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      if (this.isSuggestionOpen && this.props.onInteraction) {
        this.props.onInteraction(false, event);
      }
    }, 200);

    if (this.props.inputProps && this.props.inputProps.onBlur) {
      this.props.inputProps.onBlur(event);
    }
  }

  @computed
  private get inputValueRenderer() {
    return this.props.inputValueRenderer || inputValueRenderer;
  }

  @computed
  private get itemRenderer() {
    return this.props.itemRenderer || itemRenderer;
  }

  @computed
  private get widthStyles() {
    return {maxWidth: this.popoverMenuWidth, width: this.popoverMenuWidth};
  }

  private itemListRenderer = (props: ItemListRendererProps<T>) => {
    const {loading, bottomSection} = this.props;
    const items = props.filteredItems.map(props.renderItem).filter((item) => item != null);

    const itemsMenu = (
      <Menu
        ulRef={props.itemsParentRef}
        style={this.widthStyles}
      >
        {/* @ts-ignore: Type 'null' is not assignable to type 'ReactElement */}
        {loading ? LoadingState : this.renderListOrNoResults(items)}
      </Menu>
    );

    return (
      <div>
        {itemsMenu}
        {renderBottomSection(bottomSection, {style: this.widthStyles}, {style: {width: this.popoverMenuWidth}})}
      </div>
    );
  }

  @computed
  private get noResults() {
    return this.props.noResults || NoResultsState;
  }

  @computed
  private get popoverProps() {
    const {onInteraction, popoverProps} = this.props;

    const defaultProps = {
      minimal: true,
      portalClassName: 'h-AutoSuggest'
    } as Partial<PopoverProps>;

    if (this.isSuggestionOpen !== undefined) {
      defaultProps.isOpen = this.isSuggestionOpen;
    }

    if (onInteraction) {
      defaultProps.onInteraction = onInteraction;
    }

    if (popoverProps && popoverProps.portalClassName) {
      defaultProps.portalClassName = classNames(defaultProps.portalClassName + popoverProps.portalClassName);
    }

    return {...defaultProps, ...popoverProps};
  }

  @computed
  private get isSuggestionOpen() {
    const {isSuggestionOpen, popoverProps} = this.props;

    if (this.isOpenOverwrite !== undefined) {
      return this.isOpenOverwrite;
    }

    if (popoverProps && popoverProps.isOpen) {
      return popoverProps.isOpen;
    }

    return isSuggestionOpen;
  }

  @computed
  private get onInteraction() {
    const {onInteraction, popoverProps} = this.props;

    return onInteraction || (popoverProps && popoverProps.onInteraction);
  }

  private renderListOrNoResults(items: JSX.Element[]) {
    return items.length > 0 ? items : this.noResults;
  }

  public render() {
    const {className, disabled, inputProps} = this.props;
    const containerClassNames = classNames(
      'h-AutoSuggest',
      {'h-AutoSuggest__disabled': disabled},
      styles.container,
      className
    );

    const passedProps = omit(this.props, ['className', 'isSuggestionOpen', 'loading', 'onInteraction', 'popoverProps', 'inputProps', 'noResults']);

    return (
      <div className={containerClassNames}>
        <Suggest<T>
          inputValueRenderer={this.inputValueRenderer}
          itemRenderer={itemRenderer}
          itemPredicate={itemPredicate}
          itemListRenderer={this.itemListRenderer}
          popoverProps={this.popoverProps}
          inputProps={{
            onBlur: this.handleBlur,
            ...inputProps
          }}
          {...passedProps}
        />
      </div>
    );
  }
}
