import * as React from 'react';
import { Text, Icon, IconName, Classes, Label, H2 } from '@blueprintjs/core';
import styles from './MetricTile.sass';
import {IconNames} from '@blueprintjs/icons';
import classNames from 'classnames';
import {formatCurrency, formatNumber, formatPercent} from '../../lib/NumberFormatters';

export interface MetricsListProps {
  /**
   * class to be added to the container element
   */
  className?: string;
}

export const MetricsList: React.FC<MetricsListProps> = ({children, className}) => {
  return (
    <div className={classNames(styles.metricsList, className)}>
      {children}
    </div>
  );
};

function formatTrend(value: number, type: MetricType) {
  return formatters.get(type)!(Math.abs(value));
}

export enum MetricType {
  Number,
  Percentage,
  Currency
}

export enum MetricPositiveDirection {
  Up,
  Down,
  Neither
}

const formatters = new Map([
  [MetricType.Number, formatNumber],
  [MetricType.Percentage, formatPercent],
  [MetricType.Currency, formatCurrency]
]);

export interface MetricTrend {
  value: number;
  type?: MetricType;
  positiveDirection?: MetricPositiveDirection;
}

export interface Metric {
  /**
   * Value of the trend.
   */
  value?: number;
  /**
   * Label to be shown after the value but before the trend.
   */
  label?: string;
  /**
   * Either a number, in which case defaults will be applied, or a MetricTrend.
   */
  trend?: MetricTrend | number;
  /**
   * Type of the metric, e.g. raw number/percentage/currency.
   */
  type?: MetricType;
  /**
   * Type of the currency Symbol that should be used, e.g. $, €.
   */
  currencySymbol?: string;
}

function trendIconAndClass(trend: number, positiveDirection: MetricPositiveDirection): {icon?: IconName, className?: string} {
  let icon: IconName | undefined;
  let className: string | undefined;
  if (trend > 0) {
    icon = IconNames.TRENDING_UP;
    className = positiveDirection === MetricPositiveDirection.Up ? styles.goodTrend : styles.badTrend;
  } else if (trend < 0) {
    icon = IconNames.TRENDING_DOWN;
    className = positiveDirection === MetricPositiveDirection.Down ? styles.goodTrend : styles.badTrend;
  } else {
    icon = IconNames.MINUS;
    className = styles.noTrend;
  }
  return {
    icon,
    className: positiveDirection === MetricPositiveDirection.Neither && trend !== 0 ? undefined : className
  };
}

interface MetricTrendProps {
  trend: MetricTrend | number;
}

function MetricTrend({trend: _trend}: MetricTrendProps) {
  const trend: MetricTrend = typeof _trend === 'number' ? { value: _trend } : _trend;
  const {value, positiveDirection = MetricPositiveDirection.Up, type = MetricType.Number} = trend;
  // Preserve trend sign. Will lose it after formatting
  const preservedSign = Math.sign(value);
  const formattedValue = formatTrend(value, type);
  // For the value we need to parse a float from the formatted value, so that if the formatted value is 0
  // we don't show a positive or a negative trend. For e.g: 0.0001 becomes 0.000
  const signed = preservedSign * parseFloat(formattedValue);
  const {icon, className} = trendIconAndClass(signed, positiveDirection);
  return (
    <div className={classNames(className, styles.trend)}>
      <Icon icon={icon}/>
      <Text className={styles.trendText}>{formattedValue}</Text>
    </div>
  );
}

export interface MetricsTileProps {
  /**
   * Title for the metric.
   */
  label: string;
  /**
   * Whether to show a skeleton loader over the value or not.
   */
  loading?: boolean;
  /**
   * The definition of the metric to be displayed.
   */
  metric?: Metric;
  /**
   * The definition of a metric to be shown on hover.
   */
  hoverMetric?: Metric;
}

export function MetricsTile({label: label, loading, metric, hoverMetric}: MetricsTileProps) {
  const [hovered, setHover] = React.useState(false);
  const hoverStart = React.useCallback(() => setHover(true), []);
  const hoverEnd = React.useCallback(() => setHover(false), []);
  if (hoverMetric && hovered) {
    metric = hoverMetric;
  }
  const {value, type = MetricType.Number, trend, label: suffix, currencySymbol = '$'} = metric || {};

  const formatter = formatters.get(type);
  const formatted = formatter ? formatter(value ?? 0, {abbreviate: true, visibleFigures: 3, currencySymbol}) : value;

  return (
    <div
      className={styles.metricTile}
      onMouseEnter={hoverStart}
      onMouseLeave={hoverEnd}
      data-test="MetricsTile"
    >
      <div className={classNames(Classes.LABEL, styles.label)}>{label}</div>
      <div className={classNames({[Classes.SKELETON]: loading})}>
        <div className={styles.display}>
          <H2 className={styles.value}>{formatted}</H2>
          {suffix && <Label className={styles.suffix}>{suffix}</Label>}
        </div>
        {trend != null && (
          <MetricTrend trend={trend} />
        )}
      </div>
    </div>
  );
}
