import {Classes, MenuProps, Menu, MenuItem, Position, Tooltip} from '@blueprintjs/core';
import {getActiveItem, ItemRendererProps, MultiSelect, Select} from '@blueprintjs/select';
import * as React from 'react';
import {SelectItem} from '../components/DropDown';
import {ItemListRendererProps} from '@blueprintjs/select/src/common/itemListRenderer';
import {FixedSizeList} from 'react-window';

export function itemPredicate<T extends SelectItem>(query: string, item: T, _index?: number): boolean {
  if (query) {
    return item.text.toLowerCase().indexOf(query.toLowerCase()) > -1;
  } else {
    return true;
  }
}

export function itemRenderer<T extends SelectItem>(item: T, {handleClick, modifiers}: ItemRendererProps) {
  return (
    <MenuItem
      key={item.value}
      text={item.text}
      icon={item.icon}
      onClick={handleClick}
      disabled={item.disabled === undefined ? modifiers.disabled : item.disabled}
      active={modifiers.active}
      multiline
    />
  );
}

export function renderBottomSection(bottomSection: JSX.Element | undefined | false | null, props?: MenuProps, dividerProps?: React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>) {
  if (bottomSection) {
    return (
      <Menu {...props}>
        <li className={Classes.MENU_DIVIDER} style={{...dividerProps}}/>
        {bottomSection}
      </Menu>
    );
  }
}

export function inputValueRenderer<T extends SelectItem>(item: T) {
  return item.text;
}

export function renderNoResults(loading: boolean | undefined) {
  return loading ? LoadingState : NoResultsState;
}

export const NoResultsState = <MenuItem disabled text="No results found."/>;

export const LoadingState = <MenuItem disabled text="Loading..."/>;

export interface ItemListRendererParams<T extends SelectItem> {
  itemListRendererProps: ItemListRendererProps<T>;
  loading?: boolean;
  bottomSection?: JSX.Element;
  virtualize?: boolean;
  itemHeight?: number;
  stickyItems?: T[];
  selectRef?: Select<T> | MultiSelect<T> | null;
  onItemSelect: (item: T, event?: React.SyntheticEvent<HTMLElement>) => void;
}

const MAX_LIST_HEIGHT = 274; // see DropDown.Sass

export const itemListRenderer = <T extends SelectItem>(
  params: ItemListRendererParams<T>
): JSX.Element => {
  const {
    itemListRendererProps,
    loading,
    bottomSection,
    virtualize,
    itemHeight,
    stickyItems,
    selectRef,
    onItemSelect
  } = params;
  let content: React.ReactNode;
  if (itemListRendererProps.filteredItems.length === 0) {
    content = renderNoResults(loading);
  } else if (virtualize) {
    const activeValue = getActiveItem(itemListRendererProps.activeItem)?.value;
    const selectedIndex = itemListRendererProps.filteredItems.findIndex((v) => v.value === activeValue);

    content = (
      // @ts-ignore: FixedSizeList not JSX component
      <FixedSizeList
        ref={(ref) => ref?.scrollToItem(selectedIndex)} // had to do it this way due to mobx + blueprint render cycles
        width="100%"
        height={Math.min(MAX_LIST_HEIGHT, itemHeight! * itemListRendererProps.filteredItems.length)}
        itemCount={itemListRendererProps.filteredItems.length}
        itemSize={itemHeight!}
      >
        {({index, style}) => (
          <div key={index} style={style}>
            {itemListRendererProps.renderItem(itemListRendererProps.filteredItems[index], index)}
          </div>
        )}
      </FixedSizeList>
    );
  } else {
    content = itemListRendererProps.filteredItems.map(itemListRendererProps.renderItem).filter((item) => item != null);
  }

  return (
      <div>
        <Menu ulRef={itemListRendererProps.itemsParentRef}>
          {content}
        </Menu>
        {stickyItems?.length && renderStickyItems({
          stickyItems,
          selectRef: selectRef!,
          onItemSelect
        })}
        {renderBottomSection(bottomSection)}
      </div>
  );
};

const renderStickyItems = <T extends SelectItem>({
  stickyItems,
  selectRef,
  onItemSelect
}: {
  stickyItems: T[],
  selectRef: Select<T> | MultiSelect<T>,
  onItemSelect: (item: T, event?: React.SyntheticEvent<HTMLElement>) => void
}) => {
  const listItems = stickyItems.map((item, index) => {
    const modifiers = {disabled: false, active: false, matchesPredicate: true};
    const handleClick = () => {
      if (selectRef) {
        // Unfortunately, this is the safest way to close the select pop-over since it's not controllable through props
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        selectRef.setState({isOpen: false});
      }
      onItemSelect(item);
    };
    return selectItemRenderer(item, {handleClick, modifiers, query: '', index});
  });

  return (
      <Menu>
        <li className={Classes.MENU_DIVIDER}/>
        {listItems}
      </Menu>
  );
};

export function selectItemRenderer(item: SelectItem, itemRenderProps: ItemRendererProps) {
  const {modifiers} = itemRenderProps;

  if (!modifiers.matchesPredicate) {
    return null;
  }

  const menuItem = itemRenderer(item, itemRenderProps);

  if (item.disabledTooltip) {
    return (
      <Tooltip
          key={item.value}
          content={item.disabledTooltip}
          hoverOpenDelay={750}
          position={Position.TOP}
      >
        {menuItem}
      </Tooltip>
    );
  }

  return menuItem;
}
