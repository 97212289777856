import {Icon, Position, Tooltip} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import {debounce} from 'lodash';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import * as CopyToClipboard from 'react-copy-to-clipboard';
import styles from './CopyTooltip.sass';

interface Props {
  /**
   * Value to be copied after clicking
   */
  valueToCopy: string;
  /**
   * Message which will be displayed after copying
   * @default "Copied!"
   */
  message?: string;
  /**
   * Timeout (in milliseconds) after which message will disappear
   * @default 1000
   */
  messageTimeout?: number;
  className?: string;
}

@observer
export class CopyTooltip extends React.Component<Props> {
  @observable private isOpen: boolean = false;
  @observable private showIcon: boolean = false;

  public debouncedClosePopover = debounce(() => this.isOpen = false, this.props.messageTimeout || 1000);

  @action
  public handleChildClick = () => {
    this.isOpen = true;
    this.debouncedClosePopover();
  }

  @action
  public onMouseEnter = () => {
    this.showIcon = true;
  }

  @action
  public onMouseLeave = () => {
    this.showIcon = false;
  }

  public render() {
    const {className, valueToCopy, children, message = 'Copied!'} = this.props;
    return (
      // @ts-ignore: CopyToClipboard is not a valid React component
      <CopyToClipboard text={valueToCopy} onCopy={this.handleChildClick} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <span className={`${styles.container} ${className || ''}`}>
          {
            children
          }
          <Tooltip content={message} isOpen={this.isOpen} position={Position.TOP}>
            {this.showIcon && <Icon icon={IconNames.CLIPBOARD}/>}
          </Tooltip>
        </span>
      </CopyToClipboard>
    );
  }
}
