import {FormValue, PrimitiveFormValue, RedactedData} from '@zaiusinc/app-forms-schema';
import {isObservableArray} from 'mobx';

export const REDACTED_VALUE = '*****';

/**
 * Convert complex form values into primitive form values:
 * Redacted values become '*****'
 * @param value any FormValue
 * @returns a primitive value or array of primitive values
 * that can be used when populating a form or evaluating a predicate
 */
export function primitiveValueOf(value?: FormValue): PrimitiveFormValue | PrimitiveFormValue[] {
  if (Array.isArray(value) || isObservableArray(value)) {
    return (value as FormValue[]).map(primitiveValueOf) as PrimitiveFormValue[];
  } else if (value == null) {
    return null;
  } else if (typeof value === 'string') {
    return value.trim();
  } else if (typeof value === 'object') {
    if ((value as RedactedData).redacted === true) {
      return REDACTED_VALUE;
    } else {
      return null;
    }
  }

  return value as PrimitiveFormValue;
}
