import {Classes} from '@blueprintjs/core';
import {Schema} from '@zaiusinc/app-forms-schema';
import classNames from 'classnames';
import * as React from 'react';
import {Errors} from '../layout/Errors';
import {FieldGroup} from '../layout/FieldGroup';
import styles from './TextField.sass';

interface Props {
  schema: Schema.TextField;
  value?: Schema.FormValue;
  errors?: string[];
  onChange: (field: string, value: Schema.FormValue) => void;
  disabled?: boolean;
  readonly?: boolean;
  loading?: boolean;
}

export class TextField extends React.Component<Props> {
  public render() {
    const {
      schema: {label, help},
      errors,
      loading
    } = this.props;

    return (
      <FieldGroup help={help} label={label}>
        <div className={classNames({'bp5-skeleton': loading})}>{this.renderInput()}</div>
        <Errors errors={errors} />
      </FieldGroup>
    );
  }

  private renderInput() {
    const {
      schema: {dataType, hint, multiline},
      value,
      disabled,
      readonly
    } = this.props;
    const className = classNames(styles.input, Classes.INPUT, {[styles.hasErrors]: this.hasErrors});
    if (multiline) {
      return (
        <textarea
          className={className}
          placeholder={hint}
          onChange={this.onChange}
          value={(value as string) || ''}
          disabled={disabled}
          readOnly={readonly}
        />
      );
    } else {
      return (
        <input
          className={className}
          type={dataType ?? 'text'}
          placeholder={hint}
          value={(value as string) || ''}
          onChange={this.onChange}
          disabled={disabled}
          readOnly={readonly}
        />
      );
    }
  }

  private onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.props.onChange(this.props.schema.key, e.currentTarget.value);
  };

  private get hasErrors() {
    return this.props.errors && this.props.errors.length > 0;
  }
}
