import * as React from 'react';
import classNames from 'classnames';
import {
  Button, ButtonGroup, ButtonProps, PopoverInteractionKind, Position, Tooltip, TooltipProps,
} from '@blueprintjs/core';

export type TickInterval = 'day'| 'week'| 'month'| 'quarter' |'year';

export interface TickIntervalOption {
  label: string;
  value: TickInterval;
  disabled?: boolean;
}

export interface TickIntervalSelectorProps {
  tickIntervals: TickIntervalOption[];
  selected: TickInterval;
  onChange: (tickInterval: TickInterval) => void;

  className?: string;

  /**
   * Properties to be passed to the tooltips
   */
  tooltipProps?: TooltipProps;

  /**
   * Properties to be passed to the buttons
   */
  buttonProps?: ButtonProps;
}

export const TickIntervalSelector: React.FC<TickIntervalSelectorProps> = (props) => {
    const {tooltipProps, buttonProps, className, selected} = props;
    const handleTickChange = (tick: TickIntervalOption) => {
      return (e: React.MouseEvent<HTMLElement>) => {
        props.onChange(tick.value);
      };
    };
    return (
      <div className={classNames('h-TickIntervalSelector', className)}>
        <ButtonGroup>
          {props.tickIntervals.map((tick) => {
            const active = tick.value === selected;
            return (
              <Tooltip
                key={tick.value}
                usePortal
                disabled={!tick.disabled}
                interactionKind={PopoverInteractionKind.HOVER}
                position={Position.TOP}
                content={'Not available for the selected timeframe'}
                {...tooltipProps}
              >
                <Button
                  onClick={handleTickChange(tick)}
                  disabled={tick.disabled}
                  active={active}
                  {...buttonProps}
                >
                  {tick.label}
                </Button>
              </Tooltip>
            );
          })}
        </ButtonGroup>
      </div>
    );
};
