import {
  AnchorButton, Button, Classes, Intent, Menu, MenuDivider,
  MenuItem, Position, OverflowList, Tooltip, Breadcrumb, Popover, PopoverProps, Icon, MaybeElement
} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import * as React from 'react';
// @ts-ignore: Could not find a declaration file for module 'react-transition-group'
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import classNames from 'classnames';
import {ScrollableContainer} from '../ScrollableContainer';
import styles from './NestedMenu.sass';
import {SearchInput} from '../SearchInput';

const MENU_SLIDE_MS = 250; // Timing for slide animation (needs to match transition in css).

// E.g:
// [
//   {icon: 'database', text: 'Events'},
//   {icon: 'layout-grid', text: 'Categories', items: [
//     {text: 'Sub Categories', items: [
//       {text: 'Sub Column One', value: 'categories.sub.column_one'},
//       {text: 'Sub Column Two', value: 'categories.sub.column_two'},
//     ]},
//     {text: 'Column One', value: 'categories.column_one'},
//     {text: 'Column Two', value: 'categories.column_two'},
//   {divider: true},
//   {icon: 'plus', text: 'Rocket Column'},
// ];
export interface NestedMenuItem {
  text: string;
  icon?: string;
  disabled?: boolean;
  items?: NestedMenuItem[];
  value: string;
  // Specific handler. When present, this will get called instead of selecting the item.
  onClick?: (item: NestedMenuItem, path: string[]) => void;
}

export interface NestedMenuDivider {
  divider: true;
}

function isNestedMenuItem(item: NestedMenuItem | NestedMenuDivider): item is NestedMenuItem {
  return !('divider' in item);
}

function notNull<T>(val: T | undefined): val is T {
  return val != null;
}

export function getNestedMenuItem(path: string[], items?: NestedMenuItem[]): NestedMenuItem | undefined {
  let result: NestedMenuItem | undefined;
  for (const part of path) {
    result = items?.find((item) => item.value === part);
    items = result?.items;
  }
  return result;
}

/**
 * Whether two paths are identical
 *
 * @param path1
 * @param path2
 */
function pathsEqual(path1: string[], path2: string[]): boolean {
  if (path1.length !== path2.length) {
    return false;
  }
  return path1.every((item, index) => item === path2[index]);
}

/**
 * Whether path is a subpath or equal to the prefix
 *
 * @param path
 * @param prefix
 */
function pathsMatch(path: string[], prefix: string[]): boolean {
  if (path.length <= prefix.length) {
    return false;
  }
  return prefix.every((item, index) => item === path[index]);
}

function isSelected(path: string[], selections: string[][]) {
  return selections.some((selection) => pathsEqual(path, selection));
}

export interface NestedMenuProps {
  // The nested menu.
  items: Array<NestedMenuItem | NestedMenuDivider>;
  // The main label to use in the breadcrumbs, defaults to 'Main'
  mainMenuLabel?: string;
  // Adds Cancel/Apply buttons and uses onApply callback to handle selected items.
  multiSelect?: boolean;
  value: string[][];
  onChange: (value: string[][]) => void;
  // Callback for applying newly selected items.
  onApply?: () => void;
  onCancel?: () => void;
  popoverProps?: PopoverProps;

  // A callback to determine if the selections are valid.
  validator?: (items: NestedMenuItem[]) => boolean;
  invalidTooltip?: JSX.Element | string;
  target: JSX.Element;
  simple?: boolean; // determines that we are using a simple menu with no search and no icons.
}

const NestedMenuItem: React.FC<{
  iconClass?: string;
  item: NestedMenuItem;
  onClick: (value: NestedMenuItem) => void;
  label?: JSX.Element;
}> = ({iconClass, item, label, onClick}) => {
  const className = item.items ? styles.parentItem : styles.item;
  const click = React.useCallback(() => onClick(item), [item, onClick]);
  const labelElement = <>{label}<Icon icon={item.items ? IconNames.CHEVRON_RIGHT : IconNames.BLANK} /></>;
  return (
    <MenuItem
      disabled={item.disabled}
      shouldDismissPopover={false}
      className={classNames(className)}
      labelElement={labelElement}
      icon={iconClass as MaybeElement}
      text={item.text}
      onClick={click}
    />
  );
};

const NestedMenuItems: React.FC<{
  items: Array<NestedMenuItem | NestedMenuDivider>,
  currentPath: string[],
  onClick: (item: NestedMenuItem) => void,
  currentSelections: string[][]
}> = ({items, currentPath, currentSelections, onClick}) => {
  return (
    <>
      {items?.map((item, idx) => {
        if (isNestedMenuItem(item)) {
          const selected = isSelected([...currentPath, item.value], currentSelections);
          const iconClass = item.icon || (selected ? 'tick' : 'blank');

          const selectedChildrenCount = currentSelections.filter((selection) => pathsMatch(selection, [...currentPath, item.value])).length;
          const label = selectedChildrenCount > 0 ? <span className="bp5-tag bp5-round">{selectedChildrenCount}</span> : undefined;

          return (
            <NestedMenuItem
              item={item}
              onClick={onClick}
              key={item.value}
              iconClass={iconClass}
              label={label}
            />
          );
        } else {
          return <MenuDivider key={`divider-${idx}`} className={styles.divider}/>;
        }
      })}
    </>
  );
};

const NestedMenuBreadcrumb: React.FC<{
  item: NestedMenuBreadcrumbItem;
  disabled: boolean;
  onClick: (item: NestedMenuBreadcrumbItem) => void;
}> = ({item: bcItem, item: {item}, disabled, onClick}) => {
  const click = React.useCallback(() => onClick(bcItem), [onClick, bcItem]);
  return (
    <Breadcrumb
      disabled={disabled}
      text={item.text}
      onClick={click}
    />
  );
};

const NestedMenuOverflowItem: React.FC<{
  item: NestedMenuBreadcrumbItem;
  onClick: (item: NestedMenuBreadcrumbItem) => void;
}> = ({item: bcItem, item: {item}, onClick}) => {
  const click = React.useCallback(() => onClick(bcItem), [onClick, bcItem]);
  return (
    <MenuItem
      key={item.value}
      text={item.text}
      shouldDismissPopover={false}
      onClick={click}
    />
  );
};

interface NestedMenuBreadcrumbItem {
  item: NestedMenuItem;
  index: number;
}

export const NestedMenu: React.FC<NestedMenuProps> = ({invalidTooltip, items, mainMenuLabel = 'Main', multiSelect, onCancel, onChange, onApply, simple, target, validator, value, popoverProps = {}}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [searchVal, setSearch] = React.useState('');
  const [direction, setDirection] = React.useState('left');
  const [path, setPath] = React.useState<string[]>([]);
  const [currentSelections, setCurrentSelections] = React.useState(value);

  React.useEffect(() => {
    setCurrentSelections(value);
  }, [value]);

  const breadcrumbClick = React.useCallback<(item: NestedMenuBreadcrumbItem) => void>(({index}) => {
    setDirection('right');
    setTimeout(() => setPath(path.slice(0, index + 1)), 0);
  }, [path, setPath, setDirection]);
  const renderBreadcrumb = React.useCallback<(item: NestedMenuBreadcrumbItem) => JSX.Element>(({index, item}) => (
    <li
      key={index}
    >
      <NestedMenuBreadcrumb
        disabled={index === items.length}
        item={{item, index}}
        onClick={breadcrumbClick}
      />
    </li>
  ), [breadcrumbClick, items.length]);
  const renderOverflow = React.useCallback<(items: NestedMenuBreadcrumbItem[]) => JSX.Element>((overflowedItems: NestedMenuBreadcrumbItem[]) => {
    const orderedItems = overflowedItems.slice().reverse();
    const overflowMenuItems = orderedItems.map((item) => {
      return (
        <NestedMenuOverflowItem
          item={item}
          key={item.item.value}
          onClick={breadcrumbClick}
        />
      );
    });

    return (
      <li
        key="overflow-menu"
      >
        <Popover
          content={<Menu>{overflowMenuItems}</Menu>}
          position={Position.BOTTOM_LEFT}
          minimal
        >
          <span className={Classes.BREADCRUMBS_COLLAPSED} />
        </Popover>
      </li>
    );
  }, [breadcrumbClick]);
  const resetState = React.useCallback(() => {
    setCurrentSelections(value);
    setSearch('');
    setPath([]);
  }, [value, setCurrentSelections, setSearch, setPath]);

  const apply = React.useCallback(() => {
    resetState();
    onChange(currentSelections);
    onApply?.();
  }, [resetState, onChange, onApply, currentSelections]);

  const cancel = React.useCallback(() => {
    resetState();
    onCancel?.();
  }, [resetState, onCancel]);

  const drillDown = React.useCallback((item: NestedMenuItem) => {
    setDirection('left');
    setTimeout(() => setPath([...path, item.value]), 0);
  }, [path, setPath]);

  const handleClick = React.useCallback((item: NestedMenuItem) => {
    if (item.onClick) {
      item.onClick(item, [...path, item.value]);
    } else if (item.items) {
      drillDown(item);
    } else if (multiSelect) {
      const selectedIndex = currentSelections.findIndex((selectedPath) => pathsEqual(selectedPath, [...path, item.value]));
      if (selectedIndex !== -1) {
        setCurrentSelections([...currentSelections?.slice(0, selectedIndex), ...currentSelections?.slice(selectedIndex + 1, currentSelections.length)]);
      } else {
        setCurrentSelections([...currentSelections, [...path, item.value]]);
      }
    } else {
      onChange([[...path, item.value]]);
    }
  }, [drillDown, multiSelect, path, currentSelections, onChange]);

  const canApply = validator?.(currentSelections.map((itemPath) => getNestedMenuItem(itemPath, items.filter(isNestedMenuItem))).filter(notNull)) ?? true;

  const filterHelper = (item: NestedMenuItem | NestedMenuDivider) => {
    if (!isNestedMenuItem(item)) {
      return true;
    }
    if (item.items) {
      return item.items.some(filterHelper);
    }

    return item.text.toLowerCase().includes(searchVal.toLowerCase());
  };

  const currItems = React.useMemo(() => path.length === 0 ? items : getNestedMenuItem(path, items.filter(isNestedMenuItem))?.items ?? [], [items, path]);
  const filteredItems = React.useMemo(() => currItems.filter(filterHelper), [currItems, searchVal]);

  return (
    <>
      <Popover
        minimal
        position={Position.BOTTOM_LEFT}
        {...popoverProps}
        onClose={resetState}
        content={
          <div ref={containerRef} className={classNames(styles.container, {simple: 'simple'})}>
            {!simple && <SearchInput className={styles.search} searchTerm={searchVal} onChange={setSearch} autoFocus={false} />}
            <ScrollableContainer>
              <TransitionGroup
                className={styles.menus}
              >
                <CSSTransition
                  classNames={`slide-${direction}`}
                  timeout={MENU_SLIDE_MS}
                  key={path.join('-')}
                >
                  <div className={styles.menuContainer}>
                    {path.length !== 0 &&
                      <OverflowList
                        className={classNames(Classes.BREADCRUMBS, styles.breadcrumbs)}
                        items={[{item: {text: mainMenuLabel, value: ''}, index: -1}, ...path
                          .map((_, index, arr) => getNestedMenuItem(arr.slice(0, index + 1), items.filter(isNestedMenuItem)))
                          .filter(notNull)
                          .map((item, index) => ({index, item}))]}
                        overflowRenderer={renderOverflow}
                        visibleItemRenderer={renderBreadcrumb}
                      />
                    }
                    <Menu className={styles.menu}>
                      <NestedMenuItems
                        items={filteredItems}
                        onClick={handleClick}
                        currentSelections={currentSelections}
                        currentPath={path}
                      />
                    </Menu>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </ScrollableContainer>

            {multiSelect &&
              <div className={styles.actions}>
                <Button
                  className={Classes.POPOVER_DISMISS}
                  onClick={cancel}
                  text="Cancel"
                />

                <Tooltip
                  content={invalidTooltip}
                  disabled={canApply}
                  position={Position.TOP_RIGHT}
                >
                  {/* The Button element won't fire the mouseLeave when it's disabled, meaning the tooltip never goes away, so we use AnchorButton
                    * https://github.com/palantir/blueprint/issues/1591
                    * https://github.com/facebook/react/issues/4251
                    */}
                  <AnchorButton
                    className={classNames(Classes.POPOVER_DISMISS, styles.applyButton)}
                    intent={Intent.PRIMARY}
                    onClick={apply}
                    text="Apply"
                    disabled={!canApply}
                  />
                </Tooltip>
              </div>
            }
          </div>}
      >
        {target}
      </Popover>
    </>
  );
};
