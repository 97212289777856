import {Classes} from '@blueprintjs/core';
import {Schema} from '@zaiusinc/app-forms-schema';
import classNames from 'classnames';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {Errors} from '../layout/Errors';
import {FieldGroup} from '../layout/FieldGroup';
import * as styles from './SecretField.sass';

interface Props {
  schema: Schema.SecretField;
  value?: Schema.FormValue;
  errors?: string[];
  onChange: (field: string, value: Schema.FormValue) => void;
  disabled?: boolean;
  readonly?: boolean;
  loading?: boolean;
}

const REDACTED_DISPLAY_VALUE = '\u2022'.repeat(8);

@observer
export class SecretField extends React.Component<Props> {
  @observable private focused = false;
  private redactedEditMode = false;

  public render() {
    const {
      schema: {label, help},
      errors,
      loading
    } = this.props;

    return (
      <FieldGroup help={help} label={label}>
        <div className={classNames({'bp5-skeleton': loading})}>{this.renderInput()}</div>
        <Errors errors={errors} />
      </FieldGroup>
    );
  }

  private renderInput() {
    const {
      schema: {hint},
      value,
      disabled,
      readonly
    } = this.props;
    const className = classNames(styles.input, Classes.INPUT, {[styles.hasErrors]: this.hasErrors});
    return (
      <input
        className={className}
        type={this.focused ? 'text' : 'password'}
        placeholder={hint}
        value={this.isRedacted(value) ? REDACTED_DISPLAY_VALUE : (value as string) || ''}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        disabled={disabled}
        readOnly={readonly}
      />
    );
  }

  private isRedacted(value: any) {
    return value != null && typeof value === 'object' && value.redacted;
  }

  private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(this.props.schema.key, e.currentTarget.value);
  };

  private onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    this.focused = true;
    this.redactedEditMode = this.isRedacted(this.props.value);
    if (this.redactedEditMode) {
      e.currentTarget.select();
    }
  };

  private onBlur = () => {
    this.focused = false;
    this.redactedEditMode = false;
  };

  private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.redactedEditMode && e.keyCode === 27) {
      // Escape key
      this.props.onChange(this.props.schema.key, {redacted: true});
      e.currentTarget.blur();
    }
  };

  private get hasErrors() {
    return this.props.errors && this.props.errors.length > 0;
  }
}
