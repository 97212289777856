const SCALE_SUFFIXES = ['', 'K', 'M', 'B', 'T'];

// Note, the order of precednece for conflicting arguments is visibleFigures > decimalPlaces > precision
export interface NumberFormatArgs {
  precision?: number;
  decimalPlaces?: number;
  // Number of figures (excluding prefix, suffix and decimal point) to show.
  // this can never truncate an integer number, but can only remove decimal places.
  visibleFigures?: number;
  currency?: boolean;
  abbreviate?: boolean;
}

export interface CurrencyFormatArgs extends NumberFormatArgs {
  currencySymbol?: string;
}

function abbreviateNumber(value: number): [number, string] {
  const digits = Math.max(Math.round(Math.log10(Math.abs(value)) | 0), 0);
  const tier = Math.floor(digits / 3);

  const suffix = SCALE_SUFFIXES[tier] || '';
  const scale = Math.pow(10, tier * 3);
  value = value / scale;

  return [value, suffix];
}

export function formatNumber(value?: number, args: NumberFormatArgs = {}) {
  const {precision, currency, abbreviate, visibleFigures} = args;
  let {decimalPlaces} = args;
  if (value == null) {
    return '';
  }
  // Prism's typing discipline leaves a lot to be desired, so we might well end up getting the wrong thing here.
  if (typeof value === 'string') {
    value = Number(value);
  }
  if (Number.isNaN(value)) {
    return '';
  }
  let suffix = '';
  if (abbreviate) {
    [value, suffix] = abbreviateNumber(value);
  }
  // If we're formatting a currency, and we aren't adding a scale suffix (e.g., less than 1k)
  // and the input doesn't specify decimal places, then default to 2 decimal places.
  if (decimalPlaces == null && currency && suffix === '') {
    decimalPlaces = 2;
  }
  if (visibleFigures != null) {
    // We want to show n visible figures, so we need to know how many exist before the decimal place
    // in the source value, to understand how many decimal places we can add and stay inside our
    // visible figure budget.
    const characters = (value === 0 ? 0 : Math.max(Math.floor(Math.log10(Math.abs(value))), 0)) + 1;
    decimalPlaces = Math.max(visibleFigures - characters, 0);
  }
  let strValue: string;
  // Decimal place take priority over precision.
  if (decimalPlaces != null) {
    strValue = value.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  } else {
    strValue = value.toLocaleString(undefined, {
      minimumSignificantDigits: precision,
      maximumSignificantDigits: precision,
    });
  }
  return `${strValue}${suffix}`;
}

export function formatCurrency(value: number, args: CurrencyFormatArgs = {}): string {
  const {abbreviate, currencySymbol = '$'} = args;
  let {decimalPlaces} = args;
  // If we're abbreviating, we shouldn't force decimal places
  if (decimalPlaces == null && !abbreviate) {
    decimalPlaces = 2;
  }
  const number = formatNumber(value, {currency: true, ...args, decimalPlaces});
  return `${currencySymbol}${number}`;
}

export function formatPercent(value: number, args: NumberFormatArgs = {}): string {
  const number = formatNumber(value * 100, { decimalPlaces: 2, ...args });
  return `${number}%`;
}
