import {Intent} from '@blueprintjs/core';
import {Badge} from '@zaiusinc/hera';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import styles from './Errors.sass';

interface Props {
  errors?: string[];
}

@observer
export class Errors extends React.Component<Props> {
  @observable private expanded = false;

  public render() {
    const {errors} = this.props;
    if (this.expanded) {
      return (
        <div className={styles.container}>
          {(errors ?? []).map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      );
    } else {
      return (
        <div className={styles.container}>
          {errors && errors[0]}
          {this.renderBadge()}
        </div>
      );
    }
  }

  private renderBadge() {
    const length = this.props.errors ? this.props.errors.length : 0;
    if (length > 1) {
      return (
        <span>
          {' '}
          (
          <a className={styles.link} onClick={this.onClick}>
            and <Badge intent={Intent.DANGER} badge={length - 1} /> more error{length === 1 ? '' : 's'}
          </a>
          )
        </span>
      );
    }
    return null;
  }

  private onClick = () => {
    this.expanded = true;
  };
}
