import {Button, Classes, Dialog, IconName, Intent} from '@blueprintjs/core';
import * as React from 'react';

interface Props {
  /**
   * Intent for the confirm button
   */
  intent?: Intent;

  /**
   * true to open the dialog
   */
  isOpen: boolean;

  /**
   * onClose handler. Called if the dialog is closed or canceled.
   */
  onClose: (e?: React.SyntheticEvent<HTMLElement>) => void;

  /**
   * onConfirm handler. Called only if the user clicks confirm.
   */
  onConfirm: (e?: React.SyntheticEvent<HTMLElement>) => void;

  /**
   * Optional confirm button text
   * @default 'Confirm'
   */
  confirmText?: string;

  /**
   * Class to add to the confirm button
   */
  confirmClass?: string;

  /**
   * Class to add to the cancel button
   */
  cancelClass?: string;

  /**
   * Dialog class name
   */
  className?: string;

  /**
   * true to show a loading indicator on the confirm button
   */
  loading?: boolean;

  /**
   * true to disable the cancel button
   */
  cancelDisabled?: boolean;

  /**
   * true to disable the confirm button
   */
  confirmDisabled?: boolean;

  /**
   * The dialog title
   */
  title?: string | JSX.Element;

  /**
   * The dialog icon
   */
  icon?: IconName | JSX.Element;
}

export const Confirm: React.FunctionComponent<Props> = (props) => {
  const {
    intent,
    isOpen,
    onClose,
    onConfirm,
    children,
    confirmText,
    confirmClass,
    cancelClass,
    className,
    loading,
    cancelDisabled,
    confirmDisabled,
    title,
    icon,
  } = props;

  return (
    <Dialog {...{isOpen, onClose, className, title, icon}}>
      <div className={Classes.DIALOG_BODY}>
        {children}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            className={cancelClass}
            onClick={onClose}
            disabled={cancelDisabled}
          >
            Cancel
          </Button>
          <Button
            className={confirmClass}
            intent={intent}
            disabled={confirmDisabled}
            onClick={onConfirm}
            loading={loading}
          >
            {confirmText || 'Confirm'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
