import {FormValue, RedactedData} from '@zaiusinc/app-forms-schema';

/**
 * Coerces a form value into a boolean.
 * Special cases:
 *   - Empty list = false, list of anything = true
 *   - null = null
 *   - redacted value (populated) = true
 *   - String value/other = !!value
 * @param value any FormValue
 * @returns boolean or null
 */
export function booleanValueOf(value?: FormValue): boolean | null {
  if (Array.isArray(value)) {
    return value.length > 0;
  } else if (value == null) {
    return null;
  } else if (typeof value === 'object') {
    if ((value as RedactedData).redacted === true) {
      return true;
    } else {
      return null;
    }
  }

  return !!value;
}
