import * as React from 'react';
import {observable} from 'mobx';
import {throttle} from 'lodash';
import {action} from 'mobx';
import {observer} from 'mobx-react';
import {ResizeSensor} from '@blueprintjs/core';
import styles from './ScrollableContainer.sass';

export interface ScrollableContainerProps extends Props {}

export interface Props {
  children?: React.ReactNode;
  /**
   * A class to be applied to the container, this is how it's recommended
   * you apply max heights
   */
  className?: string;
  /**
   * Optionally allows you to specify the max height programmatically
   */
  maxHeight?: number;
}

@observer
export class ScrollableContainer extends React.Component<Props> {
  private ref: Element | null = null;
  @observable private showTopShadow: boolean = false;
  @observable private showBottomShadow: boolean = false;
  private throttledShouldShowShadow = throttle(this.shouldShowShadows.bind(this), 10);

  public componentDidMount() {
    setTimeout(() => this.shouldShowShadows());
  }

  @action
  private shouldShowShadows() {
    if (this.getScrollHeight() - this.getContainerHeight() > 0) {
      const contentHeight = this.getScrollHeight();
      const viewHeight = this.getContainerHeight();
      const distanceToTop = this.getScrollTop();
      const distanceToBottom = contentHeight - viewHeight - distanceToTop;

      this.showTopShadow = distanceToTop > 5;
      this.showBottomShadow = distanceToBottom > 5;
    } else {
      this.showTopShadow = false;
      this.showBottomShadow = false;
    }
  }

  private getScrollHeight() {
    return this.ref && this.ref.scrollHeight || 0;
  }

  private getScrollTop() {
    return this.ref && this.ref.scrollTop || 0;
  }

  private getContainerHeight() {
    return this.ref && this.ref.clientHeight || 0;
  }

  private containerClasses() {
    const classes = [styles.container, (this.props.className || '')];

    if (!this.showTopShadow) {
      classes.push(styles.topShadowHidden);
    }
    if (!this.showBottomShadow) {
      classes.push(styles.bottomShadowHidden);
    }

    return classes.join(' ');
  }

  public render() {
    return (
      <div
        className={styles.shadowContainer}
      >
        {/* @ts-ignore: ResizeSensor not JSX component */}
        <ResizeSensor onResize={this.throttledShouldShowShadow}>
          <div
            onScroll={this.throttledShouldShowShadow}
            style={{
              maxHeight: this.props.maxHeight
            }}
            ref={(ref) => this.ref = ref}
            className={this.containerClasses()}
          >
            {this.props.children}
          </div>
        </ResizeSensor>
      </div>
    );
  }
}
