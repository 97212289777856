import {Switch} from '@blueprintjs/core';
import {Schema} from '@zaiusinc/app-forms-schema';
import {DropDownMulti} from '@zaiusinc/hera';
import classNames from 'classnames';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {Errors} from '../layout/Errors';
import {FieldGroup} from '../layout/FieldGroup';

interface Props {
  schema: Schema.MultiSelectField;
  value?: Schema.FormValue;
  errors?: string[];
  onChange: (field: string, value: Schema.FormValueList) => void;
  disabled?: boolean;
  readonly?: boolean;
  loading?: boolean;
  onChangeAction: (action: string) => void;
  onFetchRemoteData: (field: string, source: Schema.DataSource) => Promise<Schema.SelectOption[]>;
}

@observer
export class MultiSelectField extends React.Component<Props> {
  @observable private options: Schema.SelectOption[] = [];
  @observable private loading = false;
  private fetching = false;

  public componentDidMount() {
    // Hera doesn't have an async multi-select, so we need to load options when we mount
    // Could be optimized away later if we create a loading state for the Hera DropDownMulti
    if (this.props.schema.dataSource) {
      this.loading = true;
      this.fetchOptions();
    }
  }

  public componentWillReceiveProps(nextProps: Props) {
    const {value} = nextProps;
    if (value != null && this.options.findIndex((option) => option.value === value) === -1) {
      this.fetchOptions();
    }
  }

  public render() {
    const {
      schema: {help, label},
      errors,
      loading
    } = this.props;

    return (
      <FieldGroup help={help} label={label}>
        <div className={classNames({'bp5-skeleton': loading})}>{this.renderInput()}</div>
        <Errors errors={errors} />
      </FieldGroup>
    );
  }

  private renderInput() {
    const {
      schema: {options, dataSource},
      value,
      disabled,
      readonly
    } = this.props;
    const isDisabled = disabled || readonly || (!dataSource && (!options || options.length === 0));
    const val = value ?? [];
    const selectedItems = (Array.isArray(val) ? val : [val]) as Schema.FormValueList;

    if (options && options.length <= 3) {
      return options.map((option, index) => (
        <Switch
          key={index}
          checked={selectedItems.indexOf(option.value) !== -1}
          onChange={this.onChangeToggle}
          disabled={isDisabled}
          value={index}
          labelElement={option.text}
        />
      ));
    } else {
      return (
        <div className={this.loading ? 'bp5-skeleton' : ''}>
          <DropDownMulti
            placeholder={this.props.schema.hint}
            disabled={isDisabled}
            selectedItems={selectedItems}
            items={dataSource ? this.options : options ?? []}
            onChange={this.onChange}
            popoverProps={{onOpening: this.fetchOptions}} // Update options in bg in case anything has changed
          />
        </div>
      );
    }
  }

  private fetchOptions = () => {
    if (!this.fetching && this.props.schema.dataSource) {
      this.fetching = true;
      const {key, dataSource} = this.props.schema;
      this.props
        .onFetchRemoteData(key, dataSource)
        .then((result) => {
          this.options = result;
          this.loading = false;
          this.fetching = false;
        })
        .catch(() => {
          this.loading = false;
          this.fetching = false;
          // TODO: display error?
        });
    }
  };

  private onChangeToggle = (e: React.FormEvent<HTMLInputElement>) => {
    const value = this.props.schema.options![parseInt(e.currentTarget.value, 10)].value;
    let items = (this.props.value ?? []) as Schema.FormValueList;
    if (!Array.isArray(items)) {
      items = [items];
    }
    if (e.currentTarget.checked) {
      if (items.indexOf(value) === -1) {
        items.push(value);
      }
    } else {
      const index = items.indexOf(value);
      if (index !== -1) {
        items.splice(index, 1);
      }
    }

    this.props.onChange(this.props.schema.key, items);
  };

  private onChange = (items: Schema.FormValueList) => {
    this.props.onChange(this.props.schema.key, items);
    if (this.props.schema.triggerAction) {
      this.props.onChangeAction(this.props.schema.triggerAction);
    }
  };
}
