import classNames from 'classnames';
import {Intent, Tag, Tooltip} from '@blueprintjs/core';
import {observer} from 'mobx-react';
import * as React from 'react';

import styles from './Badge.sass';

interface BadgeProps {
  className?: string;
  intent?: Intent;
  tooltip?: string;
  tooltipProps?: any;
  badge?: string | number;
}

@observer
export class Badge extends React.Component<BadgeProps, {}> {
  public render() {
    const {badge, className, intent, tooltip, tooltipProps} = this.props;
    const numeric = typeof badge === 'number';

    return (
      <span className={classNames(className, styles.container)}>
        <Tooltip content={tooltip || ''} {...tooltipProps}>
          <Tag
            className={classNames(styles.tag, {[styles.numeric]: numeric})}
            intent={intent || Intent.NONE}
          >
            {badge}
          </Tag>
        </Tooltip>
      </span>
    );
  }
}
