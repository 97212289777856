import * as React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import {
  DataTableWithState,
  DataTableWithStateProps,
  DataTableRequestParams,
} from './DataTableWithState';

export type LoadDataTablePromise = Promise<{ rows: any[]; totalRowCount: number }>;

interface AsyncDataTableProps extends DataTableWithStateProps {
  /**
   * Async Function to Load Data
   */
  loadData: (params: DataTableRequestParams) => LoadDataTablePromise;
}

// AsyncDataTable passes sort, searching and paging requests to props.loadData.
@observer
export class AsyncDataTable extends React.Component<
  AsyncDataTableProps,
  {}
> {
  private datatable!: DataTableWithState;
  private recentRequestParams: DataTableRequestParams | null = null;
  /**
   * The data to display in the table
   */
  @observable public data: any[] = [];
  /**
   * Whether the table is still loading data
   */
  @observable public loading: boolean = true;
  /**
   * Total number of records (for paging)
   */
  @observable public total: number = 0;

  @action
  public reset = (): void => {
    this.datatable.reset();
  }

  @action
  public refresh: () => void = () => {
    this.datatable.refresh();
  }

  @action
  public updateData = (requestParams: DataTableRequestParams) => {
    if (this.props.columns.length === 0) {
      return;
    }

    this.loading = true;
    this.recentRequestParams = requestParams;

    // tslint:disable-next-line: no-floating-promises
    this.props.loadData(requestParams).then(
      action((data: { totalRowCount: number; rows: any[] }) => {
        // Ideally we'd cancel previous requests when a new one comes in. Since
        // we can't do that, we verify that this result is from our most recent
        // request. This fixes a race condition where a user could trigger multiple
        // requests and the last one to respond "wins".
        if (requestParams === this.recentRequestParams) {
          this.total = data.totalRowCount;
          this.data = data.rows || [];
          this.loading = false;
        }
      })
    );
  }

  public render() {
    return (
      <DataTableWithState
        {...this.props}
        ref={(datatable) => (this.datatable = datatable!)}
        data={this.data}
        loading={this.loading}
        total={this.total}
        updateData={this.updateData}
      />
    );
  }
}
