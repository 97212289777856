import {FormField, PredicateFieldValidation, RegexFieldValidation} from '@zaiusinc/app-forms-schema';
import {PredicateEvaluator} from './PredicateEvaluator';

export class FieldValidator {
  private _errors = [] as string[];
  constructor(private evaluator: PredicateEvaluator, private field: FormField) {}

  public valid(): boolean {
    if (this.evaluator.evaluate(this.field.required)) {
      if (this.evaluator.evaluate({key: this.field.key, empty: true})) {
        this.errors.push('Required');
        return false;
      }
    }

    this.field.validations?.forEach((validation) => {
      if ((validation as RegexFieldValidation).regex) {
        const regexValidation = validation as RegexFieldValidation;
        const comparator = {
          key: this.field.key,
          regex: regexValidation.regex,
          flags: regexValidation.flags
        };

        if (!this.evaluator.evaluate(comparator)) {
          this.errors.push(regexValidation.message);
        }
      } else {
        if (!this.evaluator.evaluate((validation as PredicateFieldValidation).predicate)) {
          this.errors.push(validation.message);
        }
      }
    });

    return this.errors.length === 0;
  }

  public get errors(): string[] {
    return this._errors;
  }
}
